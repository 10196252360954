// import libraries
import React from 'react';
import { Button, Popover, Checkbox } from 'antd';
import { createSearchParams } from 'react-router-dom';
import moment from 'moment';

//import utils
import { Routes } from '@app/routes/routes';

// create a component
const ReceptionItem = (
  navigate = () => {},
  patientId = '',
  isLance,
  onCheckRecept,
  checkedList,
  isMeasureDetail = false,
  setThumbnailModalData,
) => {
  return isLance === '1'
    ? [
        !isMeasureDetail
          ? {
              title: '選択',
              key: '0',
              width: '60px',
              render: (record) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <Checkbox
                      onChange={(e) => {
                        onCheckRecept(record?.receptId, e?.target?.checked);
                      }}
                      defaultChecked={false}
                      checked={checkedList[record?.receptId]}
                    />
                  </div>
                );
              },
            }
          : {},
        {
          title: 'NO.',
          key: '1',
          dataIndex: 'no',
          sorter: (a, b) => a?.no - b?.no,
          className: 'number-text',
        },
        {
          title: '受付日',
          key: '2',
          render: (record) => {
            return (
              <div>
                <Button
                  type='text'
                  onClick={() =>
                    navigate({
                      pathname: Routes.Home.MeasureDetail.path,
                      search: `?${createSearchParams({
                        patientId: patientId,
                        receptId: record?.receptId,
                      })}`,
                    })
                  }
                >
                  {record?.receptDate}
                </Button>
              </div>
            );
          },
          sorter: (a, b) =>
            moment(a?.receptDate, 'YYYY-MM-DD').diff(
              moment(b?.receptDate, 'YYYY-MM-DD'),
            ),
        },
        {
          title: '受付開始時刻',
          key: '3',
          dataIndex: 'startTime',
          sorter: (a, b) => a?.startTime?.localeCompare(b?.startTime),
        },
        {
          title: '受付終了時刻',
          key: '4',
          dataIndex: 'endTime',
          sorter: (a, b) =>
            moment(a?.endTime, 'YYYY-MM-DD').diff(
              moment(b?.endTime, 'YYYY-MM-DD'),
            ),
        },

        {
          title: '回目',
          key: '5',
          dataIndex: 'receptCnt',
          className: 'number-text',
          sorter: (a, b) => a?.receptCnt - b?.receptCnt,
        },
        {
          title: '服薬（朝）',
          key: '6',
          dataIndex: 'med_m',
          sorter: (a, b) => a?.med_m - b?.med_m,
        },
        {
          title: '服薬（昼）',
          key: '7',
          dataIndex: 'med_d',
          sorter: (a, b) => a?.med_d - b?.med_d,
        },
        {
          title: '服薬（夜）',
          key: '8',
          dataIndex: 'med_n',
          sorter: (a, b) => a?.med_n - b?.med_n,
        },
        {
          title: '体重',
          key: '9',
          dataIndex: 'weight',
          className: 'number-text',
          sorter: (a, b) => a?.weight - b?.weight,
        },
        {
          title: '体温',
          key: '10',
          dataIndex: 'temperature',
          className: 'number-text',
          sorter: (a, b) => a?.temperature - b?.temperature,
        },
        {
          title: '収縮圧',
          key: '11',
          dataIndex: 'sbp',
          className: 'number-text',
          sorter: (a, b) => a?.sbp - b?.sbp,
        },
        {
          title: '拡張圧',
          key: '12',
          dataIndex: 'dbp',
          className: 'number-text',
          sorter: (a, b) => a?.dbp - b?.dbp,
        },
        {
          title: '脈拍',
          key: '13',
          dataIndex: 'pulse',
          className: 'number-text',
          sorter: (a, b) => a?.pulse - b?.pulse,
        },
        {
          title: 'WE-100計測回数',
          key: '14',
          dataIndex: 'we100Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.we100Cnt - b?.we100Cnt,
        },
        {
          title: 'WE-110計測回数',
          key: '15',
          dataIndex: 'we110Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.we110Cnt - b?.we110Cnt,
        },
        {
          title: 'EM-300計測回数',
          key: '16',
          dataIndex: 'em300Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.em300Cnt - b?.em300Cnt,
        },
        {
          title: 'EM-350計測回数',
          key: '17',
          dataIndex: 'em350Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.em350Cnt - b?.em350Cnt,
        },
        {
          title: 'EM-400計測回数',
          key: '18',
          dataIndex: 'em400Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.em400Cnt - b?.em400Cnt,
        },
        {
          title: 'スマートウォッチ計測回数',
          key: '19',
          dataIndex: 'vitalCnt',
          className: 'number-text',
          sorter: (a, b) => a?.vitalCnt - b?.vitalCnt,
        },
        !isMeasureDetail
          ? {
              title: 'メディアファイル数',
              key: '20',
              className: 'number-text',
              sorter: (a, b) => a?.mediaCnt - b?.mediaCnt,
              render: (record) => {
                return (
                  <div>
                    <Button
                      type='text'
                      onClick={(e) => {
                        window.open(
                          '/users/detail/thumbnail?receptId=' +
                            record?.receptId +
                            '&date=' +
                            record?.receptDate,
                          '',
                          'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30',
                        );
                      }}
                    >
                      {record?.mediaCnt}
                    </Button>
                  </div>
                );
              },
            }
          : {},
        {
          title: 'メモ',
          key: '21',
          render: (record, rowIndex) => {
            return (
              <div className='action-item'>
                <Popover
                  content={<div style={{ maxWidth: 500 }}>{record?.memo}</div>}
                  trigger='click'
                  placement='left'
                >
                  <Button key={rowIndex} type='text'>
                    {record?.memo}
                  </Button>
                </Popover>
              </div>
            );
          },
        },
      ].filter((e) => {
        return Object.keys(e).length;
      })
    : [
        !isMeasureDetail
          ? {
              title: '選択',
              key: '0',
              width: '60px',
              render: (record) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <Checkbox
                      onChange={(e) => {
                        onCheckRecept(record?.receptId, e?.target?.checked);
                      }}
                      defaultChecked={false}
                      checked={checkedList[record?.receptId]}
                    />
                  </div>
                );
              },
            }
          : {},
        {
          title: 'NO.',
          key: '1',
          dataIndex: 'no',
          sorter: (a, b) => a?.no - b?.no,
        },
        {
          title: '受付日',
          key: '2',
          render: (record) => {
            return (
              <div>
                <Button
                  type='text'
                  onClick={() =>
                    navigate({
                      pathname: Routes.Home.MeasureDetail.path,
                      search: `?${createSearchParams({
                        patientId: patientId,
                        receptId: record?.receptId,
                      })}`,
                    })
                  }
                >
                  {record?.receptDate}
                </Button>
              </div>
            );
          },
          sorter: (a, b) =>
            moment(a?.receptDate, 'YYYY-MM-DD').diff(
              moment(b?.receptDate, 'YYYY-MM-DD'),
            ),
        },
        {
          title: '受付開始時刻',
          key: '3',
          dataIndex: 'startTime',
          sorter: (a, b) => a?.startTime?.localeCompare(b?.startTime),
        },
        {
          title: '受付終了時刻',
          key: '4',
          dataIndex: 'endTime',
          sorter: (a, b) =>
            moment(a?.endTime, 'YYYY-MM-DD').diff(
              moment(b?.endTime, 'YYYY-MM-DD'),
            ),
        },

        {
          title: '回目',
          key: '5',
          dataIndex: 'receptCnt',
          className: 'number-text',
          sorter: (a, b) => a?.receptCnt - b?.receptCnt,
        },
        {
          title: '服薬（朝）',
          key: '6',
          dataIndex: 'med_m',
          sorter: (a, b) => a?.med_m - b?.med_m,
        },
        {
          title: '服薬（昼）',
          key: '7',
          dataIndex: 'med_d',
          sorter: (a, b) => a?.med_d - b?.med_d,
        },
        {
          title: '服薬（夜）',
          key: '8',
          dataIndex: 'med_n',
          sorter: (a, b) => a?.med_n - b?.med_n,
        },
        {
          title: '体重',
          key: '9',
          dataIndex: 'weight',
          className: 'number-text',
          sorter: (a, b) => a?.weight - b?.weight,
        },
        {
          title: '体温',
          key: '10',
          dataIndex: 'temperature',
          className: 'number-text',
          sorter: (a, b) => a?.temperature - b?.temperature,
        },
        {
          title: '収縮圧',
          key: '11',
          dataIndex: 'sbp',
          className: 'number-text',
          sorter: (a, b) => a?.sbp - b?.sbp,
        },
        {
          title: '拡張圧',
          key: '12',
          dataIndex: 'dbp',
          className: 'number-text',
          sorter: (a, b) => a?.dbp - b?.dbp,
        },
        {
          title: '脈拍',
          key: '13',
          dataIndex: 'pulse',
          className: 'number-text',
          sorter: (a, b) => a?.pulse - b?.pulse,
        },
        {
          title: 'WE-100計測回数',
          key: '14',
          dataIndex: 'we100Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.we100Cnt - b?.we100Cnt,
        },
        {
          title: 'WE-110計測回数',
          key: '15',
          dataIndex: 'we110Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.we110Cnt - b?.we110Cnt,
        },
        {
          title: 'EM-300計測回数',
          key: '16',
          dataIndex: 'em300Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.em300Cnt - b?.em300Cnt,
        },
        {
          title: 'EM-350計測回数',
          key: '17',
          dataIndex: 'em350Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.em350Cnt - b?.em350Cnt,
        },
        {
          title: 'EM-400計測回数',
          key: '18',
          dataIndex: 'em400Cnt',
          className: 'number-text',
          sorter: (a, b) => a?.em400Cnt - b?.em400Cnt,
        },
        !isMeasureDetail
          ? {
              title: 'メディアファイル数',
              key: '19',
              className: 'number-text',
              sorter: (a, b) => a?.mediaCnt - b?.mediaCnt,
              render: (record) => {
                return (
                  <div>
                    <Button
                      type='text'
                      onClick={(e) => {
                        window.open(
                          '/users/detail/thumbnail?receptId=' +
                            record?.receptId +
                            '&date=' +
                            record?.receptDate,
                          '',
                          'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30',
                        );
                      }}
                    >
                      {record?.mediaCnt}
                    </Button>
                  </div>
                );
              },
            }
          : {},
        {
          title: 'メモ',
          key: '20',
          render: (record, rowIndex) => {
            return (
              <div className='action-item'>
                <Popover
                  content={<div style={{ maxWidth: 500 }}>{record?.memo}</div>}
                  trigger='click'
                  placement='left'
                >
                  <Button key={rowIndex} type='text'>
                    {record?.memo}
                  </Button>
                </Popover>
              </div>
            );
          },
        },
      ].filter((e) => {
        return Object.keys(e).length;
      });
};

// make this component available to the app
export default ReceptionItem;
