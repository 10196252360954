import React from 'react';
import { HomeLayout } from '@app/layout/home.layout';
import { DefaultLayout } from '@app/layout/default.layout';

const LAYOUT_MAP = {
  DEFAULT_LAYOUT: DefaultLayout,
  HOME_LAYOUT: HomeLayout,
};

export const LayoutManager = ({ children, route }) => {
  const { layout } = route;
  const Layout = LAYOUT_MAP[layout] || LAYOUT_MAP.DEFAULT_LAYOUT;
  return <Layout>{children}</Layout>;
};
