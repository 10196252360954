import React, { createContext, useContext, useEffect, useState } from 'react';
import { Row } from 'antd';
import { Auth } from '@app/middleware/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [userFacility, setUserFacility] = useState(null);
  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      const { user } = await Auth(location, navigate);
      if (!isEmpty(user)) {
        setUserInfo(user);
        if (localStorage.getItem('defaultFacility')) {
          setUserFacility(JSON.parse(localStorage.getItem('defaultFacility')));
        }
      }
      setLoading(false);
    };
    fn();
  }, [location]);

  if (loading)
    return (
      <Row style={{ height: '100vh' }} justify='center' align='middle'></Row>
    );

  return (
    <AuthContext.Provider value={{ user: userInfo, userFacility }}>
      {children}
    </AuthContext.Provider>
  );
};
