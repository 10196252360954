// import libraries
import React from 'react';
import { Button, Popover } from 'antd';
import { genderJapName } from '@app/utils/static';
import { find } from 'lodash';
import moment from 'moment';

// create a component
export const PatientListItem = () => {
  return [
    {
      title: 'NO.',
      key: '1',
      dataIndex: 'no',
      sorter: (a, b) => a?.no - b?.no,
    },
    {
      title: 'カルテID',
      key: '2',
      render: (record) => record?.carteNo || '',
      sorter: (a, b) => a?.carteNo?.localeCompare(b?.carteNo, 'ja'),
    },
    {
      title: '氏名',
      key: '3',
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b?.name, 'ja'),
    },
    {
      title: 'カナ',
      key: '4',
      dataIndex: 'nameKana',
      sorter: (a, b) => a?.nameKana?.localeCompare(b?.nameKana, 'ja'),
    },
    {
      title: '生年月日',
      key: '5',
      dataIndex: 'birthday',
      sorter: (a, b) =>
        moment(a?.birthday, 'YYYY-MM-DD').diff(
          moment(b?.birthday, 'YYYY-MM-DD'),
        ),
    },
    {
      title: '年齢',
      key: '6',
      dataIndex: 'age',
      className: 'number-text',
      sorter: (a, b) => a?.age - b?.age,
    },
    {
      title: '性別',
      key: '7',
      dataIndex: 'gender',
      render: (gender) =>
        find(genderJapName, {
          value: +gender,
        })?.label || '',
      sorter: (a, b) => a?.gender - b?.gender,
    },
    {
      title: '類別',
      key: '8',
      dataIndex: 'className',
      sorter: (a, b) => a?.className?.localeCompare(b?.className, 'ja'),
    },
    {
      title: '身長',
      key: '9',
      dataIndex: 'height',
      className: 'number-text',
      sorter: (a, b) => a?.height - b?.height,
    },
    {
      title: '初回受付日',
      key: '10',
      dataIndex: 'firstDate',
      sorter: (a, b) =>
        moment(a?.firstDate, 'YYYY-MM-DD').diff(
          moment(b?.firstDate, 'YYYY-MM-DD'),
        ),
    },
    {
      title: '最新受付日',
      key: '11',
      dataIndex: 'visitDate',
      sorter: (a, b) =>
        moment(a?.visitDate, 'YYYY-MM-DD').diff(
          moment(b?.visitDate, 'YYYY-MM-DD'),
        ),
    },
    {
      title: '回数',
      key: '12',
      dataIndex: 'receptCnt',
      className: 'number-text',
      sorter: (a, b) => a?.receptCnt - b?.receptCnt,
    },
    {
      title: 'クライアントメモ',
      key: '13',
      render: (record, rowIndex) => {
        return (
          <div className='action-item'>
            <Popover
              content={<div style={{ maxWidth: 500 }}>{record?.memo}</div>}
              trigger='click'
              placement='left'
            >
              <Button key={rowIndex} type='text'>
                {record?.memo}
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ].filter((e) => {
    return Object.keys(e).length;
  });
};
