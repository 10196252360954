import { Button } from 'antd';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Routes } from '@app/routes/routes';

const FacilityItem = ({ contractDetail = {} }) => {
  const navigate = useNavigate();
  return [
    {
      title: '施設名',
      key: '1',
      render: (record) => {
        return (
          <Button
            type='text'
            onClick={() =>
              navigate({
                pathname: Routes.Home.PatientList.path,
                search: `?${createSearchParams({
                  facilityId: record?.facilityId,
                })}`,
              })
            }
          >
            {record?.facilityName}
          </Button>
        );
      },
    },
    {
      title: 'アプリ利用代表者',
      key: '2',
      render: () => <div>{contractDetail?.managerName || ''}</div>,
    },
    {
      title: 'アプリ利用代表者（ふりがな）',
      key: '3',
      render: () => <div>{contractDetail?.managerNameKana || ''}</div>,
    },
    {
      title: '郵便番号',
      key: '4',
      render: () => <div>{contractDetail?.zip || ''}</div>,
    },
    {
      title: '都道府県',
      key: '5',
      dataIndex: 'pref',
    },
    {
      title: '市区町村',
      key: '6',
      dataIndex: 'city',
    },
    {
      title: '番地など',
      key: '7',
      dataIndex: 'street',
    },
    {
      title: '建物名・部屋番号',
      key: '8',
      dataIndex: 'building',
    },
  ];
};

export default FacilityItem;
