// import libraries
import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib/icons';
import { Button, Spin, Row } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';

// import utils
import {
  beWellTrainingModePickerValues,
  dongleTrainingModePickerValues,
} from '@app/utils/static';
import Title from '@app/components/title';
import Table from '@app/components/table';
import { useAuthContext } from '@app/providers/authProvider';
import Chart from '@app/scenes/home/users/measureDetail/components/chart';
import UserListItem from '@app/scenes/home/users/userlist/components/userlist.item.jsx';
import ReceptionItem from '@app/scenes/home/users/userDetail/components/reception.item';
import RealtimeItem from '@app/scenes/home/users/measureDetail/components/realtime.item';
import { useStableNavigate } from '@app/providers/stableNavigate';
import { downloadCsvFromAPI } from '@app/utils/commonFunctions';
import { find } from 'lodash';

// import services
import { getPatientList } from '@app/services/user/user.service';
import { getPatientReceptInfoList } from '@app/services/user/userDetail.service';
import {
  getRealtimeMeasureSecondAxis,
  getMeasureDetail,
} from '@app/services/user/measureDetail.service';

import moment from 'moment';
import { useMonitorStore } from '@app/utils/contextManager';
import { Routes } from '@app/routes/routes';
// create a component
const MeasureDetailScene = () => {
  const {
    state: { graphWidth = 0 },
  } = useMonitorStore();
  const { user, userFacility } = useAuthContext();
  const [showTable, setShowTable] = useState(true);
  const [state, setState] = useState({
    realtimeListDataList: [],
    loading: true,
    selectGraph: 1,
    selectVisible: { smartWatch: 1, beWell: 1 },
    pageNo: 1,
    finished: false,
    measureDetail: true,
    isMeasureTime: 1,
  });
  const [receptState, setReceptState] = useState({
    prevData: {},
    nextData: {},
    patientReceptInfoList: [],
  });
  const [patients, setPatients] = useState([]);

  const [measureListState, setMeasureListState] = useState({
    beWellAndDongleMeasureList: [],
    lanceMeasureList: [],
    chooseBeWellMeasureMent: null,
    chooseLanceMeasureMent: null,
  });
  const {
    realtimeListDataList,
    loading,
    selectVisible,
    pageNo,
    measureDetail,
  } = state;

  const { prevData, nextData, patientReceptInfoList } = receptState;
  const {
    beWellAndDongleMeasureList,
    lanceMeasureList,
    chooseBeWellMeasureMent,
    chooseLanceMeasureMent,
  } = measureListState;

  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = queryString.parse(search);
  const [viewDate, setViewDate] = useState('');
  const [onClickTable, seTonClickTable] = useState({
    lineDate: '',
    tooltipPosition: [],
  });
  const navigate = useStableNavigate();

  useEffect(() => {
    if (params.receptId) {
      _fetchData();
    }
  }, []);

  useEffect(() => {
    if (
      (beWellAndDongleMeasureList?.length > 0 ||
        lanceMeasureList?.length > 0) &&
      patients?.length > 0
    )
      getPatientMeasureData();
  }, [patients, chooseBeWellMeasureMent, chooseLanceMeasureMent]);

  const getPatientMeasureData = async () => {
    setState({ ...state, loading: true });
    await getPatientDailySummaryListData();
  };

  const _fetchData = async () => {
    try {
      // Result of Searched values to get API data
      setState({ ...state, loading: true });
      const _res = await getPatientList({
        facilityId: userFacility?.facilityId || null,
        patientId: searchParams.get('patientId') || null,
      });
      // Passing props data as patient
      if (_res?.data?.patientResponseList?.[0]) {
        setPatients([
          {
            ..._res.data.patientResponseList[0],
            key: _res.data.patientResponseList[0]?.patientId,
            no: '1',
          },
        ]);
        const _receptItem = await getReceptList(
          _res.data.patientResponseList[0],
        );
        if (_receptItem?.receptId) {
          const measureDetail = await _getMeasureDetail(
            _res.data.patientResponseList[0],
            _receptItem,
          );
          if (measureDetail?.measureList?.length > 0) {
            const beWellAndDongleMeasureInfoList =
              measureDetail?.measureList?.filter(
                (measurement) =>
                  measurement.rehabilitationId === 2 ||
                  measurement.rehabilitationId === 8 ||
                  measurement.rehabilitationId === 11 ||
                  measurement.rehabilitationId === 12 ||
                  measurement.rehabilitationId === 13,
              );
            const lanceMeasureInfoList = measureDetail?.measureList?.filter(
              (measurement) => measurement.rehabilitationId === 1,
            );
            setMeasureListState({
              ...measureListState,
              beWellAndDongleMeasureList: beWellAndDongleMeasureInfoList,
              lanceMeasureList: lanceMeasureInfoList,
              chooseBeWellMeasureMent:
                beWellAndDongleMeasureInfoList?.[
                  beWellAndDongleMeasureInfoList?.length - 1
                ],
              chooseLanceMeasureMent:
                lanceMeasureInfoList?.[lanceMeasureInfoList?.length - 1],
            });

            if (
              lanceMeasureInfoList?.length === 0 &&
              beWellAndDongleMeasureInfoList?.length === 0
            ) {
              setState({ ...state, loading: false });
            }
          } else {
            setState({ ...state, loading: false });
          }
        } else {
          setState({ ...state, loading: false });
        }
      } else {
        setState({ ...state, loading: false });
      }
    } catch (error) {
      console.log('userDetail', error);
      setState({ ...state, loading: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  const getReceptList = async (_patient) => {
    try {
      const _receptRes = await getPatientReceptInfoList({
        facilityId: userFacility?.facilityId || null,
        patientId: _patient?.patientId || null,
      });
      const patientReceptInfoListData = (
        _receptRes?.data?.receptList || []
      ).find(
        (data) => data.receptId === parseInt(searchParams.get('receptId')),
      );
      const summaryIdx = _receptRes?.data?.receptList.findIndex(
        (item) => item.receptId === parseInt(searchParams.get('receptId')),
      );
      const foundItem = _receptRes?.data?.receptList;

      setReceptState({
        prevData:
          summaryIdx !== foundItem.length
            ? foundItem[summaryIdx + 1]
            : foundItem[summaryIdx],
        nextData:
          summaryIdx > 0 ? foundItem[summaryIdx - 1] : foundItem[summaryIdx],
        patientReceptInfoList: [
          {
            ...patientReceptInfoListData,
            key: patientReceptInfoListData?.receptId,
            no: '1',
          },
        ],
      });
      return _receptRes?.data?.receptList?.[summaryIdx] || {};
    } catch (error) {
      return [];
    }
  };

  const _getMeasureDetail = async (_patient, _receptItem) => {
    try {
      const _realtimeList = await getMeasureDetail({
        facilityId: userFacility?.facilityId || null,
        patientId: _patient?.patientId || null,
        receptId: parseInt(_receptItem?.receptId) || null,
        receptDateFrom:
          moment(_receptItem?.receptDate).format('YYYYMMDD') || '',
        receptDateTo: moment(_receptItem?.receptDate).format('YYYYMMDD') || '',
      });
      return _realtimeList?.data?.receptList?.[0] || {};
    } catch (error) {
      return {};
    }
  };

  // useEffect(() => {
  //   if (measureData?.receptId && measureData?.measureList?.length > 0) {
  //     onInit();
  //   }
  // }, [chooseBeWellMeasureMent, chooseLanceMeasureMent]);

  const getPatientDailySummaryListData = async () => {
    try {
      let _measureInfoIds = [];
      if (chooseBeWellMeasureMent) {
        _measureInfoIds = [
          ..._measureInfoIds,
          chooseBeWellMeasureMent.measureInfoId,
        ];
      }
      if (chooseLanceMeasureMent) {
        _measureInfoIds = [
          ..._measureInfoIds,
          chooseLanceMeasureMent.measureInfoId,
        ];
      }
      const result = await getRealtimeMeasureSecondAxis({
        facilityId: userFacility?.facilityId || null,
        measureInfoIds: _measureInfoIds,
        page: pageNo,
        allData: true,
      });
      if (result?.success === 'failed') {
        setState({
          ...state,
          realtimeListDataList: [],
          loading: false,
          finished: true,
        });
      }
      if (result?.success === 'success') {
        const data = realtimeDataConverter(result?.data?.rehabiliValueList);
        setState({
          ...state,
          realtimeListDataList: data,
          loading: false,
          finished: true,
        });
      }
    } catch (error) {
      setState({ ...state, loading: false, realtimeListDataList: [] });

      console.log(error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  const realtimeDataConverter = (rehabiliValues) => {
    let newFormatedData = [];
    if (rehabiliValues?.length === 2) {
      (rehabiliValues?.[0]?.values || [])?.map((item, index) => {
        let newValue = {};
        const firstConvertedData = realtimeValueFormatter(
          item,
          rehabiliValues?.[0],
          index,
        );
        const secondConvertedData = realtimeValueFormatter(
          rehabiliValues?.[1]?.values?.[index],
          rehabiliValues?.[1],
          index,
        );
        newValue = { ...firstConvertedData, ...secondConvertedData };
        newFormatedData = [...newFormatedData, newValue];
        return newFormatedData;
      });
      return newFormatedData;
    }
    if (rehabiliValues?.length === 1) {
      (rehabiliValues?.[0]?.values || [])?.map((item, index) => {
        const firstConvertedData = realtimeValueFormatter(
          item,
          rehabiliValues?.[0],
          index,
        );
        newFormatedData = [...newFormatedData, firstConvertedData];
        return newFormatedData;
      });
      return newFormatedData;
    }
  };

  const realtimeValueFormatter = (values, rehabiliValue, index) => {
    const value = values?.split(',');
    if (rehabiliValue?.rehabilitationId === 1) {
      const newTime = value?.[0]?.split(' ');
      return {
        key: index,
        measureTime: newTime?.[1],
        isVitalPlot: +value?.[1] === 1,
        lanceExerciseTime: value?.[3] !== 'null' ? value?.[3] : null,
        pulse: +value?.[4] || 0,
        oxygen: +value?.[5] || 0,
        sbp: +value?.[6] || 0,
        dbp: +value?.[7] || 0,
        borg: +value?.[8] || null,
        alarmList: value?.[9]?.split('|')?.join(',') || null,
      };
    }

    if (
      rehabiliValue?.rehabilitationId === 2 ||
      rehabiliValue?.rehabilitationId === 8
    ) {
      const newTime = value?.[0]?.split(' ');
      return {
        key: index,
        measureTime: newTime?.[1],
        isAerobicPlot: +value?.[1] === 1,
        beWellExerciseTime: value?.[3] !== 'null' ? value?.[3] : null,
        bewellPulse: +value?.[4] || 0,
        watt: +value?.[5] || 0,
        step_rotation: +value?.[7] || 0,
        loadLevel: +value?.[8] || 0,
        mets: +value?.[6] || 0.0,
        cumulative: +value?.[11] || 0,
        cal: +value?.[10] || 0,
        borg: +value?.[16] || null,
        alarmList: value?.[17]?.split('|')?.join(',') || null,
        distance: +value?.[9] || 0.0,
        trainingMode:
          find(beWellTrainingModePickerValues, {
            value: +value?.[12],
          })?.label || '',
        pedalTorque: 0,
      };
    }
    if (
      rehabiliValue?.rehabilitationId === 11 ||
      rehabiliValue?.rehabilitationId === 12 ||
      rehabiliValue?.rehabilitationId === 13
    ) {
      const newTime = value?.[0]?.split(' ');
      return {
        key: index,
        measureTime: newTime?.[1],
        isAerobicPlot: +value?.[1] === 1,
        mederugoExerciseTime: value?.[3] !== 'null' ? value?.[3] : null,
        bewellPulse: +value?.[4] || 0,
        watt: +value?.[5] || 0,
        pedalTorque: +value?.[7] || 0,
        loadLevel: 0,
        step_rotation: +value?.[6] || 0,
        mets: 0.0,
        cumulative: +value?.[11] || 0,
        cal: +value?.[8] || 0,
        borg: +value?.[13] || null,
        alarmList: value?.[14]?.split('|')?.join(',') || null,
        trainingMode:
          rehabiliValue?.rehabilitationId === 13
            ? find(dongleTrainingModePickerValues, {
                value: +value?.[9],
              })?.label || ''
            : '-',
      };
    }
  };
  const onDownloadReceptCsv = async () => {
    try {
      await downloadCsvFromAPI(
        '/csv/realtime/list',
        userFacility,
        'リアルタイムデータCSV',
        { patientId: params?.patientId, receptId: params?.receptId },
      );
    } catch (error) {
      console.log(error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };
  const _list =
    user.lanceBandRestriction !== '1'
      ? realtimeListDataList?.filter((e) => e.isAerobicPlot)
      : realtimeListDataList;

  return (
    <Spin spinning={loading}>
      <div className='measure-detail'>
        <Title title='計測詳細画面'>
          <Button
            disabled={!patients?.length}
            type='text'
            onClick={onDownloadReceptCsv}
          >
            リアルタイムcsvダウンロード
          </Button>
          <Button
            type='text'
            className='arrow-button start'
            onClick={() =>
              setSearchParams({
                ...params,
                receptId: prevData.receptId,
              })
            }
          >
            <LeftOutlined />
            前の受付
          </Button>
          <Button
            type='text'
            className='arrow-button'
            onClick={() =>
              setSearchParams({
                ...params,
                receptId: nextData.receptId,
              })
            }
          >
            <RightOutlined />
            次の受付
          </Button>
        </Title>
        <Row>
          <Title title='計測情報' list noButtons collapse />
          <Button
            onClick={() => setShowTable(!showTable)}
            icon={showTable ? <CaretUpOutlined /> : <CaretDownOutlined />}
            type='text'
          />
        </Row>
        <Table
          visible={showTable}
          rowSelection=''
          className='user-list-table'
          dataSource={patients}
          columns={UserListItem({ isDetail: true })}
        />
        <div className='title'>
          <div className='title-container'>
            <Table
              visible={showTable}
              rowSelection=''
              className='reception-table'
              dataSource={patientReceptInfoList}
              columns={ReceptionItem(
                navigate,
                patients[0]?.patientId,
                user.lanceBandRestriction,
                null,
                null,
                true,
              )}
            />
          </div>
        </div>
        {chooseBeWellMeasureMent?.rehabilitationId ||
        chooseLanceMeasureMent?.rehabilitationId ? (
          <Chart
            realtimeListDataList={realtimeListDataList}
            isLance={user.lanceBandRestriction}
            selectVisible={selectVisible}
            setState={setState}
            state={state}
            loading={loading}
            measureDetail={measureDetail}
            setViewDate={setViewDate}
            onClickTable={onClickTable}
            viewDate={viewDate}
            beWellAndDongleMeasureList={beWellAndDongleMeasureList}
            lanceMeasureList={lanceMeasureList}
            chooseBeWellMeasureMent={chooseBeWellMeasureMent}
            chooseLanceMeasureMent={chooseLanceMeasureMent}
            bewellDonglePickerDonePress={(e) =>
              setMeasureListState({
                ...measureListState,
                chooseBeWellMeasureMent: beWellAndDongleMeasureList?.find(
                  (item) => item.measureInfoId === e,
                ),
              })
            }
            lancePickerDonePress={(e) =>
              setMeasureListState({
                ...measureListState,
                chooseLanceMeasureMent: lanceMeasureList?.find(
                  (item) => item.measureInfoId === e,
                ),
              })
            }
          />
        ) : null}

        <Table
          rowSelection=''
          className='reception-table'
          dataSource={_list}
          columns={RealtimeItem(
            user.lanceBandRestriction,
            chooseBeWellMeasureMent?.rehabilitationId,
          )}
          rowClassName={(record, index) =>
            viewDate.includes(record?.measureTime) ? 'table-row-view' : ''
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (viewDate.includes(record?.measureTime)) {
                  seTonClickTable({
                    lineDate:
                      viewDate?.[0] === record?.measureTime
                        ? viewDate?.[1]
                        : viewDate?.[0],
                    tooltipPosition: onClickTable?.tooltipPosition,
                    isNew: false,
                  });
                } else {
                  const displayP =
                    graphWidth -
                    0.053 * graphWidth -
                    0.118 * graphWidth +
                    0.033 * graphWidth;
                  const point = displayP / realtimeListDataList?.length;
                  seTonClickTable({
                    lineDate: record?.measureTime,
                    tooltipPosition: [
                      rowIndex * point + point / 2 + graphWidth * 0.037,
                      1,
                    ],
                    isNew: true,
                  });
                }
              },
            };
          }}
        />
      </div>
    </Spin>
  );
};

// make this component available to the app
export default MeasureDetailScene;
