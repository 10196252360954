import axios from 'axios';

// Create axios client
const instance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL || 'https://og-datalink.jp/',
  baseURL: process.env.REACT_APP_API_URL || 'https://og-datalink.jp/api',
  //baseURL : 'http://localhost:8080/api/'
  // request default time is 20000 millisecond
  //   timeout: 20000,
});

/* Instance custom default headers start */
instance.defaults.withCredentials = true;
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
// instance.defaults.headers.post.Cookie =
//   'JSESSIONID=YWIwMTdhMTItNDc3Ni00ZjM3LWI4N2QtY2NlNDc0ZTJjZDYx';
// instance.defaults.headers.get.Cookie =
//   'JSESSIONID=YWIwMTdhMTItNDc3Ni00ZjM3LWI4N2QtY2NlNDc0ZTJjZDYx';

// instance.defaults.headers.common['X-XSRF-TOKEN'] =
//   '3fbdb3f9-c65a-4ace-8cca-1123a118d0fb';

// instance.defaults.headers.post['og-app'] = '7v(!QyJYmmS9';
// instance.defaults.headers.get['og-app'] = '7v(!QyJYmmS9';
/* Instance custom default headers end */
// instance.defaults.headers.post.Cookie = document.cookie;

// Default statuses response.status >= 200 && response.status < 300
// instance.interceptors.response.use((response) => response);

instance.interceptors.response.use((response) => {
  if (response?.headers?.['set-cookie']) {
    const setCookie = response?.headers?.['set-cookie'].toString();
    const token = setCookie.split(';')?.[0]?.replace('XSRF-TOKEN=', '');
    instance.defaults.headers.common['X-XSRF-TOKEN'] = token;
  }
  return response;
});

export default instance;
