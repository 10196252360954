/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { LayoutManager } from '@app/layout';
import { useAuthContext } from '@app/providers/authProvider';

import { RoutesArray } from '@app/routes/routes';

const RouteRender = ({ component: Component, user, ...props }) => {
  const { requireRole, requireAuth, fallback = '/login' } = props;
  let roleCheck = true;
  let redirect = false;
  if (user) {
    const { role } = user;
    roleCheck = Array.isArray(requireRole)
      ? requireRole.includes(role)
      : requireRole
      ? requireRole === role
      : true;
    if (!requireAuth) redirect = true;
  } else if (requireAuth || requireRole) {
    roleCheck = false;
  }
  return roleCheck && !redirect ? (
    <LayoutManager route={props}>
      <Component />
    </LayoutManager>
  ) : (
    <Navigate to={fallback} />
  );
};

export const RouteManager = () => {
  const { user } = useAuthContext();
  return (
    <Routes>
      {RoutesArray.map((route, idx) => (
        <Route
          {...route}
          key={idx}
          element={<RouteRender {...route} user={user} />}
        />
      ))}
    </Routes>
  );
};
