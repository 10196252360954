import { isEmpty } from 'lodash';
import { isLanding, isHome, Routes, getRoute } from '@app/routes/routes';
import { getUser, logoutAsync } from '@app/services/auth/auth.service';

const checkAuth = async () => {
  try {
    const result = await getUser();
    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const logOut = async (navigate) => {
  try {
    await logoutAsync();
    navigate(Routes.Landing.Login.path);
    window.location.reload();
  } catch (error) {
    console.log('logoutAsync.err', error);
  }
};

const redirect = (navigate, route) => {
  try {
    navigate(route);
  } catch (error) {
    console.log(error);
  }
};

export const Auth = async (location, navigate) => {
  const route = location.pathname;
  const user = await checkAuth();
  const routeObject = getRoute(route);
  if (!isEmpty(user)) {
    if (isLanding(route)) {
      redirect(navigate, Routes.Home.UserList.path);
    } else {
      if (!routeObject) {
        redirect(navigate, Routes.Home.UserList.path);
      }
    }
    if (!localStorage.getItem('defaultFacility')) {
      localStorage.setItem(
        'defaultFacility',
        JSON.stringify(user.facilityList?.[0]),
      );
    }
    return { user };
  } else if (isHome(route)) {
    logOut(navigate);
    localStorage.removeItem('defaultFacility');
    return {};
  } else if (!isLanding(route)) {
    navigate(Routes.Landing.Login.path);
    localStorage.removeItem('defaultFacility');
    return {};
  }
  localStorage.removeItem('defaultFacility');
  return {};
};
