import moment from 'moment';
const beWell = [
  {
    title: '計測日',
    key: '1',
    dataIndex: 'measureDate',
    sorter: (a, b) =>
      moment(a?.measureDate, 'YYYY-MM-DD').diff(
        moment(b?.measureDate, 'YYYY-MM-DD'),
      ),
  },
  {
    title: 'BE-Wellモード',
    key: '2',
    dataIndex: 'bewellMode',
  },
  {
    title: '運動時間',
    key: '3',
    dataIndex: 'exerciseTime',
    sorter: (a, b) => a?.exerciseTime?.localeCompare(b?.exerciseTime),
  },
  {
    title: '累積歩数/回転数',
    key: '4',
    dataIndex: 'cumulative',
    className: 'number-text',
    sorter: (a, b) => a?.cumulative - b?.cumulative,
  },
  {
    title: '消費カロリー',
    key: '5',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
  {
    title: '距離',
    key: '6',
    dataIndex: 'distance',
    className: 'number-text',
    sorter: (a, b) => a?.distance - b?.distance,
  },
  {
    title: '体力レベル',
    key: '7',
    dataIndex: 'pLevel',
    className: 'number-text',
    sorter: (a, b) => a?.pLevel - b?.pLevel,
  },
  {
    title: '最大運動能力',
    key: '8',
    dataIndex: 'maxAthletic',
    className: 'number-text',
    sorter: (a, b) => a?.maxAthletic - b?.maxAthletic,
  },
  {
    title: '最大酸素摂取量',
    key: '9',
    dataIndex: 'maxOxygen',
    className: 'number-text',
    sorter: (a, b) => a?.maxOxygen - b?.maxOxygen,
  },
];
const beWellWithLance = [
  {
    title: '計測日',
    key: '1',
    dataIndex: 'measureDate',
    sorter: (a, b) =>
      moment(a?.measureDate, 'YYYY-MM-DD').diff(
        moment(b?.measureDate, 'YYYY-MM-DD'),
      ),
  },
  {
    title: '脈拍f',
    key: '2',
    dataIndex: 'pulseF',
    className: 'number-text',
    sorter: (a, b) => a?.pulseF - b?.pulseF,
  },
  {
    title: '酸素f',
    key: '3',
    dataIndex: 'oxygenF',
    className: 'number-text',
    sorter: (a, b) => a?.oxygenF - b?.oxygenF,
  },
  {
    title: '収縮圧f/拡張圧f',
    key: '4',
    className: 'number-text',
    render: (data) => (
      <div className='table-mw-100'>
        <span>
          {data?.sbpF}/{data?.dbpF}
        </span>
      </div>
    ),
  },
  {
    title: '脈拍',
    key: '5',
    dataIndex: 'pulse',
    className: 'number-text',
    sorter: (a, b) => a?.pulse - b?.pulse,
  },
  {
    title: '酸素',
    key: '6',
    dataIndex: 'oxygen',
    className: 'number-text',
    sorter: (a, b) => a?.oxygen - b?.oxygen,
  },
  {
    title: '収縮圧/拡張圧',
    key: '7',
    className: 'number-text',
    render: (record) => (
      <div className='table-mw-100'>
        <span>
          {record?.sbp}/{record?.dbp}
        </span>
      </div>
    ),
  },
  {
    title: '脈拍b',
    key: '8',
    dataIndex: 'pulseB',
    className: 'number-text',
    sorter: (a, b) => a?.pulseB - b?.pulseB,
  },
  {
    title: '酸素b',
    key: '9',
    dataIndex: 'oxygenB',
    className: 'number-text',
    sorter: (a, b) => a?.oxygenB - b?.oxygenB,
  },
  {
    title: '収縮圧b/拡張圧b',
    key: '10',
    className: 'number-text',
    render: (record) => (
      <div className='table-mw-100'>
        <span>
          {record?.sbpB}/{record?.dbpB}
        </span>
      </div>
    ),
  },
  {
    title: 'BE-Wellモード',
    key: '11',
    dataIndex: 'bewellMode',
  },
  {
    title: '運動時間',
    key: '12',
    dataIndex: 'exerciseTime',
    sorter: (a, b) => a?.exerciseTime?.localeCompare(b?.exerciseTime),
  },
  {
    title: '累積歩数/回転数',
    key: '13',
    dataIndex: 'cumulative',
    className: 'number-text',
    sorter: (a, b) => a?.cumulative - b?.cumulative,
  },
  {
    title: '消費カロリー',
    key: '14',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
  {
    title: '距離',
    key: '15',
    dataIndex: 'distance',
    className: 'number-text',
    sorter: (a, b) => a?.distance - b?.distance,
  },
  {
    title: '体力レベル',
    key: '16',
    dataIndex: 'pLevel',
    className: 'number-text',
    sorter: (a, b) => a?.pLevel - b?.pLevel,
  },
  {
    title: '最大運動能力',
    key: '17',
    dataIndex: 'maxAthletic',
    className: 'number-text',
    sorter: (a, b) => a?.maxAthletic - b?.maxAthletic,
  },
  {
    title: '最大酸素摂取量',
    key: '18',
    dataIndex: 'maxOxygen',
    className: 'number-text',
    sorter: (a, b) => a?.maxOxygen - b?.maxOxygen,
  },
];

const mederugo = [
  {
    title: '計測日',
    key: '1',
    dataIndex: 'measureDate',
    sorter: (a, b) =>
      moment(a?.measureDate, 'YYYY-MM-DD').diff(
        moment(b?.measureDate, 'YYYY-MM-DD'),
      ),
  },
  {
    title: '運動プログラム',
    key: '2',
    dataIndex: 'bewellMode',
  },
  {
    title: '運動時間',
    key: '3',
    dataIndex: 'exerciseTime',
    sorter: (a, b) => a?.exerciseTime?.localeCompare(b?.exerciseTime),
  },
  {
    title: '消費カロリー',
    key: '4',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
  {
    title: '体力レベル',
    key: '5',
    dataIndex: 'pLevel',
    className: 'number-text',
    sorter: (a, b) => a?.pLevel - b?.pLevel,
  },
  {
    title: '最大運動能力',
    key: '6',
    dataIndex: 'maxAthletic',
    className: 'number-text',
    sorter: (a, b) => a?.maxAthletic - b?.maxAthletic,
  },
  {
    title: '最大酸素摂取量',
    key: '7',
    dataIndex: 'maxOxygen',
    className: 'number-text',
    sorter: (a, b) => a?.maxOxygen - b?.maxOxygen,
  },
];

const mederugoWithLance = [
  {
    title: '計測日',
    key: '1',
    dataIndex: 'measureDate',
    sorter: (a, b) =>
      moment(a?.measureDate, 'YYYY-MM-DD').diff(
        moment(b?.measureDate, 'YYYY-MM-DD'),
      ),
  },
  {
    title: '脈拍f',
    key: '2',
    dataIndex: 'pulseF',
    className: 'number-text',
    sorter: (a, b) => a?.pulseF - b?.pulseF,
  },
  {
    title: '酸素f',
    key: '3',
    dataIndex: 'oxygenF',
    className: 'number-text',
    sorter: (a, b) => a?.oxygenF - b?.oxygenF,
  },
  {
    title: '収縮圧f/拡張圧f',
    key: '4',
    className: 'number-text',
    render: (data) => (
      <div className='table-mw-100'>
        <span>
          {data?.sbpF}/{data?.dbpF}
        </span>
      </div>
    ),
  },
  {
    title: '脈拍',
    key: '5',
    dataIndex: 'pulse',
    className: 'number-text',
    sorter: (a, b) => a?.pulse - b?.pulse,
  },
  {
    title: '酸素',
    key: '6',
    dataIndex: 'oxygen',
    className: 'number-text',
    sorter: (a, b) => a?.oxygen - b?.oxygen,
  },
  {
    title: '収縮圧/拡張圧',
    key: '7',
    className: 'number-text',
    render: (record) => (
      <div className='table-mw-100'>
        <span>
          {record?.sbp}/{record?.dbp}
        </span>
      </div>
    ),
  },
  {
    title: '脈拍b',
    key: '8',
    dataIndex: 'pulseB',
    className: 'number-text',
    sorter: (a, b) => a?.pulseB - b?.pulseB,
  },
  {
    title: '酸素b',
    key: '9',
    dataIndex: 'oxygenB',
    className: 'number-text',
    sorter: (a, b) => a?.oxygenB - b?.oxygenB,
  },
  {
    title: '収縮圧b/拡張圧b',
    key: '10',
    className: 'number-text',
    render: (record) => (
      <div className='table-mw-100'>
        <span>
          {record?.sbpB}/{record?.dbpB}
        </span>
      </div>
    ),
  },
  {
    title: '運動プログラム',
    key: '11',
    dataIndex: 'bewellMode',
  },
  {
    title: '運動時間',
    key: '12',
    dataIndex: 'exerciseTime',
    sorter: (a, b) => a?.exerciseTime?.localeCompare(b?.exerciseTime),
  },
  {
    title: '消費カロリー',
    key: '13',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
  {
    title: '体力レベル',
    key: '14',
    dataIndex: 'pLevel',
    className: 'number-text',
    sorter: (a, b) => a?.pLevel - b?.pLevel,
  },
  {
    title: '最大運動能力',
    key: '15',
    dataIndex: 'maxAthletic',
    className: 'number-text',
    sorter: (a, b) => a?.maxAthletic - b?.maxAthletic,
  },
  {
    title: '最大酸素摂取量',
    key: '16',
    dataIndex: 'maxOxygen',
    className: 'number-text',
    sorter: (a, b) => a?.maxOxygen - b?.maxOxygen,
  },
];

export { beWell, beWellWithLance, mederugo, mederugoWithLance };
