import moment from 'moment';

const beWellWithLance = [
  {
    title: '計測時刻',
    key: '1',
    render: (e) => (
      <div className='table-mw-80'>
        <span>{e?.measureTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      moment(a?.measureTime, 'HH:mm:ss').diff(
        moment(b?.measureTime, 'HH:mm:ss'),
      ),
  },
  {
    title: 'BE-Well経過時間',
    key: '2',
    render: (e) => (
      <div className='table-mw-100'>
        <span>{e?.beWellExerciseTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      parseInt(a?.beWellExerciseTime.split(':')[0]) * 60 +
      parseInt(a?.beWellExerciseTime.split(':')[1]) -
      (parseInt(b?.beWellExerciseTime.split(':')[0]) * 60 +
        parseInt(b?.beWellExerciseTime.split(':')[1])),
  },
  {
    title: 'モード',
    key: '3',
    dataIndex: 'trainingMode',
  },
  {
    title: 'BE-Well脈拍',
    key: '4',
    dataIndex: 'bewellPulse',
    className: 'number-text',
    sorter: (a, b) => a?.bewellPulse - b?.bewellPulse,
  },
  {
    title: 'ワット',
    key: '5',
    dataIndex: 'watt',
    className: 'number-text',
    sorter: (a, b) => a?.watt - b?.watt,
  },
  {
    title: '歩数/回転数',
    key: '6',
    dataIndex: 'step_rotation',
    className: 'number-text',
    sorter: (a, b) => a?.step_rotation - b?.step_rotation,
  },
  {
    title: 'ペダル負荷',
    key: '7',
    // dataIndex: 'loadLevel',
    className: 'number-text',
    render: (e) => <span>{e?.trainingMode === 'PT' ? '-' : e?.loadLevel}</span>,
    sorter: (a, b) => a?.loadLevel - b?.loadLevel,
  },
  {
    title: 'メッツ',
    key: '8',
    dataIndex: 'mets',
    className: 'number-text',
    sorter: (a, b) => a?.mets - b?.mets,
  },
  {
    title: '累積歩数/回転数',
    key: '9',
    dataIndex: 'cumulative',
    className: 'number-text',
    sorter: (a, b) => a?.cumulative - b?.cumulative,
  },
  {
    title: '消費カロリー',
    key: '10',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
  {
    title: '距離',
    key: '11',
    dataIndex: 'distance',
    className: 'number-text',
    sorter: (a, b) => a?.distance - b?.distance,
  },
  {
    title: 'スマートウォッチ経過時間',
    key: '12',
    dataIndex: 'lanceExerciseTime',
    sorter: (a, b) => a?.lanceExerciseTime - b?.lanceExerciseTime,
  },
  {
    title: '収縮圧',
    key: '13',
    dataIndex: 'sbp',
    className: 'number-text',
    sorter: (a, b) => a?.sbp - b?.sbp,
  },
  {
    title: '拡張圧',
    key: '14',
    dataIndex: 'dbp',
    className: 'number-text',
    sorter: (a, b) => a?.dbp - b?.dbp,
  },
  {
    title: '脈拍',
    key: '15',
    dataIndex: 'pulse',
    className: 'number-text',
    sorter: (a, b) => a?.pulse - b?.pulse,
  },
  {
    title: '酸素',
    key: '16',
    dataIndex: 'oxygen',
    className: 'number-text',
    sorter: (a, b) => a?.oxygen - b?.oxygen,
  },
];

const beWell = [
  {
    title: '計測時刻',
    key: '1',
    render: (e) => (
      <div className='table-mw-80'>
        <span>{e?.measureTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      moment(a?.measureTime, 'HH:mm:ss').diff(
        moment(b?.measureTime, 'HH:mm:ss'),
      ),
  },
  {
    title: 'BE-Well経過時間',
    key: '2',
    render: (e) => (
      <div className='table-mw-100'>
        <span>{e?.beWellExerciseTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      parseInt(a?.beWellExerciseTime.split(':')[0]) * 60 +
      parseInt(a?.beWellExerciseTime.split(':')[1]) -
      (parseInt(b?.beWellExerciseTime.split(':')[0]) * 60 +
        parseInt(b?.beWellExerciseTime.split(':')[1])),
  },
  {
    title: 'モード',
    key: '3',
    dataIndex: 'trainingMode',
  },
  {
    title: '脈拍',
    key: '4',
    dataIndex: 'bewellPulse',
    className: 'number-text',
    sorter: (a, b) => a?.bewellPulse - b?.bewellPulse,
  },
  {
    title: 'ワット',
    key: '5',
    dataIndex: 'watt',
    className: 'number-text',
    sorter: (a, b) => a?.watt - b?.watt,
  },
  {
    title: '歩数/回転数',
    key: '6',
    dataIndex: 'step_rotation',
    className: 'number-text',
    sorter: (a, b) => a?.step_rotation - b?.step_rotation,
  },
  {
    title: 'ペダル負荷',
    key: '7',
    //dataIndex: 'loadLevel',
    className: 'number-text',
    render: (e) => <span>{e?.trainingMode === 'PT' ? '-' : e?.loadLevel}</span>,
    sorter: (a, b) => a?.loadLevel - b?.loadLevel,
  },
  {
    title: 'メッツ',
    key: '8',
    dataIndex: 'mets',
    className: 'number-text',
    sorter: (a, b) => a?.mets - b?.mets,
  },
  {
    title: '累積歩数/回転数',
    key: '9',
    dataIndex: 'cumulative',
    className: 'number-text',
    sorter: (a, b) => a?.cumulative - b?.cumulative,
  },
  {
    title: '消費カロリー',
    key: '10',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
  {
    title: '距離',
    key: '11',
    dataIndex: 'distance',
    className: 'number-text',
    sorter: (a, b) => a?.distance - b?.distance,
  },
];

const mederugo = [
  {
    title: '計測時刻',
    key: '1',
    render: (e) => (
      <div className='table-mw-80'>
        <span>{e?.measureTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      moment(a?.measureTime, 'HH:mm:ss').diff(
        moment(b?.measureTime, 'HH:mm:ss'),
      ),
  },
  {
    title: 'メデルゴ経過時間',
    key: '2',
    render: (e) => (
      <div className='table-mw-100'>
        <span>{e?.mederugoExerciseTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      parseInt(a?.mederugoExerciseTime.split(':')[0]) * 60 +
      parseInt(a?.mederugoExerciseTime.split(':')[1]) -
      (parseInt(b?.mederugoExerciseTime.split(':')[0]) * 60 +
        parseInt(b?.mederugoExerciseTime.split(':')[1])),
  },
  {
    title: '運動プログラム',
    key: '3',
    dataIndex: 'trainingMode',
  },
  {
    title: 'メデルゴ脈拍',
    key: '4',
    dataIndex: 'bewellPulse',
    className: 'number-text',
    sorter: (a, b) => a?.bewellPulse - b?.bewellPulse,
  },
  {
    title: 'ワット',
    key: '5',
    dataIndex: 'watt',
    className: 'number-text',
    sorter: (a, b) => a?.watt - b?.watt,
  },
  {
    title: 'ペダル回転数',
    key: '6',
    dataIndex: 'step_rotation',
    className: 'number-text',
    sorter: (a, b) => a?.step_rotation - b?.step_rotation,
  },
  {
    title: 'ペダルトルク',
    key: '7',
    //dataIndex: 'loadLevel',
    className: 'number-text',
    render: (e) => (
      <span>{e?.trainingMode === 'PT' ? '-' : e?.pedalTorque}</span>
    ),
    sorter: (a, b) => a?.pedalTorque - b?.pedalTorque,
  },
  {
    title: '消費カロリー',
    key: '8',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
];

const mederugoWithLance = [
  {
    title: '計測時刻',
    key: '1',
    render: (e) => (
      <div className='table-mw-80'>
        <span>{e?.measureTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      moment(a?.measureTime, 'HH:mm:ss').diff(
        moment(b?.measureTime, 'HH:mm:ss'),
      ),
  },
  {
    title: 'メデルゴ経過時間',
    key: '2',
    render: (e) => (
      <div className='table-mw-100'>
        <span>{e?.mederugoExerciseTime}</span>
      </div>
    ),
    sorter: (a, b) =>
      parseInt(a?.mederugoExerciseTime.split(':')[0]) * 60 +
      parseInt(a?.mederugoExerciseTime.split(':')[1]) -
      (parseInt(b?.mederugoExerciseTime.split(':')[0]) * 60 +
        parseInt(b?.mederugoExerciseTime.split(':')[1])),
  },
  {
    title: '運動プログラム',
    key: '3',
    dataIndex: 'trainingMode',
  },
  {
    title: 'メデルゴ脈拍',
    key: '4',
    dataIndex: 'bewellPulse',
    className: 'number-text',
    sorter: (a, b) => a?.bewellPulse - b?.bewellPulse,
  },
  {
    title: 'ワット',
    key: '5',
    dataIndex: 'watt',
    className: 'number-text',
    sorter: (a, b) => a?.watt - b?.watt,
  },
  {
    title: 'ペダル回転数',
    key: '6',
    dataIndex: 'step_rotation',
    className: 'number-text',
    sorter: (a, b) => a?.step_rotation - b?.step_rotation,
  },
  {
    title: 'ペダルトルク',
    key: '7',
    //dataIndex: 'loadLevel',
    className: 'number-text',
    render: (e) => (
      <span>{e?.trainingMode === 'PT' ? '-' : e?.pedalTorque}</span>
    ),
    sorter: (a, b) => a?.pedalTorque - b?.pedalTorque,
  },
  {
    title: '消費カロリー',
    key: '8',
    dataIndex: 'cal',
    className: 'number-text',
    sorter: (a, b) => a?.cal - b?.cal,
  },
  {
    title: 'スマートウォッチ経過時間',
    key: '9',
    dataIndex: 'lanceExerciseTime',
    sorter: (a, b) => a?.lanceExerciseTime - b?.lanceExerciseTime,
  },
  {
    title: '収縮圧',
    key: '10',
    dataIndex: 'sbp',
    className: 'number-text',
    sorter: (a, b) => a?.sbp - b?.sbp,
  },
  {
    title: '拡張圧',
    key: '11',
    dataIndex: 'dbp',
    className: 'number-text',
    sorter: (a, b) => a?.dbp - b?.dbp,
  },
  {
    title: '脈拍',
    key: '12',
    dataIndex: 'pulse',
    className: 'number-text',
    sorter: (a, b) => a?.pulse - b?.pulse,
  },
  {
    title: '酸素',
    key: '13',
    dataIndex: 'oxygen',
    className: 'number-text',
    sorter: (a, b) => a?.oxygen - b?.oxygen,
  },
];

export { beWellWithLance, beWell, mederugo, mederugoWithLance };
