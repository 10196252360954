import React, { useEffect, useState } from 'react';
import { Title, Table } from '@app/components';
import { Button, Tabs, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useStableNavigate } from '@app/providers/stableNavigate';

import BasicInfoItem from './components/basicInfo.item';
import ContractListFilter from './components/contractlist.filter';
import SummaryInfoItem from './components/summaryInfo.item';
import { getContractorList } from '@app/services/contractor/contractor.service';
import { Routes } from '@app/routes/routes';
import queryString from 'query-string';
import { downloadCsvFromAPI } from '@app/utils/commonFunctions';

const { TabPane } = Tabs;

const ContractorListScene = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useStableNavigate();
  const params = queryString.parse(search);

  const [state, setState] = useState({
    contractList: [],
    pageNo: 1,
    loading: false,
    finished: false,
    isRefresh: true,
    spin: false,
  });

  const { contractList, pageNo, loading, finished, isRefresh, spin } = state;

  // Call class api after component did mount
  useEffect(() => {
    onSearch();
  }, []);

  // Infinity scroll when loading is true
  useEffect(() => {
    if (loading) {
      onSearch();
    }
  }, [loading]);

  // get contract list data
  const onSearch = async () => {
    try {
      // Result of Searched values to get API data
      const _res = await getContractorList({
        page: pageNo,
        contractFacilityName: searchParams.get('contractFacilityName'),
        prefectureId: searchParams.get('prefectureId'),
        accountCreateFrom: searchParams.get('accountCreateFrom')
          ? moment(new Date(searchParams.get('accountCreateFrom'))).format(
              'YYYY/MM/DD',
            )
          : null,
        accountCreateTo: searchParams.get('accountCreateTo')
          ? moment(new Date(searchParams.get('accountCreateTo'))).format(
              'YYYY/MM/DD',
            )
          : null,
        lastLoginFrom: searchParams.get('lastLoginFrom')
          ? moment(new Date(searchParams.get('lastLoginFrom'))).format(
              'YYYY/MM/DD',
            )
          : null,
        lastLoginTo: searchParams.get('lastLoginTo')
          ? moment(new Date(searchParams.get('lastLoginTo'))).format(
              'YYYY/MM/DD',
            )
          : null,
        tel: searchParams.get('tel'),
      });

      const contractListData = _res.data.contractList.map((data, index) => {
        const indexNew = (pageNo - 1) * 30 + index;
        return { ...data, no: indexNew + 1, key: indexNew + 10 };
      });
      setState({
        ...state,
        loading: false,
        isRefresh: false,
        finished: contractListData?.length < 30,
        contractList: [...contractList, ...contractListData],
      });
    } catch (error) {
      setState({ ...state, loading: false, isRefresh: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  const onDownloadContractorCsv = async () => {
    try {
      await downloadCsvFromAPI('admin/csv/contract/list', '', '契約者一覧', {});
    } catch (error) {
      //console.log(error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };
  // // Search function that request API with filtered values
  // const loadMoreData = async () => {
  //   if (pageNo > 0) {
  //     setState({ ...state, loading: true, pageNo: pageNo + 1 });
  //   }
  // };

  return (
    <Spin spinning={spin}>
      <div className='user-list'>
        <Title title='契約者一覧画面'>
          <Button type='text' onClick={onDownloadContractorCsv}>
            契約者一覧CSVダウンロード
          </Button>
        </Title>
        <ContractListFilter
          onSearch={() => {
            setState({ ...state, pageNo: 1, contractList: [] });
          }}
        />
        {/* <InfiniteScroll
          dataLength={contractList.length}
          // next={loadMoreData}
          hasMore={!finished}
        > */}
        <Tabs defaultActiveKey='1'>
          <TabPane tab='基本情報' key='1'>
            <Table
              rowSelection=''
              className='user-list-table'
              loading={isRefresh || loading}
              dataSource={contractList}
              columns={BasicInfoItem()}
            />
          </TabPane>
          <TabPane tab='サマリー情報' key='2'>
            <Table
              rowSelection=''
              className='user-list-table'
              loading={isRefresh || loading}
              dataSource={contractList}
              columns={SummaryInfoItem()}
            />
          </TabPane>
        </Tabs>
        {/* </InfiniteScroll> */}
      </div>
    </Spin>
  );
};

export default ContractorListScene;
