import { dataFormatterTime } from '@app/utils/commonFunctions';

const selectedDefault = {
  脈拍: true,
  酸素: true,
  収縮圧: true,
  拡張圧: true,
};

export const vitalStackOptions = (
  _realtimeListDataList,
  selectVisible,
  //setGraphTooltipData,
  axis,
  markerArray,
  stackObject,
  isMeasureTime,
) => {
  const realtimeListDataList = dataFormatterTime(
    _realtimeListDataList,
    'isVitalPlot',
  );
  const selected = {
    ...stackObject?.reduce((p, item) => {
      return { ...p, [item.seriesName]: item?.isView };
    }, {}),
  };
  return lineGraph(
    realtimeListDataList,
    selectVisible,
    //setGraphTooltipData,
    axis,
    stackObject ? selected : selectedDefault,
    markerArray,
    isMeasureTime,
  );
};

const lineGraph = (
  realtimeListDataList,
  selectVisible,
  //setGraphTooltipData,
  axis,
  selected,
  markerArray,
  isMeasureTime,
) => {
  return {
    legend: {
      data: ['収縮圧', '拡張圧', '脈拍', '酸素'],
      //icon: 'circle',
      top: selectVisible.beWell === 2 ? '95%' : '90%',
      left: 25,
      selected: { ...selected },
    },
    // tooltip: {
    //   trigger: 'axis',
    //   triggerOn: 'click',
    //   formatter: () => {
    //     return '';
    //   },
    //   position: function (point, params) {
    //     setGraphTooltipData({
    //       tooltipPosition: point,
    //       lineDate: params[0]?.axisValue,
    //     });
    //     return [point[0], '10%'];
    //   },
    // },
    // xAxis: {
    //   type: 'category',
    //   data: realtimeListDataList?.measureTime || [],
    // },
    xAxis: {
      type: 'category',
      axisLabel: {
        formatter: function (value) {
          if (isMeasureTime === 2) {
            const data = realtimeListDataList?.lanceExerciseTime?.find(
              (item) => item?.[0] === value,
            );
            return data?.[1];
          } else {
            return value;
          }
        },
      },
      data: realtimeListDataList?.measureTime || [],
    },
    yAxis: {
      type: 'value',
      scale: true,
      position: 'left',
      max: axis?.maxAxisVital,
      min: axis?.lowAxisVital,
    },
    grid: [
      {
        top: 5,
        bottom: 60,
        left: 50,
      },
    ],
    dataZoom: {
      show: false,
      type: 'slider',
    },
    series: [
      {
        name: 'Precipitation',
        type: 'bar',
        barWidth: 2,
        yAxisIndex: 0,
        tooltip: {
          valueFormatter: function (value) {
            return value + ' ml';
          },
        },
        data: markerArray,
      },
      {
        name: '脈拍',
        type: 'line',
        showAllSymbol: true,
        data: realtimeListDataList?.pulse,
        color: '#D1392C',
      },

      {
        name: '酸素',
        type: 'line',
        showAllSymbol: true,
        data: realtimeListDataList?.oxygen,
        color: '#3092EA',
      },
      {
        name: '収縮圧',
        type: 'line',
        showAllSymbol: true,
        data: realtimeListDataList?.sbp,
        color: '#454545',
      },
      {
        name: '拡張圧',
        type: 'line',
        showAllSymbol: true,
        data: realtimeListDataList?.dbp,
        color: '#454545',
      },
    ],
  };
};
