// import libraries
import React, { useRef, useEffect } from 'react';
import { Button, Select, Radio } from 'antd';

import { deviceNames } from '@app/utils/static';
const { Option } = Select;

export const useHorizontalScroll = () => {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);
  return elRef;
};

// create a component
const Title = ({
  isLance,
  title = '',
  children,
  list = false,
  noButtons = false,
  deviceWe = false,
  horizontal = false,
  state = {},
  setState = () => {},
  onTakePhoto = () => {},
  photoLoading = false,
  overlayChart = false,
  setOverlayChart = () => {},
  measureDetail,
  onDisplayChange,
  onClickMarkerClear,
  markerClearDisable,
  lanceMeasureList = [],
  beWellAndDongleMeasureList = [],
  chooseLanceMeasureMent = {},
  chooseBeWellMeasureMent = {},
  bewellDonglePickerDonePress = () => {},
  lancePickerDonePress = () => {},
  isPicker = false,
}) => {
  const { selectWe, selectGraph, selectVisible } = state;
  const scrollRef = useHorizontalScroll();

  const val = Object.keys(deviceNames)?.find((i) => deviceNames?.[i] === title);

  const changeOption = (e) => {
    if (chooseBeWellMeasureMent && isPicker && deviceWe) {
      if (
        chooseBeWellMeasureMent?.rehabilitationId === 2 ||
        chooseBeWellMeasureMent?.rehabilitationId === 8 ||
        chooseBeWellMeasureMent?.rehabilitationId === 11 ||
        chooseBeWellMeasureMent?.rehabilitationId === 12 ||
        chooseBeWellMeasureMent?.rehabilitationId === 13
      ) {
        setState({
          ...state,
          selectVisible: {
            ...selectVisible,
            beWell: e.target.value,
          },
        });
      }
    } else {
      setState({
        ...state,
        selectVisible: {
          ...selectVisible,
          [val]: e.target.value,
        },
      });
    }
    onDisplayChange();
  };

  const groupByLabelNames = beWellAndDongleMeasureList.reduce(
    (prev, current) => {
      const filteredData = beWellAndDongleMeasureList?.filter(
        (a) => a?.rehabilitationId === current?.rehabilitationId,
      );
      if (filteredData?.length > 0) {
        const _index = filteredData.findIndex(
          (e) => e.measureInfoId === current.measureInfoId,
        );

        current = {
          ...current,
          label: `${current.individualRehabiliName}(${
            filteredData?.length - _index
          })`,
          value: current.measureInfoId,
        };
        return [...prev, current];
      }
    },
    [],
  );

  const isDisabled = () => {
    if (deviceWe) {
      if (selectVisible?.beWell === 1) {
        if (selectVisible?.smartWatch !== 1) {
          return true;
        } else {
          return false;
        }
      } else {
        if (selectVisible?.smartWatch === 1) {
          return false;
        } else return true;
      }
    } else {
      if (selectVisible?.smartWatch === 1) {
        if (selectVisible?.beWell !== 1) {
          return true;
        } else {
          return false;
        }
      } else {
        if (selectVisible?.beWell === 1) {
          return false;
        } else return true;
      }
    }
  };
  return (
    <div className='title'>
      <div
        ref={scrollRef}
        className={`title-container ${list ? 'list' : ''} ${
          horizontal ? 'horizontal' : ''
        }`}
      >
        {!isPicker && !deviceWe && (
          <div className='title-text'>
            <h1>{title}</h1>{' '}
          </div>
        )}
        {deviceWe && !isPicker && (
          <div className='title-text'>
            <Select
              size='small'
              onChange={(e) => {
                setState({ ...state, selectWe: e });
                onDisplayChange();
              }}
              defaultValue={selectWe}
            >
              <Option key={8} value={8}>
                WE-100
              </Option>
              <Option key={2} value={2}>
                WE-110
              </Option>
              <Option key={11} value={11}>
                EM-300
              </Option>
              <Option key={12} value={12}>
                EM-350
              </Option>
              <Option key={13} value={13}>
                EM-400
              </Option>
            </Select>
          </div>
        )}

        {deviceWe && isPicker && groupByLabelNames?.length > 0 && (
          <>
            <div className='title-text'>
              <h1>{title}</h1>
            </div>
            <Select
              size='small'
              onChange={(e) => {
                bewellDonglePickerDonePress(e);
                onDisplayChange();
              }}
              value={chooseBeWellMeasureMent?.measureInfoId}
            >
              {groupByLabelNames?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </>
        )}
        {overlayChart && isPicker && (
          <div className='title-text'>
            <h1>{title}</h1>
          </div>
        )}
        {!overlayChart && !deviceWe && isPicker && (
          <>
            <div className='title-text'>
              <h1>{title}</h1>
            </div>
            <Select
              size='small'
              onChange={(e) => {
                lancePickerDonePress(e);
                onDisplayChange();
              }}
              value={chooseLanceMeasureMent?.measureInfoId}
            >
              {(lanceMeasureList || [])?.map((item, index) => (
                <Option key={index} value={item.measureInfoId}>
                  {`${item.individualRehabiliName}(${
                    lanceMeasureList?.length - index
                  })`}
                </Option>
              ))}
            </Select>
          </>
        )}
        <div className='button-container'>
          {!list ? (
            <div className='header'>{children}</div>
          ) : (
            <>
              {!noButtons && (
                <div className='list-buttons'>
                  {!overlayChart && (
                    <Radio.Group
                      disabled={isDisabled()}
                      onChange={(e) => changeOption(e)}
                      defaultValue={selectVisible[val] || 1}
                    >
                      <Radio value={1}>表示</Radio>
                      <Radio value={2}>非表示</Radio>
                    </Radio.Group>
                  )}
                  {/* 計測詳細（ランスバンドあり） */}
                  {measureDetail && !deviceWe && (
                    <React.Fragment>
                      <span className='measureOverlay'>
                        <Button
                          size='small'
                          type='primary'
                          onClick={() => {
                            setOverlayChart(!overlayChart);
                            onDisplayChange();
                          }}
                        >
                          {!overlayChart ? 'グラフ重ね' : 'グラフを分離'}
                        </Button>
                      </span>
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => {
                          onClickMarkerClear();
                        }}
                        disabled={markerClearDisable}
                      >
                        マーカー削除
                      </Button>
                      {!overlayChart && (
                        <Radio.Group
                          onChange={(e) => {
                            setState({
                              ...state,
                              isMeasureTime: e.target.value,
                            });
                            onDisplayChange();
                          }}
                          defaultValue={state?.isMeasureTime}
                          style={{ marginLeft: '10px' }}
                        >
                          <Radio value={1}>計測時刻表示</Radio>
                          <Radio value={2}>経過時間表示</Radio>
                        </Radio.Group>
                      )}
                    </React.Fragment>
                  )}
                  {/* 計測詳細（ランスバンド無し） */}
                  {measureDetail && isLance !== '1' && (
                    <React.Fragment>
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => {
                          onClickMarkerClear();
                        }}
                        disabled={markerClearDisable}
                      >
                        マーカー削除
                      </Button>
                      {!overlayChart && (
                        <Radio.Group
                          onChange={(e) => {
                            setState({
                              ...state,
                              isMeasureTime: e.target.value,
                            });
                            onDisplayChange();
                          }}
                          defaultValue={state?.isMeasureTime}
                          style={{ marginLeft: '10px' }}
                        >
                          <Radio value={1}>計測時刻表示</Radio>
                          <Radio value={2}>経過時間表示</Radio>
                        </Radio.Group>
                      )}
                    </React.Fragment>
                  )}
                  {/* 使用者詳細（ランスバンドあり） */}
                  {!measureDetail && (isLance !== '1' ? deviceWe : !deviceWe) && (
                    <React.Fragment>
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => {
                          onClickMarkerClear();
                        }}
                        disabled={markerClearDisable}
                      >
                        マーカー削除
                      </Button>
                    </React.Fragment>
                  )}

                  <div className='download'>
                    {!measureDetail &&
                      (isLance !== '1' ? deviceWe : !deviceWe) && (
                        <Select
                          size='small'
                          onChange={(e) => {
                            onDisplayChange();
                            setState({ ...state, selectGraph: e });
                          }}
                          defaultValue={selectGraph}
                        >
                          <Option key={1} value={1}>
                            折れ線グラフ
                          </Option>
                          <Option key={2} value={2}>
                            棒グラフ（日別）
                          </Option>
                          <Option key={3} value={3}>
                            棒グラフ（指標別）
                          </Option>
                        </Select>
                      )}
                    {(isLance !== '1' ? deviceWe : !deviceWe) && (
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => onTakePhoto()}
                        loading={photoLoading}
                      >
                        グラフ画像ダウンロード
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {list && <div className='title-body-container'>{children}</div>}
    </div>
  );
};

// make this component available to the app
export default Title;
