export const chartZoomOptions = (
  patientDailySummaryDataList,
  selectGraph,
  measureDetail,
) => {
  return {
    xAxis: [
      {
        type: 'category',
        show: false,
        data:
          selectGraph !== 3
            ? patientDailySummaryDataList?.map((item) =>
                measureDetail ? item.measureTime : item.measureDate,
              )
            : null,
      },
    ],
    yAxis: [
      {
        show: false,
        type: 'value',
        scale: true,
        position: 'left',
      },
    ],
    dataZoom: [
      {
        show: true,
        type: 'slider',
        top: 10,
        height: 40,
        left: 0,
        filterMode: 'none',
      },
    ],
  };
};
