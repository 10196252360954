// import libraries
import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { useSearchParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

//import utils
import { Table, Title } from '@app/components';
import { useAuthContext } from '@app/providers/authProvider';
import { getPatientList, delPatient } from '@app/services/user/user.service';
import { Routes } from '@app/routes/routes';
import { useStableNavigate } from '@app/providers/stableNavigate';

import { downloadCsvFromAPI } from '@app/utils/commonFunctions';
import UserListFilter from '@app/scenes/home/users/userlist/components/userlist.filter';
import UserListItem from './components/userlist.item';

// create a component
const UserlistScene = () => {
  const { userFacility } = useAuthContext();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const params = queryString.parse(search);
  const navigate = useStableNavigate();
  const [selPatient, setSelPatient] = useState([]);
  const [checkedList, setCheckedList] = useState({});
  const [state, setState] = useState({
    patientDataList: [],
    pageNo: 1,
    loading: false,
    finished: false,
    isRefresh: true,
    patDelete: 0,
    spin: false,
  });

  const {
    patientDataList,
    pageNo,
    loading,
    isRefresh,
    finished,
    patDelete,
    spin,
  } = state;

  // Call class api after component did mount
  useEffect(() => {
    onSearch();
  }, []);

  // Infinity scroll when loading is true
  useEffect(() => {
    if (loading) {
      onSearch();
    }
  }, [loading]);

  //削除後のリロード
  useEffect(() => {
    onSearch();
  }, [patDelete]);

  // Search function that request API with filtered values
  const loadMoreData = async () => {
    if (pageNo > 0) {
      setState({ ...state, loading: true, pageNo: pageNo + 1 });
    }
  };

  const onSearch = async () => {
    try {
      // Result of Searched values to get API data
      const _res = await getPatientList({
        page: pageNo,
        facilityId: +params?.facilityId || userFacility?.facilityId || null,
        carteNo: searchParams.get('carteNo'),
        birthdayFrom: searchParams.get('birthdayFrom')
          ? moment(new Date(searchParams.get('birthdayFrom'))).format(
              'YYYY-MM-DD',
            )
          : null,
        birthdayTo: searchParams.get('birthdayTo')
          ? moment(new Date(searchParams.get('birthdayTo'))).format(
              'YYYY-MM-DD',
            )
          : null,
        dataNo: searchParams.get('dataNo'),
        receptDateFrom: searchParams.get('receptDateFrom')
          ? moment(new Date(searchParams.get('receptDateFrom'))).format(
              'YYYY-MM-DD',
            )
          : null,
        receptDateTo: searchParams.get('receptDateTo')
          ? moment(new Date(searchParams.get('receptDateTo'))).format(
              'YYYY-MM-DD',
            )
          : null,
        patientName: searchParams.get('patientName'),
        gender: searchParams.get('gender'),
        staffName: searchParams.get('staffName'),
        classId: searchParams.get('classId'),
      });

      const patientListData = _res.data.patientResponseList.map(
        (data, index) => {
          const indexNew = (pageNo - 1) * 30 + index;
          return { ...data, no: indexNew + 1, key: indexNew + 10 };
          //key: indexNew + 10 };
        },
      );
      setState({
        ...state,
        loading: false,
        isRefresh: false,
        finished: patientListData?.length < 30,
        patientDataList: [...patientDataList, ...patientListData],
      });
    } catch (error) {
      setState({ ...state, loading: false, isRefresh: false });
      // if (error === 'A001-001') {
      //   navigate(Routes.Landing.Login.path);
      //   window.location.reload();
      // }
    }
  };
  const onDownloadPatientCsv = async () => {
    try {
      await downloadCsvFromAPI(
        '/csv/patient/list',
        params?.facilityId ? { facilityId: +params?.facilityId } : userFacility,
        '使用者一覧',
      );
    } catch (error) {
      //console.log(error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };
  const onCheckUser = (patientId, checked) => {
    setCheckedList({
      ...checkedList,
      [patientId]: checked,
    });
    if (checked) {
      if (patientId) setSelPatient([...selPatient, patientId]);
    } else {
      if (patientId) {
        setSelPatient(
          selPatient.filter((val) => {
            if (val === patientId) {
              return false;
            }
            return true;
          }),
        );
      }
    }
    return;
  };

  const onDelPatient = async () => {
    //削除APIのコール
    try {
      setState({ ...state, spin: true });
      await delPatient({
        facilityId: userFacility?.facilityId || 0,
        patientIds: selPatient || [],
      });
      setCheckedList({});
      setSelPatient([]);
      setState({ ...state, spin: false });
    } catch (error) {
      setState({ ...state, spin: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
    //画面再ロード
    setState({
      ...state,
      patDelete: patDelete + 1,
      pageNo: 1,
      patientDataList: [],
    });
  };

  const confirm = () => {
    Modal.confirm({
      title: '使用者の削除',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <br />
          <p>選択された{selPatient.length}件の使用者を削除します。</p>
          <p>よろしいですか？</p>
        </>
      ),
      okText: '削除',
      cancelText: 'キャンセル',
      onOk: onDelPatient,
    });
  };
  return (
    <Spin spinning={spin}>
      <div className='user-list'>
        <Title title='使用者一覧画面'>
          <Button type='text' onClick={onDownloadPatientCsv}>
            使用者一覧CSVダウンロード
          </Button>
        </Title>
        <UserListFilter
          onSearch={() => {
            setState({
              ...state,
              pageNo: 1,
              patientDataList: [],
            });
          }}
        />
        <InfiniteScroll
          dataLength={patientDataList.length}
          next={loadMoreData}
          hasMore={!finished}
        >
          <div style={{ paddingRight: '5px', paddingLeft: '5px' }}>
            <Button
              type='primary'
              disabled={selPatient.length < 1}
              onClick={() => {
                confirm();
              }}
              size='small'
              style={{
                width: '50px',
                background: 'dimgray',
                border: 'none',
                marginBottom: 5,
              }}
            >
              削除
            </Button>
          </div>
          <Table
            rowSelection=''
            className='user-list-table'
            loading={isRefresh || loading}
            dataSource={patientDataList}
            columns={UserListItem({
              isDetail: false,
              onCheckUser: onCheckUser,
              checkedList: checkedList,
            })}
          />
        </InfiniteScroll>
      </div>
    </Spin>
  );
};

// make this component available to the app
export default UserlistScene;
