import html2canvas from 'html2canvas';

export const screenShot = (node, fileName) => {
  return new Promise((resolve, reject) => {
    html2canvas(node, { scale: 2 })
      .then(function (canvas) {
        var croppedCanvas = document.createElement('canvas');
        var croppedCanvasContext = croppedCanvas.getContext('2d'); // init data
        var cropPositionTop = 0;
        var cropPositionLeft = 0;
        var cropWidth = canvas.width;
        var cropHeight = canvas.height;
        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;
        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop,
        );
        var base64Image = croppedCanvas.toDataURL('image/jpeg', 1.0);
        fetch(base64Image)
          .then((res) => res.blob())
          .then((img) => {
            var a = document.createElement('a');
            const url = window.URL.createObjectURL(img);
            a.href = url;
            a.download = fileName;
            a.click();
          });
        resolve(true);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
