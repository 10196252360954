// import libraries
import {
  beWell,
  beWellWithLance,
  mederugo,
  mederugoWithLance,
} from '@app/utils/summaryGraphTable';
// create a component
const SummaryItem = (isLance, rehabilitationId) => {
  switch (rehabilitationId) {
    case 2:
      return isLance !== '1' ? beWell : beWellWithLance;
    case 8:
      return isLance !== '1' ? beWell : beWellWithLance;
    case 11:
      return isLance !== '1' ? mederugo : mederugoWithLance;
    case 12:
      return isLance !== '1' ? mederugo : mederugoWithLance;
    case 13:
      return isLance !== '1' ? mederugo : mederugoWithLance;
    default:
      return isLance !== '1' ? beWell : beWellWithLance;
  }
};

// make this component available to the app
export default SummaryItem;
