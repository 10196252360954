import {
  convertLabelBarChartData,
  convertExerciseTime,
  dataFormatter,
  dataFormatterBabel,
} from '@app/utils/commonFunctions';

const selectedDefault = {
  運動時間: true,
  '累積歩数/回転数': true,
  消費カロリー: true,
  距離: true,
};

export const aerobicStackOptions = (
  selectGraph,
  _patientDailySummaryDataList,
  isLance,
  selectVisible,
  //setGraphTooltipData,
  axis,
  markerArray,
  stackObject,
  rehabilitationId,
) => {
  switch (selectGraph) {
    case 1: {
      const patientDailySummaryDataList = dataFormatter(
        _patientDailySummaryDataList,
        'isAerobicPlot',
      );
      const selected = {
        ...stackObject?.reduce((p, item) => {
          return { ...p, [item.seriesName]: item?.isView };
        }, {}),
      };
      return lineGraph(
        patientDailySummaryDataList,
        isLance,
        selectVisible,
        //setGraphTooltipData,
        axis,
        stackObject ? selected : selectedDefault,
        markerArray,
        rehabilitationId,
      );
    }
    case 2: {
      const patientDailySummaryDataList = dataFormatter(
        _patientDailySummaryDataList,
        'isAerobicPlot',
      );
      return barDateGraph(
        patientDailySummaryDataList,
        isLance,
        selectVisible,
        rehabilitationId,
      );
    }
    case 3: {
      const patientDailySummaryDataList = dataFormatterBabel(
        _patientDailySummaryDataList,
        'isAerobicPlot',
      );

      return barLabelGraph(
        patientDailySummaryDataList,
        isLance,
        selectVisible,
        rehabilitationId,
      );
    }
    default:
      break;
  }
};

const lineGraph = (
  patientDailySummaryDataList,
  isLance,
  selectVisible,
  //setGraphTooltipData,
  axis,
  selected,
  markerArray,
  rehabilitationId,
) => {
  if (rehabilitationId === 2 || rehabilitationId === 8) {
    return {
      legend: {
        data: ['運動時間', '累積歩数/回転数', '消費カロリー', '距離'],
        //icon: 'circle',
        top: selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
        left: 25,
        selected: { ...selected },
      },

      xAxis: {
        type: 'category',
        data: patientDailySummaryDataList?.measureDate || [],
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
        max: axis?.maxAxisAero,
        min: axis?.lowAxisAero,
      },
      grid: [
        {
          top: 5,
          bottom: isLance === '1' ? 60 : 70,
          left: 50,
        },
      ],
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          barWidth: 2,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ml';
            },
          },
          data: markerArray,
        },
        {
          name: '運動時間',
          type: 'line',
          showAllSymbol: true,
          data: convertExerciseTime(patientDailySummaryDataList),
          color: '#000',
        },
        {
          name: '累積歩数/回転数',
          type: 'line',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.cumulative,
          color: '#364F77',
        },
        {
          name: '消費カロリー',
          type: 'line',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.cal,
          color: '#D36BB3',
        },
        {
          name: '距離',
          type: 'line',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.distance,
          color: '#9C22E8',
        },
      ],
    };
  }
  if (
    rehabilitationId === 11 ||
    rehabilitationId === 12 ||
    rehabilitationId === 13
  ) {
    return {
      legend: {
        data: ['運動時間', '消費カロリー'],
        //icon: 'circle',
        top: selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
        left: 25,
        selected: { ...selected },
      },
      xAxis: {
        type: 'category',
        data: patientDailySummaryDataList?.measureDate || [],
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
        max: axis?.maxAxisAero,
        min: axis?.lowAxisAero,
      },
      grid: [
        {
          top: 5,
          bottom: isLance === '1' ? 60 : 70,
          left: 50,
        },
      ],
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          barWidth: 2,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ml';
            },
          },
          data: markerArray,
        },
        {
          name: '運動時間',
          type: 'line',
          showAllSymbol: true,
          data: convertExerciseTime(patientDailySummaryDataList),
          color: '#000',
        },
        {
          name: '消費カロリー',
          type: 'line',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.cal,
          color: '#D36BB3',
        },
      ],
    };
  }
};

const barDateGraph = (
  patientDailySummaryDataList,
  isLance,
  selectVisible,
  rehabilitationId,
) => {
  if (rehabilitationId === 2 || rehabilitationId === 8) {
    return {
      legend: [
        {
          data: ['運動時間', '累積歩数/回転数', '消費カロリー', '距離'],
          icon: 'circle',
          top:
            selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
          left: 25,
          color: '#9C22E8',
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: patientDailySummaryDataList?.measureDate || [],
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
      },
      grid: {
        top: 5,
        bottom: isLance === '1' ? 60 : 70,
        left: 50,
      },
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: '運動時間',
          type: 'bar',
          showAllSymbol: true,
          data: convertExerciseTime(patientDailySummaryDataList),
        },
        {
          name: '累積歩数/回転数',
          type: 'bar',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.cumulative,
          color: '#364F77',
        },
        {
          name: '消費カロリー',
          type: 'bar',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.cal,
        },
        {
          name: '距離',
          type: 'bar',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.distance,
          color: '#9C22E8',
        },
      ],
    };
  }
  if (
    rehabilitationId === 11 ||
    rehabilitationId === 12 ||
    rehabilitationId === 13
  ) {
    return {
      legend: [
        {
          data: ['運動時間', '消費カロリー'],
          icon: 'circle',
          top:
            selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
          left: 25,
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: patientDailySummaryDataList?.measureDate || [],
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
      },
      grid: {
        top: 5,
        bottom: isLance === '1' ? 60 : 70,
        left: 50,
      },
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: '運動時間',
          type: 'bar',
          showAllSymbol: true,
          data: convertExerciseTime(patientDailySummaryDataList),
          color: '#000',
        },
        {
          name: '消費カロリー',
          type: 'bar',
          showAllSymbol: true,
          data: patientDailySummaryDataList?.cal,
          color: '#D36BB3',
        },
      ],
    };
  }
};

const barLabelGraph = (
  patientDailySummaryDataList,
  isLance,
  selectVisible,
  rehabilitationId,
) => {
  if (rehabilitationId === 2 || rehabilitationId === 8) {
    return {
      legend: [
        {
          data: patientDailySummaryDataList?.measureDate,
          icon: 'circle',
          top:
            selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
          left: 25,
        },
      ],
      tooltip: { trigger: 'axis' },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
      },
      grid: {
        top: 5,
        bottom: isLance === '1' ? 60 : 70,
        left: 50,
      },
      dataZoom: {
        show: false,
        type: 'slider',
      },
      dataset: {
        dimensions: [
          'productName',
          ...(patientDailySummaryDataList?.measureDate || []),
        ],
        source: [
          {
            productName: '運動時間',
            ...convertLabelBarChartData(
              patientDailySummaryDataList,
              'exerciseTime',
            ),
            color: '#000',
          },
          {
            productName: '累積歩数/回転数',
            ...convertLabelBarChartData(
              patientDailySummaryDataList,
              'cumulative',
            ),
            color: '#364F77',
          },
          {
            productName: '消費カロリー',
            ...convertLabelBarChartData(patientDailySummaryDataList, 'cal'),
            color: '#D36BB3',
          },
          {
            productName: '距離',
            ...convertLabelBarChartData(
              patientDailySummaryDataList,
              'distance',
            ),
            color: '#9C22E8',
          },
        ],
      },
      series:
        patientDailySummaryDataList?.measureDate?.length > 0
          ? Array(patientDailySummaryDataList?.measureDate.length)
              .fill(0)
              .map(() => ({
                type: 'bar',
              }))
          : [{}],
    };
  }
  if (
    rehabilitationId === 11 ||
    rehabilitationId === 12 ||
    rehabilitationId === 13
  ) {
    return {
      legend: [
        {
          data: patientDailySummaryDataList?.measureDate,
          icon: 'circle',
          top:
            selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
          left: 25,
        },
      ],
      tooltip: { trigger: 'axis' },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
      },
      grid: {
        top: 5,
        bottom: isLance === '1' ? 60 : 70,
        left: 50,
      },
      dataZoom: {
        show: false,
        type: 'slider',
      },
      dataset: {
        dimensions: [
          'productName',
          ...(patientDailySummaryDataList?.measureDate || []),
        ],
        source: [
          {
            productName: '運動時間',
            ...convertLabelBarChartData(
              patientDailySummaryDataList,
              'exerciseTime',
            ),
            color: '#000',
          },
          {
            productName: '消費カロリー',
            ...convertLabelBarChartData(patientDailySummaryDataList, 'cal'),
            color: '#D36BB3',
          },
        ],
      },
      series:
        patientDailySummaryDataList?.measureDate?.length > 0
          ? Array(patientDailySummaryDataList?.measureDate.length)
              .fill(0)
              .map(() => ({
                type: 'bar',
              }))
          : [{}],
    };
  }
};
