import { dataFormatterTime } from '@app/utils/commonFunctions';

const selectedDefault = {
  脈拍: true,
  ワット: true,
  メッツ: true,
  ペダル負荷: true,
  '歩数/回転数': true,
  '累積歩数/回転数': true,
  消費カロリー: true,
  距離: true,
};
export const aerobicStackOptions = (
  _realtimeListDataList,
  isLance,
  selectVisible,
  //setGraphTooltipData,
  axis,
  markerArray,
  stackObject,
  rehabilitationId,
  isMeasureTime,
) => {
  const realtimeListDataList = dataFormatterTime(
    _realtimeListDataList,
    'isAerobicPlot',
  );

  const selected = {
    ...stackObject?.reduce((p, item) => {
      return { ...p, [item.seriesName]: item?.isView };
    }, {}),
  };

  return lineGraph(
    realtimeListDataList,
    isLance,
    selectVisible,
    //setGraphTooltipData,
    axis,
    stackObject ? selected : selectedDefault,
    markerArray,
    rehabilitationId,
    isMeasureTime,
  );
};

const lineGraph = (
  realtimeListDataList,
  isLance,
  selectVisible,
  //setGraphTooltipData,
  axis,
  selected,
  markerArray,
  rehabilitationId,
  isMeasureTime,
) => {
  if (rehabilitationId === 2 || rehabilitationId === 8) {
    return {
      legend: {
        data: [
          '脈拍',
          'ワット',
          '歩数/回転数',
          'ペダル負荷',
          'メッツ',
          '累積歩数/回転数',
          '消費カロリー',
          '距離',
        ],
        //icon: 'circle',
        top: selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
        left: 25,
        selected: { ...selected },
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          formatter: function (value) {
            if (isMeasureTime === 2) {
              const data = realtimeListDataList?.beWellExerciseTime?.find(
                (item) => item?.[0] === value,
              );
              return data?.[1];
            } else {
              return value;
            }
          },
        },
        data: realtimeListDataList?.measureTime || [],
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
        max: axis?.maxAxisAero,
        min: axis?.lowAxisAero,
      },
      grid: [
        {
          top: 5,
          bottom: isLance === '1' ? 60 : 70,
          left: 50,
        },
      ],
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          barWidth: 2,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ml';
            },
          },
          data: markerArray,
        },
        {
          name: '脈拍',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.bewellPulse,
          color: '#F73131',
        },
        {
          name: 'ワット',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.watt,
          color: '#FDB01D',
        },
        {
          name: 'メッツ',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.mets,
          color: '#6B6FD3',
        },
        {
          name: 'ペダル負荷',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.loadLevel,
          color: '#1399CB',
        },
        {
          name: '歩数/回転数',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.step_rotation,
          color: '#5EB167',
        },
        {
          name: '累積歩数/回転数',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.cumulative,
          color: '#21219D',
        },
        {
          name: '消費カロリー',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.cal,
          color: '#D36BB3',
        },
        {
          name: '距離',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.distance,
          color: '#9C22E8',
        },
      ],
    };
  }
  if (
    rehabilitationId === 11 ||
    rehabilitationId === 12 ||
    rehabilitationId === 13
  ) {
    return {
      legend: {
        data: [
          '脈拍',
          'ワット',
          'ペダル回転数',
          'ペダルトルク',
          '消費カロリー',
        ],
        //icon: 'circle',
        top: selectVisible.smartWatch === 2 || isLance !== '1' ? '95%' : '90%',
        left: 25,
        selected: { ...selected },
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          formatter: function (value) {
            if (isMeasureTime === 2) {
              const data = realtimeListDataList?.mederugoExerciseTime?.find(
                (item) => item?.[0] === value,
              );
              return data?.[1];
            } else {
              return value;
            }
          },
        },
        data: realtimeListDataList?.measureTime || [],
      },
      yAxis: {
        type: 'value',
        scale: true,
        position: 'left',
        max: axis?.maxAxisAero,
        min: axis?.lowAxisAero,
      },
      grid: [
        {
          top: 5,
          bottom: isLance === '1' ? 60 : 70,
          left: 50,
        },
      ],
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          barWidth: 2,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ml';
            },
          },
          data: markerArray,
        },
        {
          name: '脈拍',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.bewellPulse,
          color: '#F73131',
        },
        {
          name: 'ワット',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.watt,
          color: '#FDB01D',
        },
        {
          name: 'ペダルトルク',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.pedalTorque,
          color: '#1399CB',
        },
        {
          name: 'ペダル回転数',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.step_rotation,
          color: '#5EB167',
        },
        {
          name: '消費カロリー',
          type: 'line',
          showAllSymbol: true,
          data: realtimeListDataList?.cal,
          color: '#D36BB3',
        },
      ],
    };
  }
  return {};
};
