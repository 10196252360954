// import libraries
import React from 'react';
import { Button } from 'antd';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Routes } from '@app/routes/routes';
import moment from 'moment';

// create a component
const BasicInfoItem = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'NO.',
      key: '1',
      dataIndex: 'no',
    },
    {
      title: '施設名',
      key: '2',
      render: (record) => {
        return (
          <Button
            type='text'
            onClick={() =>
              navigate({
                pathname: Routes.Home.ContractorDetail.path,
                search: `?${createSearchParams({
                  contractId: record?.contractId,
                })}`,
              })
            }
          >
            {record?.contractFacilityName}
          </Button>
        );
      },
    },
    {
      title: '都道府県',
      key: '3',
      dataIndex: 'pref',
    },
    {
      title: '電話番号',
      key: '4',
      dataIndex: 'tel',
      sorter: (a, b) => a?.tel?.localeCompare(b?.tel, 'ja'),
    },
    {
      title: 'アカウント作成日時',
      key: '5',
      render: (record) => {
        return (
          <div>
            {record?.accountCreate
              ? moment(record?.accountCreate).format('YYYY/MM/DD')
              : ''}
          </div>
        );
      },
      sorter: (a, b) => moment(a?.accountCreate).diff(moment(b?.accountCreate)),
    },
    {
      title: '初回ログイン',
      key: '6',
      dataIndex: 'firstLoginAt',
      sorter: (a, b) =>
        moment(a?.firstLoginAt, 'YYYY/MM/DD').diff(
          moment(b?.firstLoginAt, 'YYYY/MM/DD'),
        ),
    },
    {
      title: '最終ログイン',
      key: '7',
      dataIndex: 'lastLoginAt',
      sorter: (a, b) =>
        moment(a?.lastLoginAt, 'YYYY/MM/DD').diff(
          moment(b?.lastLoginAt, 'YYYY/MM/DD'),
        ),
    },
    {
      title: '登録使用者',
      key: '8',
      dataIndex: 'patientCnt',
      sorter: (a, b) => a?.patientCnt - b?.patientCnt,
    },
    {
      title: '男性使用者',
      key: '9',
      dataIndex: 'patientCntMan',
      sorter: (a, b) => a?.patientCntMan - b?.patientCntMan,
    },
    {
      title: '女性使用者',
      key: '10',
      dataIndex: 'patientCntWoman',
      sorter: (a, b) => a?.patientCntWoman - b?.patientCntWoman,
    },
    {
      title: '脳血管疾患 ',
      key: '11',
      dataIndex: 'patientClassA',
      sorter: (a, b) => a?.patientClassA - b?.patientClassA,
    },
    {
      title: '運動器 ',
      key: '12',
      dataIndex: 'patientClassB',
      sorter: (a, b) => a?.patientClassB - b?.patientClassB,
    },
    {
      title: '廃用症候群',
      key: '13',
      dataIndex: 'patientClassC',
      sorter: (a, b) => a?.patientClassC - b?.patientClassC,
    },
    {
      title: '心大血管',
      key: '14',
      dataIndex: 'patientClassD',
      sorter: (a, b) => a?.patientClassD - b?.patientClassD,
    },
    {
      title: '呼吸器',
      key: '15',
      dataIndex: 'patientClassE',
      sorter: (a, b) => a?.patientClassE - b?.patientClassE,
    },
    {
      title: 'その他',
      key: '16',
      dataIndex: 'patientClassF',
      sorter: (a, b) => a?.patientClassF - b?.patientClassF,
    },
    {
      title: '類別未選択',
      key: '17',
      dataIndex: 'patientClassG',
      sorter: (a, b) => a?.patientClassG - b?.patientClassG,
    },
  ];
};

// make this component available to the app
export default BasicInfoItem;
