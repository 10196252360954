import React from 'react';
import { message, Modal } from 'antd';
export { Errors } from '@app/utils/errors';

export const showErrorDialogs = (error, onCancel = () => {}) => {
  try {
    const { message, isNeworkError } = error;
    Modal.destroyAll();
    Modal.error({
      title: isNeworkError ? 'ネットワークエラー' : 'エラー',
      className: 'show-graphql-error-dailogs-modal',
      okText: '閉じる',
      onOk: () => {
        onCancel();
      },
      content: (
        <span id='show-graphql-error-dailogs-modal'>
          {isNeworkError ? 'ネットワークの接続状況をご確認ください。' : message}
        </span>
      ),
    });
  } catch (error) {
    console.log(error);
    console.log('Failed to show error messages.');
  }
};

export const showError = (error) => {
  try {
    message.error(error);
  } catch (error) {
    console.log(error);
  }
};
