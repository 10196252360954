const errorTypes = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
};

const SystemErrors = {
  REFRESH_TOKEN_MISSING: {
    key: 'REFRESH_TOKEN_MISSING',
    type: errorTypes.ERROR,
    message: 'Refresh token missing.',
  },
  INTERNAL_SERVER_ERROR: {
    key: 'INTERNAL_SERVER_ERROR',
    type: errorTypes.ERROR,
    message: 'Internal Server Error',
  },
};

const generateErrorInstances = (errorMap) =>
  Object.keys(errorMap).reduce(
    (p, n) => ({ ...p, [n]: () => new Error(n.key) }),
    {},
  );

export const ErrorsInstances = {
  System: generateErrorInstances(SystemErrors),
};

export const Errors = {
  System: SystemErrors,
};
