import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Layout, Select, Menu } from 'antd';
import { Routes } from '@app/routes/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MoreOutlined,
} from '@ant-design/icons/lib/icons';
import { useAuthContext } from '@app/providers/authProvider';
import { logoutAsync } from '@app/services/auth/auth.service';

const { Content, Sider } = Layout;
const { Option } = Select;
const SubMenu = Menu.SubMenu;

export function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

export const HomeLayout = ({ children }) => {
  const { user, userFacility } = useAuthContext();
  const [collapsed, setCollapsed] = useState(false);
  const [mobileCollapsed, setMobileCollapsed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { width } = useWindowDimensions();

  const toggle = () => {
    if (width <= 960) {
      setMobileCollapsed(!mobileCollapsed);
    } else {
      setCollapsed(!collapsed);
    }
  };

  useEffect(() => {
    if (width <= 960) {
      setCollapsed(false);
      setMobileCollapsed(false);
    }
  }, [width]);

  const onLogout = async () => {
    try {
      await logoutAsync();
      navigate(Routes.Landing.Login.path);
      window.location.reload();
    } catch (error) {
      console.log('logoutAsync.err', error);
    }
  };
  const menu = (
    <Menu className='header-menu'>
      {location.pathname !== Routes.Home.PatientList.path ? (
        <SubMenu key='facilityList' title={userFacility?.facilityName}>
          {user?.facilityList?.map((item, index) => (
            <Menu.Item
              key={index}
              onClick={() => onChangeFacility(item?.facilityId)}
            >
              {item.facilityName || ''}
            </Menu.Item>
          ))}
        </SubMenu>
      ) : null}
      <Menu.Item key='name'>{user.userName || ''}</Menu.Item>
      <Menu.Item key='logout' onClick={onLogout}>
        ログアウト
      </Menu.Item>
    </Menu>
  );

  const onChangeFacility = (e) => {
    if (userFacility?.facilityId !== e) {
      const facility = user.facilityList.find((item) => item.facilityId === e);
      localStorage.setItem('defaultFacility', JSON.stringify(facility));
      window.location.reload();
    }
  };

  const defaultSelectedKeyChecker = () => {
    switch (location.pathname) {
      case Routes.Home.UserList.path:
        return Routes.Home.UserList.path
      case Routes.Home.UserDetail.path:
        return Routes.Home.UserList.path
      case Routes.Home.ThumbnailList.path:
        return Routes.Home.UserList.path
      case Routes.Home.MediaMemo.path:
        return Routes.Home.UserList.path
      case Routes.Home.MeasureDetail.path:
        return Routes.Home.UserList.path
      case Routes.Home.ContractorList.path:
        return Routes.Home.ContractorList.path
      case Routes.Home.PatientList.path:
        return Routes.Home.ContractorList.path
      case Routes.Home.ContractorDetail.path:
        return Routes.Home.ContractorList.path

      default:
        return Routes.Home.UserList.path
    }

  }

  return (
    <Layout className='home-container'>
      <header className='home-header'>
        <h1>
          {React.createElement(
            collapsed || mobileCollapsed
              ? MenuUnfoldOutlined
              : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: toggle,
            },
          )}
          WEBアナライズ
        </h1>
        <img
          className='logo'
          src='/assets/images/logos/ogdl_logo01.svg'
          alt='Logo'
          height={24}
        />
        <div className='header-buttons'>
          {location.pathname !== Routes.Home.PatientList.path ? (
            <Select
              size='small'
              defaultValue={userFacility?.facilityId}
              onChange={onChangeFacility}
              style={{ width: '120px' }}
            >
              {user?.facilityList?.map((item, index) => (
                <Option key={index} value={item?.facilityId}>
                  {item?.facilityName || ''}
                </Option>
              ))}
            </Select>
          ) : null}
          <Button size='large' type='text'>
            {user.userName || ''}
          </Button>
          <Button size='large' type='text' onClick={onLogout}>
            ログアウト
          </Button>
        </div>
        <div className='header-folded-buttons'>
          <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined className='folded-icon' />
          </Dropdown>
        </div>
      </header>
      <Layout
        className={`home-layout ${collapsed ? 'collapsed' : ''} ${mobileCollapsed ? 'mobilecollapsed' : ''
          }`}
      >
        <div
          className={`sider-overlay ${mobileCollapsed ? 'on' : ''}`}
          onClick={toggle}
        />
        <Sider theme='light' trigger={null} className='home-sider'>
          <Menu
            theme='light'
            mode='inline'
            defaultSelectedKeys={Routes.Home.UserList.path}
            selectedKeys={[defaultSelectedKeyChecker()]}
            className='menu'
          >
            <Menu.Item
              key={Routes.Home.UserList.path}
              onClick={() =>
                navigate(Routes.Home.UserList.path, {
                  replace: true,
                })
              }
            >
              使用者一覧画面
            </Menu.Item>
            {user?.ogAdminFlg === '1' ? (
              <Menu.Item
                key={Routes.Home.ContractorList.path}
                onClick={() =>
                  navigate(Routes.Home.ContractorList.path, {
                    replace: true,
                  })
                }
              >
                契約者一覧画面
              </Menu.Item>
            ) : null}
          </Menu>
        </Sider>
        <Content className='home-content'>{children}</Content>
      </Layout>
    </Layout>
  );
};
