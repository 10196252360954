import {
  LoginPage,
  UserListPage,
  UserDetailPage,
  MeasureDetailPage,
  ThumbnailListPage,
  MediaMemoPage,
  ContractorListPage,
  ContractorDetailPage,
  PatientListPage,
} from 'pages';
import { LAYOUT_TYPES } from '@app/layout/types/layout.types';

const getRoutes = (n) =>
  Object.keys(n).reduce((p, route) => [...p, n[route]], []);

const HomeRoutes = {
  UserList: {
    path: '/users',
    exact: true,
    component: UserListPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.HOME_LAYOUT,
  },
  UserDetail: {
    path: '/users/detail',
    exact: true,
    component: UserDetailPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.HOME_LAYOUT,
  },
  MeasureDetail: {
    path: '/users/detail/measuredetail',
    exact: true,
    component: MeasureDetailPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.HOME_LAYOUT,
  },
  ContractorList: {
    path: '/contractor',
    exact: true,
    component: ContractorListPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.HOME_LAYOUT,
  },
  ThumbnailList: {
    path: '/users/detail/thumbnail',
    exact: true,
    component: ThumbnailListPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.DEFAULT_LAYOUT,
  },
  MediaMemo: {
    path: '/users/detail/mediamemo',
    exact: true,
    component: MediaMemoPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.DEFAULT_LAYOUT,
  },
  ContractorDetail: {
    path: '/contractor/detail',
    exact: true,
    component: ContractorDetailPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.HOME_LAYOUT,
  },
  PatientList: {
    path: '/contractor/patientListPage',
    exact: true,
    component: PatientListPage,
    requireAuth: true,
    // requireRole: ['ADMIN', 'EDITOR'],
    requireRole: false,
    layout: LAYOUT_TYPES.HOME_LAYOUT,
  },
};

const LandingRoutes = {
  Login: {
    path: '/login',
    exact: true,
    component: LoginPage,
    requireAuth: false,
    requireRole: false,
    // fallback: '/login',
    layout: LAYOUT_TYPES.DEFAULT_LAYOUT,
  },
  Home: {
    path: '/',
    exact: true,
    component: LoginPage,
    requireAuth: false,
    requireRole: false,
    fallback: '/login',
    layout: LAYOUT_TYPES.DEFAULT_LAYOUT,
  },
};

export const Routes = {
  Home: HomeRoutes,
  Landing: LandingRoutes,
};

export const isLanding = (route) => {
  const landingPages = getRoutes(LandingRoutes);
  return landingPages.find((r) => r.path === route);
};
export const isHome = (route) => {
  const homePages = getRoutes(HomeRoutes);
  return homePages.find((r) => r.path === route);
};

export const RoutesArray = [HomeRoutes, LandingRoutes].reduce(
  (p, n) => [...p, ...getRoutes(n)],
  [],
);

export const getRoute = (route) => RoutesArray.find((r) => r.path === route);
