import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Button, Col, Row } from 'antd';
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';

const ChartTooltip = forwardRef(
  (
    {
      setGraphTooltipData,
      graphTooltipData,
      dataList,
      selectVisible,
      state,
      isLance,
      _vitalStackObject = [],
      _aerobicStackObject = [],
      measureDetail = false,
      overlayChart = false,
      _overlayStackObject = [],
      viewDate = [],
      graphWidth = 0,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      clearMarker: () => {
        setMarker({
          ...marker,
          markerDateFirst: '',
          markerDateSecond: '',
          countStep: 0,
          pointPositionFirst: [],
        });
      },
      onClick: () => {
        if (markerDateSecond) {
          setGraphTooltipData({
            lineDate: markerDateSecond,
            tooltipPosition: [
              pointPositionSecond?.[0] + 1,
              pointPositionSecond?.[1] + 1,
            ],
          });
          setMarker({
            ...marker,
            markerDateFirst: markerDateSecond,
            markerDateSecond: '',
            countStep: 1,
            pointPositionFirst: pointPositionSecond,
            pointPositionSecond: [],
          });
        } else {
          setMarker({
            ...marker,
            markerDateFirst: '',
            countStep: 0,
            pointPositionFirst: [],
          });
          setGraphTooltipData({ tooltipPosition: [], lineDate: '' });
        }
      },
      onClick2: () => {
        setGraphTooltipData({
          lineDate: markerDateFirst,
          tooltipPosition: pointPositionFirst,
        });
        setMarker({
          ...marker,
          markerDateSecond: '',
          pointPositionSecond: [],
          countStep: 1,
        });
      },
    }));

    const [marker, setMarker] = useState({
      markerDateFirst: '',
      markerDateSecond: '',
      pointPositionFirst: [],
      pointPositionSecond: [],
      countStep: 0,
      minimized: false,
    });
    const {
      markerDateFirst,
      markerDateSecond,
      pointPositionFirst,
      pointPositionSecond,
      countStep,
      minimized,
    } = marker;
    const { lineDate, tooltipPosition } = graphTooltipData;
    useEffect(() => {
      if (!viewDate?.[0] && viewDate?.[1] && !viewDate?.[2]) {
        setMarker({
          ...marker,
          markerDateFirst: viewDate?.[1],
          markerDateSecond: '',
          countStep: 1,
          pointPositionFirst: tooltipPosition,
          pointPositionSecond: [],
        });
      } else if (!viewDate?.[0] && !viewDate?.[1]) {
        setMarker({
          ...marker,
          markerDateFirst: '',
          countStep: 0,
          pointPositionFirst: [],
        });
        setGraphTooltipData({ tooltipPosition: [], lineDate: '' });
      }
    }, [viewDate?.[0], viewDate?.[1]]);
    const axisData = dataList.filter(
      (e) =>
        markerDateFirst === e?.measureDate ||
        markerDateSecond === e?.measureDate ||
        markerDateFirst === e?.measureTime ||
        markerDateSecond === e?.measureTime,
    );

    // const parseTime = (_data) => {
    //   return moment(_data).format('HH:mm:ss');
    // };

    const parseExercise = (_data) => {
      if (!_data) return 0;
      let exerciseTimes;
      const hours = _data.split(':');

      if (hours?.length === 2) {
        const stringTime = hours[0] + '.' + hours[1];
        exerciseTimes = parseFloat(stringTime);
      }
      return exerciseTimes;
    };

    const vitalStackObject = axisData.reduce((p, e) => {
      return [
        ...p,
        _vitalStackObject.reduce((pp, item) => {
          if (item?.isView)
            pp.push({
              seriesName: item.seriesName,
              data:
                item?.data === 'measureTime' ? e[item?.data] : e[item?.data],
            });
          return pp;
        }, []),
      ];
    }, []);

    const aerobicStackObject = axisData.reduce((p, e) => {
      return [
        ...p,
        _aerobicStackObject.reduce((pp, item) => {
          if (item?.isView) {
            if (item?.data === 'exerciseTime')
              pp.push({
                seriesName: item.seriesName,
                data: parseExercise(e[item?.data]) || 0,
              });
            else
              pp.push({
                seriesName: item.seriesName,
                data:
                  item?.data === 'measureTime' ? e[item?.data] : e[item?.data],
              });
          }
          return pp;
        }, []),
      ];
    }, []);

    let mergeStackObject = axisData.reduce((p, e) => {
      return [
        ...p,
        _overlayStackObject.reduce((pp, item) => {
          if (item?.isView) {
            if (item?.data === 'exerciseTime')
              pp.push({
                seriesName: item.seriesName,
                data: parseExercise(e[item?.data]) || 0,
              });
            else
              pp.push({
                seriesName: item.seriesName,
                data:
                  item?.data === 'measureTime' ? e[item?.data] : e[item?.data],
              });
          }
          return pp;
        }, []),
      ];
    }, []);

    if (lineDate && countStep === 0) {
      setMarker({
        ...marker,
        // pointPositionFirst: tooltipPosition,
        markerDateFirst: lineDate,
        countStep: countStep + 1,
      });
    }
    if (
      countStep === 1 &&
      lineDate !== markerDateFirst &&
      lineDate !== markerDateSecond
      // tooltipPosition?.[0] !== pointPositionFirst[0] &&
      // tooltipPosition?.[0] !== pointPositionSecond[0]
    ) {
      setMarker({
        ...marker,
        markerDateSecond: lineDate,
        //pointPositionSecond: tooltipPosition,
        countStep: countStep + 1,
      });
    }
    if (
      countStep === 2 &&
      lineDate !== markerDateSecond &&
      lineDate !== markerDateFirst
      // tooltipPosition?.[0] !== pointPositionSecond[0] &&
      // tooltipPosition?.[0] !== pointPositionFirst[0]
    ) {
      setMarker({
        ...marker,
        // pointPositionFirst: tooltipPosition,
        markerDateFirst: lineDate,
        countStep: 1,
      });
    }

    // const onClick = () => {
    //   console.log('onclick');
    //   if (markerDateSecond) {
    //     setGraphTooltipData({
    //       lineDate: markerDateSecond,
    //       tooltipPosition: [
    //         pointPositionSecond?.[0] + 1,
    //         pointPositionSecond?.[1] + 1,
    //       ],
    //     });
    //     setMarker({
    //       ...marker,
    //       markerDateFirst: markerDateSecond,
    //       markerDateSecond: '',
    //       countStep: 1,
    //       pointPositionFirst: pointPositionSecond,
    //       pointPositionSecond: [],
    //     });
    //   } else {
    //     setMarker({
    //       ...marker,
    //       markerDateFirst: '',
    //       countStep: 0,
    //       pointPositionFirst: [],
    //     });
    //     setGraphTooltipData({ tooltipPosition: [], lineDate: '' });
    //   }
    // };

    // const onClick2 = () => {
    //   console.log('onclick2');
    //   setGraphTooltipData({
    //     lineDate: markerDateFirst,
    //     tooltipPosition: pointPositionFirst,
    //   });
    //   setMarker({
    //     ...marker,
    //     markerDateSecond: '',
    //     pointPositionSecond: [],
    //     countStep: 1,
    //   });
    // };
    // const isSecond = moment(markerDateFirst).diff(moment(markerDateSecond)) > 0;

    const secToTime = (from, to) => {
      let fromSec = 0;
      let toSec = 0;
      try {
        fromSec =
          parseInt(from?.split(':')[0]) * 3600 +
          parseInt(from?.split(':')[1]) * 60 +
          parseInt(from?.split(':')[2]);
        toSec =
          parseInt(to?.split(':')[0]) * 3600 +
          parseInt(to?.split(':')[1]) * 60 +
          parseInt(to?.split(':')[2]);
      } catch {
        return '-';
      }
      let sec = toSec - fromSec;
      let retHour = String((sec / 3600) | 0);
      let retMin = String(((sec - 3600 * retHour) / 60) | 0);
      let retSec = String(sec - 3600 * retHour - 60 * retMin);
      if (retHour.length < 2) retHour = '0' + retHour;
      if (retMin.length < 2) retMin = '0' + retMin;
      if (retSec.length < 2) retSec = '0' + retSec;
      return (retHour === '00' ? '' : retHour + ':') + retMin + ':' + retSec;
    };

    const sec2ToTime = (from, to) => {
      let fromSec = 0;
      let toSec = 0;
      try {
        fromSec =
          parseInt(from?.split(':')[0]) * 60 + parseInt(from?.split(':')[1]);
        toSec = parseInt(to?.split(':')[0]) * 60 + parseInt(to?.split(':')[1]);
      } catch {
        return '-';
      }
      let sec = toSec - fromSec;
      if (sec >= 0) {
        let retMin = String((sec / 60) | 0);
        let retSec = String(sec - 60 * retMin);
        if (retMin.length < 2) retMin = '0' + retMin;
        if (retSec.length < 2) retSec = '0' + retSec;
        return retMin + ':' + retSec;
      } else {
        const _sec = sec * -1;
        let retMin = String((_sec / 60) | 0);
        let retSec = String(_sec - 60 * retMin);
        if (_sec - 60 * retMin) {
        }
        if (retMin.length < 2) retMin = '0' + retMin;
        if (retSec.length < 2) retSec = '0' + retSec;
        return '-' + retMin + ':' + retSec;
      }
    };
    return (
      <>
        {selectVisible.smartWatch === 1 &&
          isLance === '1' &&
          state.selectGraph === 1 &&
          !overlayChart &&
          markerDateFirst && (
            <div>
              <div
                className='line-graph-tooltip'
                style={{
                  // transform: `translate3d(${
                  //   pointPositionFirst[0] > (pointPositionSecond[0] || 0)
                  //     ? pointPositionFirst[0]
                  //     : pointPositionSecond[0]
                  // }px,
                  transform: `translate3d(${graphWidth * 0.91}px,  
              ${
                minimized
                  ? selectVisible.beWell === 2
                    ? '35px'
                    : '35px'
                  : selectVisible.beWell === 2
                  ? '125px'
                  : '35px'
              }, 0px)`,
                }}
              >
                {!minimized && (
                  <Row className='tooltip-table'>
                    <Col>
                      <div className='head'>指標</div>
                      {vitalStackObject[0]?.map((e, idx) =>
                        e?.seriesName !== '計測時刻' &&
                        e?.seriesName !== '計測日' ? (
                          <div key={idx} className='col'>
                            {e?.seriesName}
                          </div>
                        ) : (
                          <></>
                        ),
                      )}
                    </Col>
                    {/* Lansband first table data */}
                    <Col>
                      <div className='head'>{vitalStackObject[0][0].data}</div>
                      {vitalStackObject[0]?.map((e, idx) =>
                        e?.seriesName !== '計測時刻' &&
                        e?.seriesName !== '計測日' ? (
                          <div key={idx} className='col'>
                            {e?.data}
                          </div>
                        ) : (
                          <></>
                        ),
                      )}
                    </Col>
                    {/* Lansband second table data */}
                    {markerDateSecond && markerDateFirst && (
                      <>
                        <Col>
                          <div className='head'>
                            {/* {measureDetail
                              ? isSecond
                                ? markerDateFirst
                                : markerDateSecond
                              : isSecond
                              ? markerDateFirst
                              : markerDateSecond} */}
                            {vitalStackObject[1][0].data}
                          </div>
                          {vitalStackObject[1]?.map((e, idx) =>
                            e?.seriesName !== '計測時刻' &&
                            e?.seriesName !== '計測日' ? (
                              <div key={idx} className='col'>
                                {e?.data}
                              </div>
                            ) : (
                              <></>
                            ),
                          )}
                        </Col>
                        {/* Lansband diff table data */}
                        <Col>
                          <div className='head'>
                            {measureDetail
                              ? '差 ' +
                                secToTime(
                                  vitalStackObject[0][0].data,
                                  vitalStackObject[1][0].data,
                                )
                              : '差分'}
                          </div>
                          {vitalStackObject[0]?.map((e, idx) =>
                            e?.seriesName !== '計測時刻' &&
                            e?.seriesName !== '計測日' ? (
                              <div key={idx} className='col'>
                                {!isNaN(
                                  parseFloat(
                                    (
                                      e?.data - vitalStackObject[1][idx]?.data
                                    ).toFixed(2),
                                  ),
                                )
                                  ? parseFloat(
                                      (
                                        e?.data - vitalStackObject[1][idx]?.data
                                      ).toFixed(2),
                                    )
                                  : isNaN(
                                      secToTime(
                                        e?.data,
                                        vitalStackObject[1][idx]?.data,
                                      ),
                                    )
                                  ? ''
                                  : secToTime(
                                      e?.data,
                                      vitalStackObject[1][idx]?.data,
                                    )}
                              </div>
                            ) : (
                              <></>
                            ),
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                )}
                <Button
                  size='small'
                  className='collapse-button'
                  onClick={() =>
                    setMarker({ ...marker, minimized: !minimized })
                  }
                  icon={minimized ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
                />
              </div>
              {/* {pointPositionFirst.length > 0 && markerDateFirst && (
                <div
                  className='tooltip-line'
                  style={{
                    transform: `translate3d(${pointPositionFirst[0]}px, 35px, 0px)`,
                    height: overlayChart
                      ? 340
                      : selectVisible.beWell === 2
                      ? 340
                      : 141,
                  }}
                  onClick={onClick}
                />
              )}
              {pointPositionSecond.length > 0 && markerDateSecond && (
                <div
                  className='tooltip-line'
                  style={{
                    transform: `translate3d(${pointPositionSecond[0]}px, 35px, 0px)`,
                    height: overlayChart
                      ? 340
                      : selectVisible.beWell === 2
                      ? 340
                      : 141,
                  }}
                  onClick={onClick2}
                />
              )} */}
            </div>
          )}
        {selectVisible.beWell === 1 &&
          state.selectGraph === 1 &&
          !overlayChart &&
          aerobicStackObject[0] && (
            <div>
              <div
                className='line-graph-tooltip'
                style={{
                  // transform: `translate3d(${
                  //   pointPositionFirst[0] > (pointPositionSecond[0] || 0)
                  //     ? pointPositionFirst[0]
                  //     : pointPositionSecond[0]
                  // }px,
                  transform: `translate3d(${graphWidth * 0.91}px, ${
                    selectVisible.smartWatch === 2 || isLance !== '1'
                      ? '200px'
                      : '290px'
                  }, 0px)`,
                }}
              >
                {!minimized && (
                  <Row className='tooltip-table'>
                    {/* Label */}
                    <Col>
                      <div className='head'>指標</div>
                      {aerobicStackObject[0].map((e, idx) =>
                        e?.seriesName !== '計測時刻' &&
                        e?.seriesName !== '計測日' ? (
                          <div key={idx} className='col'>
                            {e.seriesName}
                          </div>
                        ) : (
                          <></>
                        ),
                      )}
                    </Col>
                    {/* Be-well First table value */}
                    <Col>
                      <div className='head'>
                        {/* {measureDetail
                          ? isSecond
                            ? markerDateSecond
                            : markerDateFirst
                          : isSecond
                          ? markerDateSecond
                          : markerDateFirst} */}
                        {aerobicStackObject[0][0].data}
                      </div>
                      {aerobicStackObject[0].map((e, idx) =>
                        e?.seriesName !== '計測時刻' &&
                        e?.seriesName !== '計測日' ? (
                          <div key={idx} className='col'>
                            {e.data}
                          </div>
                        ) : (
                          <></>
                        ),
                      )}
                    </Col>
                    {/* Be-well Second table value */}
                    {markerDateSecond && markerDateFirst && (
                      <>
                        <Col>
                          <div className='head'>
                            {aerobicStackObject[1][0].data}
                          </div>
                          {aerobicStackObject[1]?.map((e, idx) =>
                            e?.seriesName !== '計測時刻' &&
                            e?.seriesName !== '計測日' ? (
                              <div key={idx} className='col'>
                                {e.data}
                              </div>
                            ) : (
                              <></>
                            ),
                          )}
                        </Col>
                        {/* Be-well diff table value */}
                        <Col>
                          <div className='head'>
                            {measureDetail
                              ? '差 ' +
                                secToTime(
                                  aerobicStackObject[0][0].data,
                                  aerobicStackObject[1][0].data,
                                )
                              : '差分'}
                          </div>
                          {aerobicStackObject[0]?.map((e, idx) =>
                            e?.seriesName !== '計測時刻' &&
                            e?.seriesName !== '計測日' ? (
                              <div key={idx} className='col'>
                                {e?.data && aerobicStackObject[1][idx]?.data
                                  ? !isNaN(
                                      parseFloat(
                                        (
                                          e?.data -
                                          aerobicStackObject[1][idx]?.data
                                        ).toFixed(2),
                                      ),
                                    )
                                    ? parseFloat(
                                        (
                                          e?.data -
                                          aerobicStackObject[1][idx]?.data
                                        ).toFixed(2),
                                      )
                                    : secToTime(
                                        e?.data,
                                        aerobicStackObject[1][idx]?.data,
                                      )
                                  : ''}
                              </div>
                            ) : (
                              <></>
                            ),
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                )}
                <Button
                  size='small'
                  className='collapse-button'
                  onClick={() =>
                    setMarker({ ...marker, minimized: !minimized })
                  }
                  icon={minimized ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
                />
              </div>
            </div>
          )}
        {overlayChart && state.selectGraph === 1 && markerDateFirst && (
          <div>
            <div
              className='line-graph-tooltip'
              style={{
                transform: `translate3d(${graphWidth * 0.91}px, 
                ${
                  minimized
                    ? selectVisible.beWell === 2
                      ? '35px'
                      : '130px'
                    : selectVisible.beWell === 2
                    ? '25px'
                    : '130px'
                }, 0px)`,
              }}
            >
              {!minimized && (
                <Row className='tooltip-table'>
                  <Col>
                    <div className='head'>指標</div>
                    {mergeStackObject[0]?.map((e, idx) =>
                      e?.seriesName !== '計測時刻' &&
                      e?.seriesName !== '計測日' ? (
                        <div key={idx} className='col'>
                          {e?.seriesName}
                        </div>
                      ) : (
                        <></>
                      ),
                    )}
                  </Col>
                  {/* Lansband first table data */}
                  <Col>
                    <div className='head'>{mergeStackObject[0][0].data}</div>
                    {mergeStackObject[0]?.map((e, idx) =>
                      e?.seriesName !== '計測時刻' &&
                      e?.seriesName !== '計測日' ? (
                        <div key={idx} className='col'>
                          {e?.data}
                        </div>
                      ) : (
                        <></>
                      ),
                    )}
                  </Col>
                  {/* Lanceband second table data */}
                  {markerDateSecond && markerDateFirst && (
                    <>
                      <Col>
                        <div className='head'>
                          {mergeStackObject[1][0].data}
                        </div>
                        {mergeStackObject[1]?.map((e, idx) =>
                          e?.seriesName !== '計測時刻' &&
                          e?.seriesName !== '計測日' ? (
                            <div key={idx} className='col'>
                              {e?.data}
                            </div>
                          ) : (
                            <></>
                          ),
                        )}
                      </Col>
                      {/* Lansband diff table data */}
                      <Col>
                        <div className='head'>
                          {measureDetail
                            ? '差 ' +
                              secToTime(
                                mergeStackObject[0][0].data,
                                mergeStackObject[1][0].data,
                              )
                            : '差分'}
                        </div>
                        {mergeStackObject[0]?.map((e, idx) =>
                          e?.seriesName !== '計測時刻' &&
                          e?.seriesName !== '計測日' ? (
                            <div key={idx} className='col'>
                              {e?.data && mergeStackObject[1][idx]?.data
                                ? !isNaN(
                                    e?.data - mergeStackObject[1][idx]?.data,
                                  )
                                  ? parseFloat(
                                      (
                                        e?.data - mergeStackObject[1][idx]?.data
                                      ).toFixed(2),
                                    )
                                  : isNaN(
                                      secToTime(
                                        e?.data,
                                        mergeStackObject[1][idx]?.data,
                                      ),
                                    )
                                  ? ''
                                  : secToTime(
                                      e?.data,
                                      mergeStackObject[1][idx]?.data,
                                    )
                                : ''}
                            </div>
                          ) : (
                            <></>
                          ),
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              )}
              <Button
                size='small'
                className='collapse-button'
                onClick={() => setMarker({ ...marker, minimized: !minimized })}
                icon={minimized ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
              />
            </div>
          </div>
        )}
      </>
    );
  },
);

export default ChartTooltip;
