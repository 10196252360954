/* eslint-disable no-unused-vars */
import axios from '@app/config/axios';
import { showErrorDialogs } from '@app/utils/errorHandler';

export const getClassList = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .get('/classlist', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'failed') {
          if (data?.code === 'A001-001') {
            reject('A001-001');
          } else {
            resolve(data);
          }
        }
        if (data?.success === 'success') {
          resolve(data);
        }
      })
      .catch(function (error) {
        showErrorDialogs({ isNetwork: true });
        reject(error);
      });
  });
};
