import moment from 'moment';

// create a component
const BasicInfoDetailItem = () => {
  return [
    {
      title: 'アカウント作成日時',
      key: '1',
      render: (record) => {
        return (
          <div>
            {record?.accountCreate
              ? moment(record?.accountCreate).format('YYYY/MM/DD')
              : ''}
          </div>
        );
      },
    },
    {
      title: '初回ログイン',
      key: '2',
      dataIndex: 'firstLoginAt',
    },
    {
      title: '最終ログイン',
      key: '3',
      dataIndex: 'lastLoginAt',
    },
    {
      title: '登録使用者',
      key: '4',
      dataIndex: 'patientCnt',
    },
    {
      title: '男性使用者',
      key: '5',
      dataIndex: 'patientCntMan',
    },
    {
      title: '女性使用者',
      key: '6',
      dataIndex: 'patientCntWoman',
    },
    {
      title: '脳血管疾患 ',
      key: '7',
      dataIndex: 'patientClassA',
    },
    {
      title: '運動器 ',
      key: '8',
      dataIndex: 'patientClassB',
    },
    {
      title: '廃用症候群',
      key: '9',
      dataIndex: 'patientClassC',
    },
    {
      title: '心大血管',
      key: '10',
      dataIndex: 'patientClassD',
    },
    {
      title: '呼吸器',
      key: '11',
      dataIndex: 'patientClassE',
    },
    {
      title: 'その他',
      key: '12',
      dataIndex: 'patientClassF',
    },
    {
      title: '類別未選択',
      key: '13',
      dataIndex: 'patientClassG',
    },
  ];
};

// make this component available to the app
export default BasicInfoDetailItem;
