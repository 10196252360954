/* eslint-disable no-unused-vars */
import axios from '@app/config/axios';
import { showErrorDialogs } from '@app/utils/errorHandler';
import moment from 'moment';

export const getPatientDailySummaryList = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/patient/dailysummary', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'failed') {
          if (data?.code === 'A001-001') {
            reject('A001-001');
          } else {
            resolve(data);
          }
        }
        if (data?.success === 'success') {
          resolve(data);
        }
      })
      .catch(function (error) {
        showErrorDialogs({ isNeworkError: true });
        reject(error);
      });
  });
};

export const getPatientReceptInfoList = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/patient/receptinfo', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'failed') {
          if (data?.code === 'A001-001') {
            reject('A001-001');
          } else {
            resolve(data);
          }
        }
        if (data?.success === 'success') {
          resolve(data);
        }
      })
      .catch(function (error) {
        showErrorDialogs({ isNeworkError: true });
        reject(error);
      });
  });
};

export const delRecept = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/recept/delete', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'failed') {
          if (data?.code === 'A001-001') {
            reject('A001-001');
          } else {
            resolve(data);
          }
        }
        if (data?.success === 'success') {
          resolve(data);
        }
      })
      .catch(function (error) {
        showErrorDialogs({ isNeworkError: true });
        reject(error);
      });
  });
};

export const getMediaMemoList = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/recept/mediamemolist', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'failed') {
          if (data?.code === 'A001-001') {
            reject('A001-001');
          } else {
            resolve(data);
          }
        }
        if (data?.success === 'success') {
          resolve(data);
        }
      })
      .catch(function (error) {
        showErrorDialogs({ isNeworkError: true });
        reject(error);
      });
  });
};

export const delMediaMemo = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/mediamemo/delete', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'failed') {
          if (data?.code === 'A001-001') {
            reject('A001-001');
          } else {
            resolve(data);
          }
        }
        if (data?.success === 'success') {
          resolve(data);
        }
      })
      .catch(function (error) {
        showErrorDialogs({ isNeworkError: true });
        reject(error);
      });
  });
};

export const pdfOdlReport = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/pdf/odl-report', JSON.stringify(values), {
        withCredentials: true,
        responseType: 'arraybuffer',
      })
      .then(function ({ data }) {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: 'application/pdf' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${values?.patientId} - ${moment().format(
            'YYYY-MM-DD-hh-mm-ss',
          )}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        resolve(data);
      })
      .catch(function (error) {
        showErrorDialogs({ isNeworkError: true });
        reject(error);
      });
  });
};

export const pdfProduct = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/pdf/product', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'failed') {
          if (data?.code === 'A001-001') {
            reject('A001-001');
          } else {
            resolve(data);
          }
        }
        if (data?.success === 'success') {
          resolve(data);
        }
      })
      .catch(function (error) {
        showErrorDialogs({ isNeworkError: true });
        reject(error);
      });
  });
};
