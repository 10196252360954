// import libraries
import React, { useState, useEffect } from 'react';
import { Button, Tabs, Spin, Modal, Popover } from 'antd';
import { useAuthContext } from '@app/providers/authProvider';
import { useLocation } from 'react-router-dom';
import { useStableNavigate } from '@app/providers/stableNavigate';
import queryString from 'query-string';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// import utils
import { Table, Title } from '@app/components';
import UserListItem from '../userlist/components/userlist.item';
import ReceptionItem from './components/reception.item';
import {
  getPatientReceptInfoList,
  getPatientDailySummaryList,
  delRecept,
} from '@app/services/user/userDetail.service';
import SummaryItem from './components/summary.item';
import { getPatientList } from '@app/services/user/user.service';
import Chart from './components/chart';
import { downloadCsvFromAPI } from '@app/utils/commonFunctions';
import { useMonitorStore } from '@app/utils/contextManager';
import { Routes } from '@app/routes/routes';
import MediaMemoModal from '@app/components/mediaMemoModal';
import ThumbnailListModal from '@app/components/thumbnailListModal';
import ReportDownloadModal from '@app/components/reportDownloadModal';

const { TabPane } = Tabs;
// create a component
const UserDetailScene = () => {
  const { user, userFacility } = useAuthContext();
  const {
    state: { graphWidth = 0 },
  } = useMonitorStore();
  const navigate = useStableNavigate();
  const [viewDate, setViewDate] = useState('');
  const [onClickTable, seTonClickTable] = useState({
    lineDate: '',
    tooltipPosition: [],
  });
  const [state, setState] = useState({
    patient: [],
    patientDailySummaryDataList: [],
    patientReceptInfoList: [],
    loading: false,
    selectWe: 8,
    selectGraph: 1,
    selectVisible: { smartWatch: 1, beWell: 1 },
    measureDetail: false,
  });
  const {
    patient,
    patientDailySummaryDataList,
    loading,
    patientReceptInfoList,
    selectWe,
    selectVisible,
    measureDetail,
  } = state;
  const { search } = useLocation();
  const [selRecept, setSelRecept] = useState([]);
  const [selTab, setSelTab] = useState('1');
  const [checkedList, setCheckedList] = useState({});
  const [thumbnailModal, setThumbnailModal] = useState({
    visibleReceptId: 0,
    //配列にはmediaMemoId,thumbnailUrl,mediaMemoUrlの3つのプロパティを持つオブジェクト
    mediaList: [],
    date: '',
  });
  const [mediaModal, setMediaModal] = useState({
    //配列にはmediaMemoId,mediaMemoUrlの2つのプロパティを持つオブジェクト
    visibleMediaList: [],
    date: '',
  });
  const [mediaModalZIndex, setMediaModalZIndex] = useState(999);
  const [reportDownloadModal, setReportWownloadModal] = useState(false);

  const setThumbnailModalData = (receptId, mediaList = [], date, pageY) => {
    setThumbnailModal({
      ...thumbnailModal,
      visibleReceptId: receptId,
      mediaList: mediaList,
      date: date,
      pageY: pageY,
    });
  };

  const setmediaModalData = (mediaList = [], date) => {
    setMediaModal({
      ...mediaModal,
      visibleMediaList: mediaList,
    });
  };

  // get carteNo that selected value of carteNo from userlist page
  const params = queryString.parse(search);

  // If carteNo has value then execute _fetchData function
  useEffect(() => {
    if (params.carteNo) {
      _fetchData();
    }
  }, [selectWe]);

  const onCheckRecept = (receptId, checked) => {
    setCheckedList({
      ...checkedList,
      [receptId]: checked,
    });
    if (checked) {
      if (receptId) setSelRecept([...selRecept, receptId]);
    } else {
      if (receptId) {
        setSelRecept(
          selRecept.filter((val) => {
            if (val === receptId) {
              return false;
            }
            return true;
          }),
        );
      }
    }
    return;
  };

  const _fetchData = async () => {
    try {
      // Result of Searched values to get API data
      setState({ ...state, loading: true });
      const _res = await getPatientList({
        facilityId: userFacility?.facilityId || null,
        carteNo: params?.carteNo || null,
      });
      // Passing props data as patient
      if (_res?.data?.patientResponseList?.[0]) {
        getPatientDailySummaryListData(_res.data.patientResponseList[0]);
      } else {
        setState({ ...state, loading: false });
      }
    } catch (error) {
      console.log('userDetail', error);
      setState({ ...state, loading: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  // After executed patient list table data use patient data
  const getPatientDailySummaryListData = async (_patient) => {
    try {
      // Result of Searched values to get DailySummary's API data
      const _res = await getPatientDailySummaryList({
        facilityId: userFacility?.facilityId || null,
        patientId: _patient?.patientId || null,
        vitalRId: 1 || null,
        aerobicRId: selectWe,
        receptDateFrom:
          moment(_patient?.firstDate, 'YYYY/MM/DD').format('YYYY-MM-DD') || '',
        receptDateTo:
          moment(_patient?.visitDate, 'YYYY/MM/DD').format('YYYY-MM-DD') || '',
      });
      const _dailySummaryList = (_res?.data?.dailySummaryList || []).map(
        (data, index) => {
          return { ...data, key: index };
        },
      );
      const _receptRes = await getPatientReceptInfoList({
        facilityId: userFacility?.facilityId || null,
        patientId: _patient?.patientId || null,
      });

      const patientReceptInfoListData = (
        _receptRes?.data?.receptList || []
      ).map((data, index) => {
        return { ...data, no: index + 1, key: index };
      });
      setState({
        ...state,
        patient: [
          {
            ..._patient,
            key: _patient?.patientId,
            no: '1',
          },
        ],
        patientDailySummaryDataList: _dailySummaryList,
        patientReceptInfoList: patientReceptInfoListData,
        loading: false,
      });
    } catch (error) {
      setState({ ...state, loading: false });
      console.log(error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };
  const onDownloadReceptCsv = async () => {
    try {
      await downloadCsvFromAPI(
        '/csv/recept/list',
        userFacility,
        '受付リストCSV',
        { patientId: patient[0]?.patientId },
      );
    } catch (error) {
      console.log(error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };
  const onDownloadSummaryCsv = async () => {
    try {
      await downloadCsvFromAPI(
        '/csv/summary/list',
        userFacility,
        'サマリーリストCSV',
        { patientId: patient[0]?.patientId },
      );
    } catch (error) {
      console.log(error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  const onDelRecept = async () => {
    console.log('OK');
    //削除APIのコール
    try {
      setState({ ...state, loading: true });
      const _res = await delRecept({
        receptIds: selRecept || [],
      });
      console.log(_res);
      setState({ ...state, loading: false });
      setCheckedList({});
      setSelRecept([]);
    } catch (error) {
      setState({ ...state, loading: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
    //画面再ロード
    _fetchData();
  };

  const confirm = () => {
    Modal.confirm({
      title: '受付データの削除',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <br />
          <p>選択された{selRecept.length}件の受付データを削除します。</p>
          <p>よろしいですか？</p>
        </>
      ),
      okText: '削除',
      cancelText: 'キャンセル',
      onOk: onDelRecept,
    });
  };

  const _list =
    user.lanceBandRestriction !== '1'
      ? patientDailySummaryDataList?.filter((e) => e.isAerobicPlot)
      : patientDailySummaryDataList;

  return (
    <Spin spinning={loading}>
      <div className='user-detail'>
        <Title title='使用者詳細画面' setState={setState} state={state}>
          <Button
            // disabled={!patient?.length}
            type='text'
            onClick={() => setReportWownloadModal(true)}
          >
            レポート出力
          </Button>
          <Button
            disabled={!patient?.length}
            type='text'
            onClick={onDownloadReceptCsv}
          >
            使用者別受付一覧CSVダウンロード
          </Button>
          <Button
            disabled={!patient?.length}
            type='text'
            onClick={onDownloadSummaryCsv}
          >
            サマリーデータCSVダウンロード
          </Button>
        </Title>
        <Title
          title='使用者情報'
          list
          noButtons
          setState={setState}
          state={state}
        >
          <Table
            rowSelection=''
            className='user-list-table'
            dataSource={patient}
            columns={UserListItem({ isDetail: true })}
          />
        </Title>
        {patient.length > 0 && (
          <Chart
            patientDailySummaryDataList={patientDailySummaryDataList}
            setState={setState}
            state={state}
            loading={loading}
            isLance={user.lanceBandRestriction}
            selectVisible={selectVisible}
            measureDetail={measureDetail}
            setViewDate={setViewDate}
            onClickTable={onClickTable}
            viewDate={viewDate}
          />
        )}
        {patient.length > 0 && (
          <>
            <Tabs
              defaultActiveKey='1'
              tabBarExtraContent={
                selTab === '1'
                  ? {
                      left: (
                        <div
                          style={{ paddingRight: '5px', paddingLeft: '5px' }}
                        >
                          <Button
                            disabled={selTab !== '1' || selRecept?.length < 1}
                            type='primary'
                            onClick={(e) => {
                              confirm();
                            }}
                            size='small'
                            style={{
                              width: '50px',
                              background: 'dimgray',
                              border: 'none',
                            }}
                          >
                            削除
                          </Button>
                        </div>
                      ),
                    }
                  : {}
              }
              onTabClick={(e) => {
                setSelTab(e);
              }}
            >
              <TabPane tab='受付単位データ' key='1'>
                <Table
                  rowSelection=''
                  className='reception-table'
                  dataSource={patientReceptInfoList}
                  columns={ReceptionItem(
                    navigate,
                    patient[0]?.patientId,
                    user.lanceBandRestriction,
                    onCheckRecept,
                    checkedList,
                    false,
                    setThumbnailModalData,
                  )}
                />
              </TabPane>
              <TabPane
                tab={
                  <Popover
                    content={`グラフのプロットデータと対応しています。\nデータ行をクリックすることでグラフにマーカーを表示します。`}
                  >
                    日単位データ
                  </Popover>
                }
                key='2'
              >
                <Table
                  rowSelection=''
                  className='summary-table'
                  dataSource={_list}
                  columns={SummaryItem(
                    user.lanceBandRestriction,
                    state?.selectWe,
                  )}
                  rowClassName={(record, index) =>
                    viewDate.includes(record?.measureDate)
                      ? 'table-row-view'
                      : ''
                  }
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        if (viewDate.includes(record?.measureDate)) {
                          seTonClickTable({
                            lineDate:
                              viewDate?.[0] === record?.measureDate
                                ? viewDate?.[1]
                                : viewDate?.[0],
                            tooltipPosition: onClickTable?.tooltipPosition,
                            isNew: false,
                          });
                        } else {
                          const displayP =
                            graphWidth -
                            graphWidth * 0.051 -
                            graphWidth * 0.121 +
                            graphWidth * 0.0255;
                          const point =
                            displayP / patientDailySummaryDataList?.length;
                          seTonClickTable({
                            lineDate: record?.measureDate,
                            tooltipPosition: [
                              rowIndex * point + point / 2 + graphWidth * 0.041,
                              1,
                            ],
                            isNew: true,
                          });
                        }
                      },
                    };
                  }}
                />
              </TabPane>
            </Tabs>
          </>
        )}
      </div>
      {/* {メディアメモIDで削除及び表示を制御する？} */}
      {thumbnailModal.visibleReceptId &&
      thumbnailModal.visibleReceptId !== 0 ? (
        <ThumbnailListModal
          date={thumbnailModal.date}
          mediaList={thumbnailModal.mediaList}
          pageY={thumbnailModal.pageY}
          setThumbnailModalData={setThumbnailModalData}
          setmediaModalData={setmediaModalData}
          visibleMediaList={mediaModal.visibleMediaList}
        />
      ) : (
        <></>
      )}
      {mediaModal?.visibleMediaList &&
      mediaModal?.visibleMediaList?.length > 0 ? (
        mediaModal?.visibleMediaList.map((value, index, array) => {
          //console.log(value);
          return (
            <MediaMemoModal
              key={value?.mediaMemoId}
              date={value?.date}
              mediaMemoId={value?.mediaMemoId}
              setmediaModalData={setmediaModalData}
              visibleMediaList={mediaModal.visibleMediaList}
              mediaModalZIndex={mediaModalZIndex}
              setMediaModalZIndex={setMediaModalZIndex}
              pageY={value?.pageY}
            />
          );
        })
      ) : (
        <></>
      )}
      {reportDownloadModal ? (
        <ReportDownloadModal
          open={reportDownloadModal}
          onCancel={() => setReportWownloadModal(false)}
          patientInfo={patient?.[0] || {}}
        />
      ) : null}
    </Spin>
  );
};

// make this component available to the app
export default UserDetailScene;
