import React, { createContext, useReducer } from 'react';

const mConstans = {
  SET_STATE: 'SET_STATE',
};
const initialState = {
  graphWidth: 0,
};
const MyContext = createContext();

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case mConstans.SET_STATE: {
        return {
          ...state,
          ...action.payload,
        };
      }
      default:
        return state;
    }
  }, initialState);

  const setValue = (data = {}) => {
    dispatch({ type: mConstans.SET_STATE, payload: data });
  };

  return (
    <MyContext.Provider
      value={{
        state,
        dispatch,
        setValue,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

const useMonitorStore = () => React.useContext(MyContext);

export { Provider, useMonitorStore };
