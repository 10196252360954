import {
  convertLabelBarChartData,
  dataFormatter,
  dataFormatterBabel,
} from '@app/utils/commonFunctions';

const selectedDefault = {
  脈拍: true,
  酸素: true,
  収縮圧: true,
  拡張圧: true,
  脈拍f: true,
  酸素f: true,
  収縮圧f: true,
  拡張圧f: true,
  脈拍b: true,
  酸素b: true,
  収縮圧b: true,
  拡張圧b: true,
};

export const vitalStackOptions = (
  selectGraph,
  _patientDailySummaryDataList,
  selectVisible,
  //setGraphTooltipData,
  // firstDate,
  // secondDate,
  axis,
  markerArray,
  stackObject = [],
) => {
  switch (selectGraph) {
    case 1: {
      const patientDailySummaryDataList = dataFormatter(
        _patientDailySummaryDataList,
        'isVitalPlot',
      );

      const selected = {
        ...stackObject?.reduce((p, item) => {
          return { ...p, [item.seriesName]: item?.isView };
        }, {}),
      };

      return lineGraph(
        patientDailySummaryDataList,
        selectVisible,
        //setGraphTooltipData,
        // firstDate,
        // secondDate,
        axis,
        stackObject ? selected : selectedDefault,
        markerArray,
      );
    }
    case 2: {
      const patientDailySummaryDataList = dataFormatter(
        _patientDailySummaryDataList,
        'isVitalPlot',
      );
      return barDateGraph(patientDailySummaryDataList, selectVisible);
    }
    case 3: {
      const patientDailySummaryDataList = dataFormatterBabel(
        _patientDailySummaryDataList,
        'isVitalPlot',
      );
      return barLabelGraph(patientDailySummaryDataList, selectVisible);
    }
    default:
      break;
  }
};

const lineGraph = (
  patientDailySummaryDataList,
  selectVisible,
  //setGraphTooltipData,
  // firstDate,
  // secondDate,
  axis,
  selected,
  markerArray,
) => {
  return {
    legend: {
      data: [
        '脈拍',
        '脈拍f',
        '脈拍b',
        '酸素',
        '酸素f',
        '酸素b',
        '収縮圧',
        '収縮圧f',
        '収縮圧b',
        '拡張圧',
        '拡張圧f',
        '拡張圧b',
      ],
      //icon: 'circle',
      top: selectVisible.beWell === 2 ? '95%' : '90%',
      left: 25,
      selected: { ...selected },
    },
    // tooltip: {
    //   trigger: 'axis',
    //   triggerOn: 'click',
    //   formatter: () => {
    //     return '';
    //   },
    //   position: function (point, params) {
    //     setGraphTooltipData({
    //       tooltipPosition: point,
    //       lineDate: params[0]?.axisValue,
    //     });
    //     return [point[0], '10%'];
    //   },
    // },
    xAxis: {
      type: 'category',
      data: patientDailySummaryDataList?.measureDate || [],
    },
    yAxis: {
      type: 'value',
      scale: true,
      position: 'left',
      max: axis?.maxAxisVital,
      min: axis?.lowAxisVital,
    },
    grid: [
      {
        top: 5,
        bottom: 60,
        left: 50,
      },
    ],
    dataZoom: {
      show: false,
      type: 'slider',
    },
    series: [
      {
        name: 'Precipitation',
        type: 'bar',
        barWidth: 2,
        yAxisIndex: 0,
        tooltip: {
          valueFormatter: function (value) {
            return value + ' ml';
          },
        },
        data: markerArray,
      },
      {
        name: '脈拍',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.pulse,
        color: '#F73131',
      },

      {
        name: '酸素',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.oxygen,
        color: '#3F8CD1',
      },
      {
        name: '収縮圧',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.sbp,
        color: '#A156D8',
      },
      {
        name: '拡張圧',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.dbp,
        color: '#48AC2A',
      },
      {
        name: '脈拍f',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.pulseF,
        color: '#F73131',
        lineStyle: {
          type: 'dashed',
        },
      },
      {
        name: '酸素f',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.oxygenF,
        color: '#3F8CD1',
        lineStyle: {
          type: 'dashed',
        },
      },
      {
        name: '収縮圧f',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.sbpF,
        color: '#A156D8',
        lineStyle: {
          type: 'dashed',
        },
      },
      {
        name: '拡張圧f',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.dbpF,
        color: '#48AC2A',
        lineStyle: {
          type: 'dashed',
        },
      },
      {
        name: '脈拍b',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.pulseB,
        color: '#F73131',
        lineStyle: {
          type: 'dotted',
        },
      },
      {
        name: '酸素b',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.oxygenB,
        color: '#3F8CD1',
        lineStyle: {
          type: 'dotted',
        },
      },
      {
        name: '収縮圧b',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.sbpB,
        color: '#A156D8',
        lineStyle: {
          type: 'dotted',
        },
      },
      {
        name: '拡張圧b',
        type: 'line',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.dbpB,
        color: '#48AC2A',
        lineStyle: {
          type: 'dotted',
        },
      },
    ],
  };
};

const barDateGraph = (patientDailySummaryDataList, selectVisible) => {
  return {
    legend: [
      {
        data: [
          '脈拍',
          '酸素',
          '収縮圧',
          '拡張圧',
          '脈拍f',
          '酸素f',
          '収縮圧f',
          '拡張圧f',
          '脈拍b',
          '酸素b',
          '収縮圧b',
          '拡張圧b',
        ],
        icon: 'circle',
        top: selectVisible.beWell === 2 ? '95%' : '90%',
        left: 25,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: patientDailySummaryDataList?.measureDate || [],
    },
    yAxis: {
      type: 'value',
      scale: true,
      position: 'left',
    },
    grid: {
      top: 5,
      bottom: 60,
      left: 50,
    },
    dataZoom: {
      show: false,
      type: 'slider',
    },
    series: [
      {
        name: '脈拍',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.pulse,
      },

      {
        name: '酸素',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.oxygen,
      },
      {
        name: '収縮圧',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.sbp,
      },
      {
        name: '拡張圧',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.dbp,
      },
      {
        name: '脈拍f',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.pulseF,
      },
      {
        name: '酸素f',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.oxygenF,
      },
      {
        name: '収縮圧f',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.sbpF,
      },
      {
        name: '拡張圧f',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.dbpF,
      },
      {
        name: '脈拍b',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.pulseB,
      },
      {
        name: '酸素b',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.oxygenB,
      },
      {
        name: '収縮圧b',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.sbpB,
      },
      {
        name: '拡張圧b',
        type: 'bar',
        showAllSymbol: true,
        data: patientDailySummaryDataList?.dbpB,
      },
    ],
  };
};

const barLabelGraph = (patientDailySummaryDataList, selectVisible) => {
  return {
    legend: [
      {
        data: patientDailySummaryDataList?.measureDate || [],
        icon: 'circle',
        top: selectVisible.beWell === 2 ? '95%' : '90%',
        left: 25,
      },
    ],
    tooltip: { trigger: 'axis' },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value',
      scale: true,
      position: 'left',
    },
    grid: [
      {
        top: 5,
        left: 50,
      },
    ],
    dataZoom: {
      show: false,
      type: 'slider',
    },
    dataset: {
      dimensions: [
        'productName',
        ...(patientDailySummaryDataList?.measureDate || []),
      ],
      source: [
        {
          productName: '脈拍',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'pulse'),
        },
        {
          productName: '酸素',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'oxygen'),
        },
        {
          productName: '収縮圧',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'sbp'),
        },
        {
          productName: '拡張圧',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'dbp'),
        },
        {
          productName: '脈拍f',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'pulseF'),
        },
        {
          productName: '酸素f',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'oxygenF'),
        },
        {
          productName: '収縮圧f',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'sbpF'),
        },
        {
          productName: '拡張圧f',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'dbpF'),
        },
        {
          productName: '脈拍b',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'pulseB'),
        },
        {
          productName: '酸素b',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'oxygenB'),
        },
        {
          productName: '収縮圧b',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'sbpB'),
        },
        {
          productName: '拡張圧b',
          ...convertLabelBarChartData(patientDailySummaryDataList, 'dbpB'),
        },
      ],
    },
    series:
      patientDailySummaryDataList?.measureDate?.length > 0
        ? Array(patientDailySummaryDataList?.measureDate?.length)
            .fill(0)
            .map(() => ({
              type: 'bar',
            }))
        : [{}],
  };
};
