// import libraries
import React, { useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Rnd } from 'react-rnd';
import ReactPlayer from 'react-player';

const baseUrl = 'https://og-datalink.jp/';

const MediaMemoModal = ({
  date,
  mediaMemoId,
  setmediaModalData,
  visibleMediaList,
  mediaModalZIndex,
  setMediaModalZIndex,
  pageY = 200,
}) => {
  const [size, setSize] = useState({
    height: 175,
    width: 175,
  });
  const [zIndex, setZIndex] = useState(mediaModalZIndex);
  return (
    <Rnd
      default={{
        x: 150,
        y: pageY,
        width: 250,
        height: 250,
      }}
      minWidth={250}
      minHeight={250}
      bounds='window'
      onResize={(e, direction, ref, delta, position) => {
        setSize({
          width: parseInt(ref.style.width.replace('px', '')) * 0.7,
          height: parseInt(ref.style.height.replace('px', '')) * 0.7,
        });
      }}
      className='media-box'
      style={{
        zIndex: zIndex,
      }}
      onClick={() => {
        setMediaModalZIndex(mediaModalZIndex + 1);
        setZIndex(mediaModalZIndex + 1);
      }}
    >
      <div className='title'>
        <span className='date-txt'>{date}</span>
        <CloseCircleOutlined
          className='close-btn'
          style={{ color: 'red', fontSize: '25px' }}
          onClick={() => {
            setmediaModalData(
              visibleMediaList.filter(
                (erem, index) => erem?.mediaMemoId !== mediaMemoId,
              ),
            );
          }}
        />
      </div>
      <hr
        style={{
          color: 'black',
          backgroundColor: 'black',
          height: 3,
          marginBottom: 0,
          paddingBottom: 0,
        }}
      />
      <ReactPlayer
        url={
          baseUrl +
          'api/img/mediamemo/facilityId1/patientId242/memoId825/version1/main.20220803171345.MP4'
        }
        id='MainPlay'
        playing
        controls={true}
        height={size.height || 175}
        width={size.width || 175}
      />
    </Rnd>
  );
};

// make this component available to the app
export default MediaMemoModal;
