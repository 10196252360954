// import libraries
import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// import utils
import { useAuthContext } from '@app/providers/authProvider';
import { useStableNavigate } from '@app/providers/stableNavigate';
import {
  getMediaMemoList,
  delMediaMemo,
} from '@app/services/user/userDetail.service';
import { Routes } from '@app/routes/routes';
import { ReactComponent as DelSvg } from 'assets/svg/delete.svg';
import { ReactComponent as DwnSvg } from 'assets/svg/download.svg';

const ThumbnailListScene = () => {
  const baseUrl = 'https://og-datalink.jp/';
  const { user, userFacility } = useAuthContext();
  const [state, setState] = useState({
    selectDate: '',
    mediaList: [],
    update: 1,
  });
  const { mediaList, loading, selectDate, update } = state;
  const { search } = useLocation();
  const params = queryString.parse(search);
  const navigate = useStableNavigate();

  //APIからデータ取得
  const _fetchData = async () => {
    try {
      // Result of Searched values to get API data
      setState({ ...state, loading: true });
      const _res = await getMediaMemoList({
        facilityId: userFacility?.facilityId || null,
        receptId: params?.receptId,
      });

      const _mediaMemoList = (_res?.data?.mediaMemoList || []).map(
        (data, index) => {
          return { ...data, key: index };
        },
      );
      setState({
        ...state,
        mediaList: _mediaMemoList,
        selectDate: params?.date,
        loading: false,
      });
    } catch (error) {
      console.log('userDetail', error);
      setState({ ...state, loading: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (params?.receptId) {
      _fetchData();
    }
    document.body.classList.add('media-window-body');
    return () => {
      document.body.classList.remove('media-window-body');
    };
  }, [update]);

  const download = async (url) => {
    var fileLink = document.createElement('a');
    fileLink.href = baseUrl + url;
    fileLink.setAttribute(
      'download',
      url.split('/')[url.split('/').length - 1],
    );
    document.body.appendChild(fileLink);
    fileLink.click();
  };

  const deleteFile = async (mediaMemoId) => {
    try {
      // Result of Searched values to get API data
      setState({ ...state, loading: true });
      await delMediaMemo({
        facilityId: userFacility?.facilityId || null,
        mediaMemoId: mediaMemoId,
      });
      setState({
        ...state,
        loading: false,
        update: update + 1,
      });
    } catch (error) {
      console.log('userDetail', error);
      setState({ ...state, loading: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  const handleOk = (deleteId) => {
    deleteFile(deleteId);
  };

  const showModal = (deleteId) => {
    Modal.confirm({
      title: 'ファイルの削除',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          選択されたファイルを削除します。
          <br />
          この操作は元に戻せません。
          <br />
          ファイルを削除してよろしいですか？
        </div>
      ),
      okText: '削除',
      cancelText: 'キャンセル',
      onOk: () => {
        handleOk(deleteId);
      },
      width: 400,
    });
  };

  return (
    <Spin spinning={loading}>
      <div className='thumbnail-container'>
        <div className='title'>
          <span className='date-txt'>{selectDate}</span>
        </div>
        <hr
          style={{
            color: 'black',
            backgroundColor: 'black',
            height: 3,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        />
        <div className='img-set-container'>
          {mediaList.map((value, index, array) => {
            return (
              <div className='img-set' key={value?.mediaMemoId}>
                <span
                  onClick={(e) => {
                    window.open(
                      '/users/detail/mediamemo?date=' +
                        value?.date +
                        '&url=' +
                        encodeURIComponent(value?.mediaMemoUrl),
                      '',
                      'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30',
                    );
                  }}
                >
                  <img
                    src={baseUrl + value?.thumbnailUrl}
                    draggable='false'
                    alt='index'
                    className='humbnail-img'
                  />
                </span>
                <div className='icon-set'>
                  <DwnSvg
                    className='download-btn'
                    style={{ color: 'blue', fontSize: '25px' }}
                    onClick={() => download(value?.mediaMemoUrl)}
                  />
                  <DelSvg
                    className='delete-btn'
                    style={{ color: 'gray', fontSize: '25px' }}
                    onClick={() => showModal(value?.mediaMemoId)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Spin>
  );
};

// make this component available to the app
export default ThumbnailListScene;
