// import libraries
import React, { useState, useEffect } from 'react';
import { Table, Title } from '@app/components';
import { Descriptions, Tabs, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// import utils
import BasicInfoDetailItem from './components/basicInfoDetail.item';
import SummaryInfoDetailItem from './components/summaryInfoDetail.item';
import FacilityItem from './components/facility.item';
import { getContractorList } from '@app/services/contractor/contractor.service';
import { useStableNavigate } from '@app/providers/stableNavigate';
import { Routes } from '@app/routes/routes';

const { TabPane } = Tabs;

const ContractorDetailScene = () => {
  const navigate = useStableNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const [state, setState] = useState({
    contractDetail: {},
    loading: true,
  });

  const { contractDetail, loading } = state;

  useEffect(() => {
    if (params.contractId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      // Result of Searched values to get API data
      const _res = await getContractorList({
        contractId: params.contractId,
      });

      setState({
        loading: false,
        contractDetail: _res.data.contractList?.[0] || {},
      });
    } catch (error) {
      setState({ ...state, loading: false });
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div className='contractor-detail '>
        <Title title='契約者詳細画面'></Title>
        <Title title='基本情報' list noButtons>
          <Descriptions bordered size='small'>
            <Descriptions.Item label='契約者氏名' span={4}>
              {contractDetail?.managerName || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='契約者氏名（ふりがな）' span={4}>
              {contractDetail?.managerNameKana || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='メールアドレス' span={4}>
              {contractDetail?.mail || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='電話番号' span={4}>
              {contractDetail?.tel || '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <>
                  所属施設名
                  <br />
                  （医療機関名、治療院名など）
                </>
              }
              span={4}
            >
              {contractDetail?.contractFacilityName || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='郵便番号' span={4}>
              {contractDetail?.zip || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='都道府県' span={4}>
              {contractDetail?.pref || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='市区町村' span={4}>
              {contractDetail?.city || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='番地など' span={4}>
              {contractDetail?.street || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='建物名・部屋番号' span={4}>
              {contractDetail?.building || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='登録時製品型式' span={4}>
              {contractDetail?.productModel || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='登録製品番号(英字)' span={4}>
              {contractDetail?.productNoAlp || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='登録製品番号(数字)' span={4}>
              {contractDetail?.productNoNum || '-'}
            </Descriptions.Item>
          </Descriptions>
        </Title>
        <Title title='サマリー情報' list noButtons>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='基本情報' key='1'>
              <Table
                rowSelection=''
                className='user-list-table'
                dataSource={[contractDetail]}
                columns={BasicInfoDetailItem()}
              />
            </TabPane>
            <TabPane tab='サマリー情報' key='2'>
              <Table
                rowSelection=''
                className='user-list-table'
                dataSource={[contractDetail]}
                columns={SummaryInfoDetailItem()}
              />
            </TabPane>
          </Tabs>
        </Title>
        <Title title='利用施設' list noButtons>
          <Table
            rowSelection=''
            className='user-list-table'
            dataSource={contractDetail?.facilityList || []}
            columns={FacilityItem({ contractDetail })}
          />
        </Title>
      </div>
    </Spin>
  );
};

export default ContractorDetailScene;
