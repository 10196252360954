// import liraries
import React, { useState,useLayoutEffect,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ReactPlayer from 'react-player'
import _ from 'lodash';

const MediaMemoScene = () => {
  const baseUrl = 'https://og-datalink.jp/';
  const { search } = useLocation();
  const params = queryString.parse(search);
  

  const [size,setSize] = useState({
    height:window.innerHeight*0.8,
    width:window.innerWidth*0.8,
  });

  const handleWindowResize = _.debounce(() => {
      setSize({
        height : window.innerHeight*0.8,
        width : window.innerWidth*0.8,
      });
  },0);

  useLayoutEffect(() => {                                                                                     
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();   
    return () => window.removeEventListener('resize', handleWindowResize);
  });

  useEffect(() => {
    document.body.classList.add('media-window-body');
    return () => {
      document.body.classList.remove('media-window-body');
    };
  }, []);

  return (
    <div
      className='media-box'
    >
      <div className='img-video-container'>
        {
          params?.url ?
            params?.url.slice(-3) !== 'mp4' && params?.url.slice(-3) !== 'MP4' ?
            <img  className='img-video' src={baseUrl + decodeURIComponent(params?.url)} draggable="false" alt="github avatar" 
              style={{
                height:size.height || window.innerWidth*0.8,
                width:size.width || window.innerWidth*0.8,
              }}
            /> 
            :
            <ReactPlayer className='img-video' url={baseUrl + decodeURIComponent(params?.url)} id="MainPlay" playing controls={true} height={size.height || 175} width={size.width || 175}/>
          :
          'データ無し'
        }
      </div>
      <div className='media-footer'>
          <hr
            style={{
                color: 'black',
                backgroundColor: 'black',
                height: 3,
                margin:0,
                padding:0,
            }}
          />
            <div className='title'>
              <span className='date-txt'>登録日時：{params?.date}</span>
            </div>
          <hr
            style={{
                color: 'black',
                backgroundColor: 'black',
                height: 3,
                margin:0,
                padding:0,
            }}
          />
      </div>
    </div>
  )
};

// make this component available to the app
export default MediaMemoScene;
