// import libraries
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useLayoutEffect,
} from 'react';
import { Row, Col, Input, Form, Button } from 'antd';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import _ from 'lodash';

//import utils
import { Title } from '@app/components';
import { vitalStackOptions } from '@app/scenes/home/users/measureDetail/components/helper/chartLanceOptions';
import { aerobicStackOptions } from '@app/scenes/home/users/measureDetail/components/helper/chartBeWellOptions';
import { chartZoomOptions } from '@app/scenes/home/users/measureDetail/components/helper/chartZoomOptions';
import { overlayChartOptions } from '@app/scenes/home/users/measureDetail/components/helper/overlayChartOptions';

import { deviceNames } from '@app/utils/static';
import ChartTooltipMeasureDetail from '@app/components/chartTooltipMeasureDetail';
import { screenShot } from '@app/services/screenshot.service';
import { useMonitorStore } from '@app/utils/contextManager';
// create a component

const { useForm } = Form;

const _aerobicStackObject = [
  { seriesName: '計測時刻', data: 'measureTime', isView: true },
  { seriesName: '脈拍', data: 'bewellPulse', isView: true },
  { seriesName: 'ワット', data: 'watt', isView: true },
  { seriesName: 'メッツ', data: 'mets', isView: true },
  { seriesName: 'ペダル負荷', data: 'loadLevel', isView: true },
  { seriesName: '歩数/回転数', data: 'step_rotation', isView: true },
  { seriesName: '累積歩数/回転数', data: 'cumulative', isView: true },
  { seriesName: '消費カロリー', data: 'cal', isView: true },
  { seriesName: '距離', data: 'distance', isView: true },
];
const _aerobicStackObject2 = [
  { seriesName: '計測時刻', data: 'measureTime', isView: true },
  { seriesName: '脈拍', data: 'bewellPulse', isView: true },
  { seriesName: 'ワット', data: 'watt', isView: true },
  { seriesName: '消費カロリー', data: 'cal', isView: true },
  { seriesName: 'ペダル回転数', data: 'step_rotation', isView: true },
  { seriesName: 'ペダルトルク', data: 'pedalTorque', isView: true },
];

const _vitalStackObject = [
  { seriesName: '計測時刻', data: 'measureTime', isView: true },
  { seriesName: '脈拍', data: 'pulse', isView: true },
  { seriesName: '酸素', data: 'oxygen', isView: true },
  { seriesName: '収縮圧', data: 'sbp', isView: true },
  { seriesName: '拡張圧', data: 'dbp', isView: true },
];

const _aerobicStackObject_overlay = [
  { seriesName: '計測時刻', data: 'measureTime', isView: true },
  { seriesName: 'BE-Well脈拍', data: 'bewellPulse', isView: true },
  { seriesName: 'ワット', data: 'watt', isView: true },
  { seriesName: 'メッツ', data: 'mets', isView: true },
  { seriesName: 'ペダル負荷', data: 'loadLevel', isView: true },
  { seriesName: '歩数/回転数', data: 'step_rotation', isView: true },
  { seriesName: '累積歩数/回転数', data: 'cumulative', isView: true },
  { seriesName: '消費カロリー', data: 'cal', isView: true },
  { seriesName: '距離', data: 'distance', isView: true },
];

const _aerobicStackObjectMederugo_overlay = [
  { seriesName: '計測時刻', data: 'measureTime', isView: true },
  { seriesName: 'メデルゴ脈拍', data: 'bewellPulse', isView: true },
  { seriesName: 'ワット', data: 'watt', isView: true },
  { seriesName: '消費カロリー', data: 'cal', isView: true },
  { seriesName: 'ペダル回転数', data: 'step_rotation', isView: true },
  { seriesName: 'ペダルトルク', data: 'pedalTorque', isView: true },
];

const _overlayStackObject = [
  ..._vitalStackObject,
  ..._aerobicStackObject_overlay,
];
const _overlayStackObjectMederugo = [
  ..._vitalStackObject,
  ..._aerobicStackObjectMederugo_overlay,
];

//onEvents用
let vitalMarkerArray = [];
let aerMarkerArray = [];
let overlayMarkerArray = [];
let zoomStart = 0;
let zoomEnd = 100;
let vitalStackObj_marker = [];
let aeroStackObj_marker = [];
let overlayStackObj_marker = [];
let highestValueVital;
let highestValueAero;
let firstDate = '';
let secondDate = '';
let markerAxis = {};

const Chart = ({
  realtimeListDataList = [],
  isLance,
  selectVisible,
  setState = () => {},
  state = {},
  loading = true,
  measureDetail,
  setViewDate = () => {},
  onClickTable = '',
  viewDate,
  beWellAndDongleMeasureList = [],
  lanceMeasureList = [],
  chooseBeWellMeasureMent = {},
  chooseLanceMeasureMent = {},
  bewellDonglePickerDonePress = () => {},
  lancePickerDonePress = () => {},
}) => {
  const [form] = useForm();
  const [axisForm] = useForm();
  const lanceRef = useRef();
  const beWellRef = useRef();
  const overlayRef = useRef();
  const dataZoomRef = useRef();
  const chartRef = useRef();
  const chartToolRef = useRef();
  const [graphTooltipData, setGraphTooltipData] = useState({
    lineDate: '',
    tooltipPosition: [],
  });

  const [toolTipWidth, setTooltipWidh] = useState(0);
  const {
    // state: { graphWidth = 0 },
    setValue,
  } = useMonitorStore();
  useEffect(() => {
    setValue({
      graphWidth: chartRef.current ? chartRef.current.offsetWidth : 0,
    });
  }, [chartRef.current]);

  const [markerDate, setMarkerDate] = useState({
    firstDate: '',
    secondDate: '',
    vitalMarkerDateMap: new Map(),
    aerMarkerDateMap: new Map(),
    overlayMarkerDateMap: new Map(),
    zoomStart: 0,
    zoomEnd: 100,
  });

  const handleWindowResize = _.debounce(() => {
    const _toolTipWidth =
      beWellRef?.current?.getEchartsInstance()?.getWidth() ||
      lanceRef?.current?.getEchartsInstance()?.getWidth() ||
      overlayRef?.current?.getEchartsInstance()?.getWidth();
    setTooltipWidh(_toolTipWidth);
  }, 100);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => window.removeEventListener('resize', handleWindowResize);
  });

  const createInitialMap = () => {
    const vitalMarkerDateMap = new Map();
    for (const element of realtimeListDataList) {
      vitalMarkerDateMap.set(element.measureTime, 0);
    }
    return vitalMarkerDateMap;
  };
  //onEvents以外のマーカー挙動対応のため
  useEffect(() => {
    onDisplayChange();
    const _map = createInitialMap();
    const _map2 = createInitialMap();
    const _map3 = createInitialMap();
    setMarkerDate((markerDate) => {
      return {
        ...markerDate,
        firstDate: '',
        secondDate: '',
        vitalMarkerDateMap: _map,
        aerMarkerDateMap: _map2,
        overlayMarkerDateMap: _map3,
      };
    });
  }, [realtimeListDataList, state?.isMeasureTime]);
  //画面再描画時のクリーンアップ
  useEffect(() => {
    onDisplayChange();
  }, []);

  const mapToArray = (map) => {
    const result = [];
    let index = 0;
    for (const [key, value] of map) {
      result[index] = value;
      index++;
    }
    return result;
  };

  // 縦軸の最大値計算
  if (!loading) {
    const reduced = realtimeListDataList.reduce(function (filteredArr, option) {
      if (option.isVitalPlot) {
        let { pulse, oxygen, sbp, dbp } = option;
        const arr = Object.values({ pulse, oxygen, sbp, dbp });
        filteredArr.push(Math.max(...arr));
      }
      return filteredArr;
    }, []);
    const _reduced = realtimeListDataList.reduce(function (
      filteredArr,
      option,
    ) {
      if (option.isAerobicPlot) {
        const {
          bewellPulse,
          watt,
          mets,
          loadLevel,
          step_rotation,
          cumulative,
          cal,
          distance,
          pedalTorque,
        } = option;
        const arr = Object.values({
          bewellPulse: bewellPulse || 0,
          watt: watt || 0,
          mets: mets || 0,
          loadLevel: loadLevel || 0,
          step_rotation: step_rotation || 0,
          cumulative: cumulative || 0,
          cal: cal || 0,
          distance: distance || 0,
          pedalTorque: pedalTorque || 0,
        });
        filteredArr.push(Math.max(...arr));
      }
      return filteredArr;
    },
    []);
    highestValueVital = reduced.length
      ? Math.max(...reduced) === 0
        ? 1
        : Math.max(...reduced)
      : 1;
    highestValueAero = _reduced.length
      ? Math.max(..._reduced) === 0
        ? 1
        : Math.max(..._reduced)
      : 1;
  }
  const onMarkArea = (date, _markerDate = markerDate) => {
    firstDate = _markerDate?.firstDate ? _markerDate?.firstDate : firstDate;
    secondDate = _markerDate?.secondDate ? _markerDate?.secondDate : secondDate;
    const vitalMarkerDateMap = _markerDate
      ? _markerDate?.vitalMarkerDateMap
      : markerDate.vitalMarkerDateMap;
    const aerMarkerDateMap = _markerDate
      ? _markerDate?.aerMarkerDateMap
      : markerDate.aerMarkerDateMap;
    const overlayMarkerDateMap = _markerDate
      ? _markerDate?.overlayMarkerDateMap
      : markerDate.overlayMarkerDateMap;
    zoomStart = _markerDate?.zoomStart ? _markerDate?.zoomStart : zoomStart;
    zoomEnd = _markerDate?.zoomEnd ? _markerDate?.zoomEnd : zoomEnd;
    vitalStackObj_marker = _markerDate
      ? vitalStackObject
      : vitalStackObj_marker;
    aeroStackObj_marker = _markerDate
      ? aerobicStackObject
      : aeroStackObj_marker;
    overlayStackObj_marker = _markerDate
      ? overlayStackObject
      : overlayStackObj_marker;
    markerAxis = _markerDate ? axis : markerAxis;

    if (firstDate === date) {
      vitalMarkerDateMap.set(date, 0);
      aerMarkerDateMap.set(date, 0);
      overlayMarkerDateMap.set(date, 0);
      //外部
      setMarkerDate((markerDate) => {
        return {
          ...markerDate,
          firstDate: secondDate || '',
          secondDate: '',
          vitalMarkerDateMap: vitalMarkerDateMap,
          aerMarkerDateMap: aerMarkerDateMap,
          overlayMarkerDateMap: overlayMarkerDateMap,
        };
      });
      firstDate = secondDate || '';
      secondDate = '';
    } else if (secondDate === date) {
      secondDate = '';
      vitalMarkerDateMap.set(date, 0);
      aerMarkerDateMap.set(date, 0);
      overlayMarkerDateMap.set(date, 0);
      //外部
      setMarkerDate((markerDate) => {
        return {
          ...markerDate,
          firstDate: firstDate,
          secondDate: '',
          vitalMarkerDateMap: vitalMarkerDateMap,
          aerMarkerDateMap: aerMarkerDateMap,
          overlayMarkerDateMap: overlayMarkerDateMap,
        };
      });
    } else {
      let oldFirst = firstDate;
      secondDate && vitalMarkerDateMap.set(secondDate, 0);
      secondDate && aerMarkerDateMap.set(secondDate, 0);
      secondDate && overlayMarkerDateMap.set(secondDate, 0);
      secondDate = firstDate || '';
      firstDate = date;
      vitalMarkerDateMap.set(date, highestValueVital);
      aerMarkerDateMap.set(date, highestValueAero);
      overlayMarkerDateMap.set(
        date,
        highestValueAero > highestValueVital
          ? highestValueAero
          : highestValueVital,
      );
      //外部
      setMarkerDate((markerDate) => {
        return {
          ...markerDate,
          secondDate: oldFirst || '',
          firstDate: date,
          vitalMarkerDateMap: vitalMarkerDateMap,
          aerMarkerDateMap: aerMarkerDateMap,
          overlayMarkerDateMap: overlayMarkerDateMap,
        };
      });
    }
    vitalMarkerArray = mapToArray(vitalMarkerDateMap);
    aerMarkerArray = mapToArray(aerMarkerDateMap);
    overlayMarkerArray = mapToArray(overlayMarkerDateMap);

    if (lanceRef?.current) {
      const vitalOption = vitalStackOptions(
        realtimeListDataList,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        markerAxis,
        vitalMarkerArray,
        vitalStackObj_marker,
        state.isMeasureTime,
      );
      lanceRef.current.getEchartsInstance().setOption(vitalOption, true, true);
      lanceRef.current.getEchartsInstance().dispatchAction({
        type: 'dataZoom',
        start: zoomStart,
        end: zoomEnd,
      });
    }
    if (beWellRef?.current) {
      const aeroOption = aerobicStackOptions(
        realtimeListDataList,
        isLance,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        markerAxis,
        aerMarkerArray,
        aeroStackObj_marker,
        chooseBeWellMeasureMent?.rehabilitationId,
        state?.isMeasureTime,
      );
      beWellRef.current.getEchartsInstance().setOption(aeroOption, true, true);
      beWellRef.current.getEchartsInstance().dispatchAction({
        type: 'dataZoom',
        start: zoomStart,
        end: zoomEnd,
      });
    }
    if (overlayRef?.current) {
      const overlayOption = overlayChartOptions(
        realtimeListDataList,
        isLance,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        markerAxis,
        overlayMarkerArray,
        overlayStackObj_marker,
        chooseBeWellMeasureMent?.rehabilitationId,
        state?.isMeasureTime,
      );

      overlayRef.current
        .getEchartsInstance()
        .setOption(overlayOption, true, true);
      overlayRef.current.getEchartsInstance().dispatchAction({
        type: 'dataZoom',
        start: zoomStart,
        end: zoomEnd,
      });
    }
  };

  useEffect(() => {
    setValue({
      graphWidth: chartRef.current ? chartRef.current.offsetWidth : 0,
    });
  }, [chartRef.current]);

  useEffect(() => {
    if (onClickTable?.isNew) {
      if (
        onClickTable?.lineDate === markerDate.firstDate &&
        markerDate.secondDate
      )
        chartToolRef?.current.onClick2();
      if (
        onClickTable?.lineDate === markerDate.firstDate &&
        !markerDate.secondDate
      )
        chartToolRef?.current.onClick();
      if (onClickTable?.lineDate === markerDate.secondDate)
        chartToolRef?.current.onClick();
      if (
        onClickTable?.lineDate !== markerDate.firstDate &&
        onClickTable?.lineDate !== markerDate.secondDate
      ) {
        setGraphTooltipData(onClickTable);
      }
      onMarkArea(onClickTable?.lineDate, markerDate);
    } else {
      // const oldIndex = realtimeListDataList.findIndex(
      //   (e) => e?.measureDate === onClickTable?.lineDate,
      // );
      if (onClickTable.tooltipPosition.length > 0) {
        setViewDate((e) => {
          if (!onClickTable?.lineDate) {
            markerClear();
            setGraphTooltipData({
              lineDate: '',
              tooltipPosition: [],
            });
            return ['', ''];
          }
          // 170 → サイド , 65 → グラフの開始とサイドの終了位置, 155 → グラフの最後とwindowの最後
          // const displayP =
          //   graphWidth -
          //   graphWidth * 0.051 -
          //   graphWidth * 0.121 +
          //   graphWidth * 0.0255;

          // PointとPointの間の距離
          // const point = displayP / realtimeListDataList?.length;

          // 最初のPointの位置
          //（point / 2 + 52) → 最初の描画されているPointの位置
          // const oldTooltipPosition = [
          //   oldIndex * point + point / 2 + graphWidth * 0.041,
          //   1,
          // ];
          const targetDate =
            e?.[0] === onClickTable?.lineDate ? e?.[1] : e?.[0];
          onMarkArea(targetDate, markerDate);
          if (e?.[0] === onClickTable?.lineDate) return ['', e?.[0]];
          else return ['', e?.[1]];
        });
      }
    }
  }, [onClickTable?.lineDate, onClickTable?.isNew]);

  useEffect(() => {
    setViewDate((e) => {
      if (!graphTooltipData?.lineDate) {
        return ['', ''];
      }
      if (e?.[0] === graphTooltipData?.lineDate) {
        return ['', e?.[0], true];
      } else if (e?.[1] === graphTooltipData?.lineDate) {
        return ['', e?.[1], true];
      } else {
        return [e?.[1], graphTooltipData?.lineDate, true];
      }
    });
  }, [graphTooltipData?.tooltipPosition]);

  const [axis, setAxis] = useState({});
  //let axis = {};
  const [overlayChart, setOverlayChart] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // BE-WELLのstate更新
  const [vitalStackObject, setVitalStackObject] = useState(_vitalStackObject);
  const [aerobicStackObject, setAerobicStackObject] = useState([]);
  const [overlayStackObject, setOverlayStackObject] = useState([]);

  const default_aerobicStackObject =
    chooseBeWellMeasureMent.rehabilitationId === 2 ||
    chooseBeWellMeasureMent.rehabilitationId === 8
      ? _aerobicStackObject
      : _aerobicStackObject2;

  const default_overlayStackObject =
    chooseBeWellMeasureMent.rehabilitationId === 2 ||
    chooseBeWellMeasureMent.rehabilitationId === 8
      ? _overlayStackObject
      : _overlayStackObjectMederugo;

  useEffect(() => {
    if (chooseBeWellMeasureMent.rehabilitationId) {
      if (
        chooseBeWellMeasureMent.rehabilitationId === 2 ||
        chooseBeWellMeasureMent.rehabilitationId === 8
      ) {
        setAerobicStackObject(_aerobicStackObject);
        setOverlayStackObject(_overlayStackObject);
      }
      if (
        chooseBeWellMeasureMent.rehabilitationId === 11 ||
        chooseBeWellMeasureMent.rehabilitationId === 12 ||
        chooseBeWellMeasureMent.rehabilitationId === 13
      ) {
        setAerobicStackObject(_aerobicStackObject2);
        setOverlayStackObject(_overlayStackObjectMederugo);
      }
    }
  }, [chooseBeWellMeasureMent.rehabilitationId]);

  const [aeroOption, setAeroOption] = useState(
    aerobicStackOptions(
      realtimeListDataList,
      isLance,
      selectVisible,
      //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
      axis,
      aerMarkerArray,
      aerobicStackObject,
      chooseBeWellMeasureMent?.rehabilitationId,
      state?.isMeasureTime,
    ),
  );

  // ランスバンドのstate更新
  const [vitalOption, setVitalOption] = useState(
    vitalStackOptions(
      realtimeListDataList,
      selectVisible,
      //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
      axis,
      vitalMarkerArray,
      vitalStackObject,
      state.isMeasureTime,
    ),
  );

  // グラフ重ねのstate更新
  const [overlayOption, setOverlayOption] = useState(
    overlayChartOptions(
      realtimeListDataList,
      selectVisible,
      isLance,
      //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
      axis,
      overlayMarkerArray,
      overlayStackObject,
      chooseBeWellMeasureMent?.rehabilitationId,
      state?.isMeasureTime,
    ),
  );

  // Be-Well, Lansband, グラフ重ねがリアルタイムデータとaxis変更するごとに更新される
  useEffect(() => {
    setAeroOption(
      aerobicStackOptions(
        realtimeListDataList,
        isLance,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        axis,
        aerMarkerArray,
        [],
        chooseBeWellMeasureMent?.rehabilitationId,
        state?.isMeasureTime,
      ),
    );
    setVitalOption(
      vitalStackOptions(
        realtimeListDataList,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        axis,
        vitalMarkerArray,
        undefined,
        state.isMeasureTime,
      ),
    );
    setOverlayOption(
      overlayChartOptions(
        realtimeListDataList,
        selectVisible,
        isLance,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        axis,
        aerMarkerArray,
        [],
        chooseBeWellMeasureMent?.rehabilitationId,
        state?.isMeasureTime,
      ),
    );
  }, [realtimeListDataList, state?.isMeasureTime]);

  // スライダー機能
  const onEventsDataZoom = {
    datazoom: useCallback((e) => {
      if (lanceRef.current) {
        lanceRef.current.getEchartsInstance().dispatchAction({
          type: 'dataZoom',
          start: e.start,
          end: e.end,
        });
      }
      if (beWellRef.current) {
        beWellRef.current.getEchartsInstance().dispatchAction({
          type: 'dataZoom',
          start: e.start,
          end: e.end,
        });
      }
      if (overlayRef.current) {
        overlayRef.current.getEchartsInstance().dispatchAction({
          type: 'dataZoom',
          start: e.start,
          end: e.end,
        });
      }
    }, []),
  };

  // スクリーンショット機能
  const onTakePhoto = async () => {
    try {
      setLoading(true);
      const imageName = `OG-DATALINK-${
        state?.patient?.[0]?.carteNo
      }-${moment().format('YYYY-MM-DD-hh-mm-ss')}.jpeg`;
      await screenShot(chartRef.current, imageName);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onChartClickAero = (e) => {
    const selected = e?.selected || {};
    const newVal = default_aerobicStackObject.reduce((p, item) => {
      return [...p, { ...item, isView: selected?.[item?.seriesName] }];
    }, []);
    newVal.unshift({
      seriesName: '計測時刻',
      data: 'measureTime',
      isView: true,
    });
    aeroStackObj_marker = newVal;
    setAerobicStackObject(newVal);
  };

  const onChartClickVital = (e) => {
    const selected = e?.selected || {};
    const newVal = _vitalStackObject.reduce((p, item) => {
      return [...p, { ...item, isView: selected?.[item?.seriesName] }];
    }, []);
    newVal.unshift({
      seriesName: '計測時刻',
      data: 'measureTime',
      isView: true,
    });
    vitalStackObj_marker = newVal;
    setVitalStackObject(newVal);
  };

  const onChartClickOverlay = (e) => {
    const selected = e?.selected || {};
    const newVal = default_overlayStackObject.reduce((p, item) => {
      return [...p, { ...item, isView: selected?.[item?.seriesName] }];
    }, []);
    newVal.unshift({
      seriesName: '計測時刻',
      data: 'measureTime',
      isView: true,
    });
    overlayStackObj_marker = newVal;
    setOverlayStackObject(newVal);
  };

  const onChartClick = (params) => {
    if (params.componentType === 'series') {
      if (params.name === firstDate && secondDate)
        chartToolRef?.current.onClick2();
      if (params.name === firstDate && !secondDate)
        chartToolRef?.current.onClick();
      if (params.name === secondDate) chartToolRef?.current.onClick();
      if (params.name !== firstDate && params.name !== secondDate) {
        setGraphTooltipData({
          tooltipPosition: [params.event.event.zrX, params.event.event.zrY],
          lineDate: params.name,
        });
      }
      onMarkArea(params.name, null);
    }
  };
  const onMarkerZoom = (params) => {
    zoomStart = params.start;
    zoomEnd = params.end;
    setMarkerDate((markerDate) => {
      return {
        ...markerDate,
        zoomStart: params.start,
        zoomEnd: params.end,
      };
    });
  };

  const markerClear = () => {
    firstDate = markerDate.firstDate;
    secondDate = markerDate.secondDate;
    firstDate && markerDate.vitalMarkerDateMap.set(firstDate, 0);
    firstDate && markerDate.aerMarkerDateMap.set(firstDate, 0);
    firstDate && markerDate.overlayMarkerDateMap.set(firstDate, 0);
    secondDate && markerDate.vitalMarkerDateMap.set(secondDate, 0);
    secondDate && markerDate.aerMarkerDateMap.set(secondDate, 0);
    secondDate && markerDate.overlayMarkerDateMap.set(secondDate, 0);
    secondDate = '';
    firstDate = '';
    //外部
    setMarkerDate((markerDate) => {
      return {
        ...markerDate,
        secondDate: firstDate,
        firstDate: secondDate,
        vitalMarkerDateMap: markerDate.vitalMarkerDateMap,
        aerMarkerDateMap: markerDate.aerMarkerDateMap,
        overlayMarkerDateMap: markerDate.overlayMarkerDateMap,
      };
    });
    vitalMarkerArray = mapToArray(markerDate.vitalMarkerDateMap);
    aerMarkerArray = mapToArray(markerDate.aerMarkerDateMap);
    overlayMarkerArray = mapToArray(markerDate.overlayMarkerDateMap);
    if (lanceRef?.current) {
      const vitalOption = vitalStackOptions(
        realtimeListDataList,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        axis,
        vitalMarkerArray,
        vitalStackObject,
        state.isMeasureTime,
      );
      lanceRef.current.getEchartsInstance().setOption(vitalOption, true, true);
      lanceRef.current.getEchartsInstance().dispatchAction({
        type: 'dataZoom',
        start: markerDate.zoomStart,
        end: markerDate.zoomEnd,
      });
    }
    if (beWellRef?.current) {
      const aeroOption = aerobicStackOptions(
        realtimeListDataList,
        isLance,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        axis,
        aerMarkerArray,
        aerobicStackObject,
        chooseBeWellMeasureMent?.rehabilitationId,
        state?.isMeasureTime,
      );
      beWellRef.current.getEchartsInstance().setOption(aeroOption, true, true);
      beWellRef.current.getEchartsInstance().dispatchAction({
        type: 'dataZoom',
        start: markerDate.zoomStart,
        end: markerDate.zoomEnd,
      });
    }
    if (overlayRef?.current) {
      const overlayOption = overlayChartOptions(
        realtimeListDataList,
        isLance,
        selectVisible,
        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
        markerAxis,
        overlayMarkerArray,
        overlayStackObj_marker,
        chooseBeWellMeasureMent?.rehabilitationId,
        state?.isMeasureTime,
      );
      overlayRef.current
        .getEchartsInstance()
        .setOption(overlayOption, true, true);
      overlayRef.current.getEchartsInstance().dispatchAction({
        type: 'dataZoom',
        start: zoomStart,
        end: zoomEnd,
      });
    }
  };

  const onDisplayChange = () => {
    //stackのリセットも入れるべきか。
    aeroStackObj_marker = default_aerobicStackObject;
    setAerobicStackObject(default_aerobicStackObject);
    vitalStackObj_marker = _vitalStackObject;
    setVitalStackObject(_vitalStackObject);
    overlayStackObj_marker = default_overlayStackObject;
    setOverlayStackObject(default_overlayStackObject);
    const _axis = {
      lowAxisVital: undefined,
      maxAxisVital: undefined,
      lowAxisAero: undefined,
      maxAxisAero: undefined,
    };
    setAxis(_axis);
    markerAxis = _axis;
    chartToolRef?.current?.clearMarker();
    setGraphTooltipData({ tooltipPosition: [], lineDate: '' });
    markerClear();
    zoomStart = 0;
    zoomEnd = 100;
    dataZoomRef?.current?.getEchartsInstance()?.dispatchAction({
      type: 'dataZoom',
      start: 0,
      end: 100,
    });
    setMarkerDate((markerDate) => {
      return {
        ...markerDate,
        zoomStart: 0,
        zoomEnd: 100,
      };
    });
    form.resetFields();
    axisForm.resetFields();
  };

  const onClickMarkerClear = () => {
    chartToolRef?.current.clearMarker();
    setGraphTooltipData({ tooltipPosition: [] });
    markerClear();
  };

  const vitalCharts = useMemo(() => {
    return () => (
      <ReactECharts
        ref={lanceRef}
        style={{
          height: selectVisible?.beWell === 1 ? '200px' : '400px',
          //width: '100%',
        }}
        className='chartWidth'
        lazyUpdate
        onEvents={{
          legendselectchanged: onChartClickVital,
          click: onChartClick,
          datazoom: onMarkerZoom,
        }}
        option={vitalOption}
      />
    );
  }, [vitalOption, selectVisible]);

  const beWellCharts = useMemo(() => {
    return () => (
      <ReactECharts
        ref={beWellRef}
        style={{
          height:
            isLance === '1' && selectVisible?.smartWatch === 1
              ? '200px'
              : '400px',
          //width: '100%',
        }}
        className='chartWidth'
        lazyUpdate
        onEvents={{
          legendselectchanged: onChartClickAero,
          click: onChartClick,
          datazoom: onMarkerZoom,
        }}
        option={aeroOption}
      />
    );
  }, [aeroOption, selectVisible]);

  const overlayCharts = useMemo(() => {
    return () => (
      <ReactECharts
        ref={overlayRef}
        style={{
          height: '400px',
          //width: '100%',
        }}
        className='chartWidth'
        lazyUpdate
        onEvents={{
          legendselectchanged: onChartClickOverlay,
          click: onChartClick,
          datazoom: onMarkerZoom,
        }}
        option={overlayOption}
      />
    );
  }, [overlayOption, selectVisible]);

  const getDeviceName = () => {
    if (chooseBeWellMeasureMent?.rehabilitationId === 2) {
      return 'WE110';
    } else if (chooseBeWellMeasureMent?.rehabilitationId === 8) {
      return 'WE100';
    } else if (chooseBeWellMeasureMent?.rehabilitationId === 11) {
      return 'EM300';
    } else if (chooseBeWellMeasureMent?.rehabilitationId === 12) {
      return 'EM350';
    } else {
      return 'EM400';
    }
  };

  return (
    <div>
      <div ref={chartRef}>
        {/* マーカー機能の生成 */}
        <ChartTooltipMeasureDetail
          ref={chartToolRef}
          setGraphTooltipData={(e) =>
            setGraphTooltipData({ ...graphTooltipData, ...e })
          }
          graphTooltipData={graphTooltipData}
          dataList={realtimeListDataList}
          selectVisible={selectVisible}
          state={state}
          isLance={isLance}
          _vitalStackObject={vitalStackObject}
          _aerobicStackObject={aerobicStackObject}
          _overlayStackObject={overlayStackObject}
          overlayChart={overlayChart}
          measureDetail
          viewDate={viewDate}
          graphWidth={toolTipWidth}
          chooseBeWellMeasureMent={chooseBeWellMeasureMent}
          chooseLanceMeasureMent={chooseLanceMeasureMent}
        />
        {isLance === '1' && (
          <Title
            isLance={isLance}
            title={
              !overlayChart
                ? deviceNames.smartWatch
                : `${deviceNames.smartWatch}&${getDeviceName()}`
            }
            lanceMeasureList={lanceMeasureList}
            list
            setState={setState}
            state={state}
            overlayChart={overlayChart}
            setOverlayChart={setOverlayChart}
            onTakePhoto={() => onTakePhoto()}
            photoLoading={isLoading}
            measureDetail={measureDetail}
            onDisplayChange={onDisplayChange}
            onClickMarkerClear={onClickMarkerClear}
            markerClearDisable={!graphTooltipData?.tooltipPosition?.length}
            chooseLanceMeasureMent={chooseLanceMeasureMent}
            chooseBeWellMeasureMent={chooseBeWellMeasureMent}
            bewellDonglePickerDonePress={bewellDonglePickerDonePress}
            lancePickerDonePress={lancePickerDonePress}
            isPicker
          >
            {overlayChart && overlayCharts()}
            {!overlayChart &&
              selectVisible.smartWatch === 1 &&
              state.selectGraph === 1 &&
              vitalCharts()}
            {!overlayChart &&
              selectVisible.smartWatch === 1 &&
              state.selectGraph === 2 && (
                <ReactECharts
                  ref={lanceRef}
                  style={{
                    height: selectVisible?.beWell === 1 ? '200px' : '400px',
                    //width: '100%',
                  }}
                  className='chartWidth'
                  lazyUpdate
                  onEvents={{ legendselectchanged: onChartClickVital }}
                  option={vitalStackOptions(
                    realtimeListDataList,
                    selectVisible,
                    //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                    axis,
                    vitalMarkerArray,
                    vitalStackObject,
                    state.isMeasureTime,
                  )}
                />
              )}
            {!overlayChart &&
              selectVisible.smartWatch === 1 &&
              state.selectGraph === 3 && (
                <ReactECharts
                  ref={lanceRef}
                  style={{
                    height: selectVisible?.beWell === 1 ? '200px' : '400px',
                    //width: '100%',
                  }}
                  className='chartWidth'
                  lazyUpdate
                  onEvents={{ legendselectchanged: onChartClickVital }}
                  option={vitalStackOptions(
                    realtimeListDataList,
                    selectVisible,
                    //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                    axis,
                    vitalMarkerArray,
                    vitalStackObject,
                    state.isMeasureTime,
                  )}
                />
              )}
          </Title>
        )}
        {!overlayChart && (
          <Title
            isLance={isLance}
            title={getDeviceName()}
            list
            deviceWe
            overlayChart={overlayChart}
            setState={setState}
            state={state}
            onTakePhoto={() => onTakePhoto()}
            photoLoading={isLoading}
            measureDetail={measureDetail}
            onDisplayChange={onDisplayChange}
            onClickMarkerClear={onClickMarkerClear}
            markerClearDisable={!graphTooltipData?.tooltipPosition?.length}
            beWellAndDongleMeasureList={beWellAndDongleMeasureList}
            chooseLanceMeasureMent={chooseLanceMeasureMent}
            chooseBeWellMeasureMent={chooseBeWellMeasureMent}
            bewellDonglePickerDonePress={bewellDonglePickerDonePress}
            lancePickerDonePress={lancePickerDonePress}
            isPicker
          >
            {selectVisible?.beWell === 1 &&
              state?.selectGraph === 1 &&
              beWellCharts()}
            {selectVisible?.beWell === 1 && state?.selectGraph === 2 && (
              <ReactECharts
                ref={beWellRef}
                style={{
                  height:
                    isLance === '1' && selectVisible?.smartWatch === 1
                      ? '200px'
                      : '400px',
                  //width: '100%',
                }}
                className='chartWidth'
                lazyUpdate
                onEvents={{ legendselectchanged: onChartClickAero }}
                option={aerobicStackOptions(
                  realtimeListDataList,
                  isLance,
                  selectVisible,
                  //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                  axis,
                  aerMarkerArray,
                  aerobicStackObject,
                  chooseBeWellMeasureMent?.rehabilitationId,
                  state?.isMeasureTime,
                )}
              />
            )}
            {selectVisible?.beWell === 1 && state?.selectGraph === 3 && (
              <ReactECharts
                ref={beWellRef}
                style={{
                  height:
                    isLance && selectVisible?.smartWatch === 1
                      ? '200px'
                      : '400px',
                  //width: '100%',
                }}
                className='chartWidth'
                lazyUpdate
                onEvents={{ legendselectchanged: onChartClickAero }}
                option={aerobicStackOptions(
                  realtimeListDataList,
                  isLance,
                  selectVisible,
                  //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                  axis,
                  aerMarkerArray,
                  aerobicStackObject,
                  chooseBeWellMeasureMent?.rehabilitationId,
                  state?.isMeasureTime,
                )}
              />
            )}
          </Title>
        )}
      </div>
      <div className='chart-date'>
        <Row gutter={[15, 15]}>
          <Col>
            <Row align='middle' className='h-140'>
              <div className='chart-date-container'>
                <span>{state.selectGraph === 3 ? '指標' : '計測時刻'}</span>
                <div
                  className={`date-chart ${
                    graphTooltipData?.tooltipPosition.length ||
                    (isLance !== '1' && selectVisible?.beWell !== 1)
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <ReactECharts
                    ref={dataZoomRef}
                    className='chart'
                    style={{
                      height: '100%',
                      width: 700,
                    }}
                    lazyUpdate
                    option={chartZoomOptions(
                      realtimeListDataList,
                      state?.selectGraph,
                      measureDetail,
                      state?.isMeasureTime,
                    )}
                    onEvents={onEventsDataZoom}
                  />
                </div>
              </div>
            </Row>
          </Col>
          <Col md={8} sm={16} xs={24}>
            {!loading && (
              <>
                {isLance === '1' && (
                  <Form
                    name='axisForm'
                    form={form}
                    onFinish={(e) => {
                      const _axis = {
                        lowAxisVital: e.lowVital || undefined,
                        maxAxisVital: e.highVital || undefined,
                        lowAxisAero: axis?.lowAxisAero,
                        maxAxisAero: axis?.maxAxisAero,
                      };
                      setAxis(_axis);
                      markerAxis = _axis;
                      if (lanceRef?.current) {
                        const vitalAxisOption = vitalStackOptions(
                          realtimeListDataList,
                          selectVisible,
                          //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                          _axis,
                          vitalMarkerArray,
                          vitalStackObj_marker,
                          state.isMeasureTime,
                        );
                        lanceRef.current
                          .getEchartsInstance()
                          .setOption(vitalAxisOption, true, true);
                        lanceRef.current.getEchartsInstance().dispatchAction({
                          type: 'dataZoom',
                          start: zoomStart,
                          end: zoomEnd,
                        });
                      } else if (overlayRef?.current) {
                        const overlayAxisOption = overlayChartOptions(
                          realtimeListDataList,
                          isLance,
                          selectVisible,
                          //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                          _axis,
                          overlayMarkerArray,
                          overlayStackObj_marker,
                          chooseBeWellMeasureMent?.rehabilitationId,
                          state?.isMeasureTime,
                        );
                        overlayRef.current
                          .getEchartsInstance()
                          .setOption(overlayAxisOption, true, true);
                        overlayRef.current.getEchartsInstance().dispatchAction({
                          type: 'dataZoom',
                          start: zoomStart,
                          end: zoomEnd,
                        });
                      }
                    }}
                  >
                    <div className='input-container-isLance-false'>
                      <span className='input-title'>
                        スマートウォッチ
                        <br />
                        縦軸
                      </span>
                      <Form.Item
                        name='lowVital'
                        rules={[
                          {
                            required: false,
                            validator: (_, _value) => {
                              const value = parseInt(_value);
                              if (!_value?.length || !_value) {
                                return Promise.resolve();
                              }
                              if (
                                value >=
                                  parseInt(form.getFieldValue('highVital')) ||
                                value < 0
                              ) {
                                return Promise.reject(
                                  new Error('入力値が正しくありません。'),
                                );
                              }
                              // if (value >= highestValueVital) {
                              //   return Promise.reject(
                              //     new Error(
                              //       `${highestValueVital}より大きい数字が入力されています。`,
                              //     ),
                              //   );
                              // }
                              return Promise.resolve();
                            },
                            validateTrigger: 'onSubmit',
                          },
                          {
                            required: false,
                            pattern: new RegExp('[0-9]+$'),
                            message: 'フォーマットが正しくありません。',
                            validateTrigger: 'onSubmit',
                          },
                        ]}
                      >
                        <Input className='w-80' size='large' />
                      </Form.Item>
                      <div className='line'>〜</div>
                      <Form.Item
                        name='highVital'
                        rules={[
                          {
                            required: false,
                            validator: (_, _value) => {
                              const value = parseInt(_value);
                              if (!_value?.length || !_value) {
                                return Promise.resolve();
                              }
                              if (
                                value <=
                                  parseInt(form.getFieldValue('lowVital')) ||
                                value <= 0
                              ) {
                                return Promise.reject(
                                  new Error('入力値が正しくありません。'),
                                );
                              }
                              // if (value > highestValueVital) {
                              //   return Promise.reject(
                              //     new Error(
                              //       `${highestValueVital}より大きい数字が入力されています。`,
                              //     ),
                              //   );
                              // }
                              return Promise.resolve();
                            },
                            validateTrigger: 'onSubmit',
                          },
                          {
                            required: false,
                            pattern: new RegExp('[0-9]+$'),
                            message: 'フォーマットが正しくありません。',
                            validateTrigger: 'onSubmit',
                          },
                        ]}
                      >
                        <Input className='w-80' size='large' />
                      </Form.Item>
                      <Button type='primary' size='large' htmlType='submit'>
                        更新
                      </Button>
                    </div>
                  </Form>
                )}
                <Form
                  name='axisForm'
                  form={axisForm}
                  onFinish={(e) => {
                    const _axis = {
                      lowAxisVital: axis.lowAxisVital,
                      maxAxisVital: axis.maxAxisVital,
                      lowAxisAero: e.lowAero || undefined,
                      maxAxisAero: e.highAero || undefined,
                    };
                    setAxis(_axis);
                    markerAxis = _axis;
                    if (beWellRef?.current) {
                      const aeroAxisOption = aerobicStackOptions(
                        realtimeListDataList,
                        isLance,
                        selectVisible,
                        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                        _axis,
                        aerMarkerArray,
                        aeroStackObj_marker,
                        chooseBeWellMeasureMent?.rehabilitationId,
                        state?.isMeasureTime,
                      );
                      beWellRef.current
                        .getEchartsInstance()
                        .setOption(aeroAxisOption, true, true);
                      beWellRef.current.getEchartsInstance().dispatchAction({
                        type: 'dataZoom',
                        start: zoomStart,
                        end: zoomEnd,
                      });
                    } else if (overlayRef?.current) {
                      const overlayAxisOption = overlayChartOptions(
                        realtimeListDataList,
                        isLance,
                        selectVisible,
                        //(e) => setGraphTooltipData({ ...graphTooltipData, ...e }),
                        _axis,
                        overlayMarkerArray,
                        overlayStackObj_marker,
                        chooseBeWellMeasureMent?.rehabilitationId,
                        state?.isMeasureTime,
                      );
                      overlayRef.current
                        .getEchartsInstance()
                        .setOption(overlayAxisOption, true, true);
                      overlayRef.current.getEchartsInstance().dispatchAction({
                        type: 'dataZoom',
                        start: zoomStart,
                        end: zoomEnd,
                      });
                    }
                  }}
                >
                  <div
                    className={
                      isLance !== '1'
                        ? 'input-container-isLance-true end'
                        : 'input-container-isLance-false end'
                    }
                  >
                    <span className='input-title'>
                      {chooseBeWellMeasureMent?.rehabilitationId === 2 ||
                      chooseBeWellMeasureMent?.rehabilitationId === 8
                        ? 'BE-Well'
                        : null}
                      {chooseBeWellMeasureMent?.rehabilitationId === 11 ||
                      chooseBeWellMeasureMent?.rehabilitationId === 12 ||
                      chooseBeWellMeasureMent?.rehabilitationId === 13
                        ? 'メデルゴ'
                        : null}
                      <br />
                      縦軸
                    </span>
                    <Form.Item
                      name='lowAero'
                      rules={[
                        {
                          required: false,
                          validator: (_, _value) => {
                            const value = parseInt(_value);
                            if (!_value?.length) {
                              return Promise.resolve();
                            }

                            if (
                              value >=
                                parseInt(axisForm.getFieldValue('highAero')) ||
                              value < 0
                            ) {
                              return Promise.reject(
                                new Error('入力値が正しくありません。'),
                              );
                            }
                            // if (value >= highestValueAero) {
                            //   return Promise.reject(
                            //     new Error(
                            //       `${highestValueAero}より大きい数字が入力されています。`,
                            //     ),
                            //   );
                            // }
                            return Promise.resolve();
                          },
                          validateTrigger: 'onSubmit',
                        },
                        {
                          required: false,
                          pattern: new RegExp('[0-9]+$'),
                          message: 'フォーマットが正しくありません。',
                          validateTrigger: 'onSubmit',
                        },
                      ]}
                    >
                      <Input className='w-80' size='large' />
                    </Form.Item>
                    <div className='line'>〜</div>
                    <Form.Item
                      name='highAero'
                      rules={[
                        {
                          required: false,
                          validator: (_, _value) => {
                            const value = parseInt(_value);
                            if (!_value?.length) {
                              return Promise.resolve();
                            }
                            if (
                              value <=
                                parseInt(axisForm.getFieldValue('lowAero')) ||
                              value <= 0
                            ) {
                              return Promise.reject(
                                new Error('入力値が正しくありません。'),
                              );
                            }
                            // if (value > highestValueAero) {
                            //   return Promise.reject(
                            //     new Error(
                            //       `${highestValueAero}より大きい数字が入力されています。`,
                            //     ),
                            //   );
                            // }
                            return Promise.resolve();
                          },
                          validateTrigger: 'onSubmit',
                        },
                        {
                          required: false,
                          pattern: new RegExp(`[0-9]+$`),
                          message: 'フォーマットが正しくありません。',
                          validateTrigger: 'onSubmit',
                        },
                      ]}
                    >
                      <Input className='w-80' size='large' />
                    </Form.Item>
                    <Button type='primary' size='large' htmlType='submit'>
                      更新
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

// make this component available to the app
export default Chart;
