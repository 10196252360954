import React from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';

import reportWebVitals from './reportWebVitals';
import App from './App';
import jaJP from 'antd/lib/locale/ja_JP';
import { StableNavigateContextProvider } from '@app/providers/stableNavigate';

// ReactDOM.render(
//   <React.StrictMode>
//     <ConfigProvider>
//       <App />
//     </ConfigProvider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

render(
  <BrowserRouter>
    <StableNavigateContextProvider>
      <CookiesProvider>
        <ConfigProvider locale={{ ...jaJP }}>
          <App />
        </ConfigProvider>
      </CookiesProvider>
    </StableNavigateContextProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}

ConfigProvider.config({
  theme: {
    primaryColor: '#0055A1',
    borderRadiusBase: 4,
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
