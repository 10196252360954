import React from 'react';

import '@app/styles/index.scss';
import 'antd/dist/antd.variable.css';

import { RouteManager } from '@app/routes';
import { AuthProvider } from '@app/providers/authProvider';
import { Provider } from '@app/utils/contextManager';

const App = () => {
  return (
    <AuthProvider>
      <Provider>
        <RouteManager />
      </Provider>
    </AuthProvider>
  );
};

export default App;
