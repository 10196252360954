// import libraries
import React, { useState } from 'react';
import { Input, DatePicker, Button, Select } from 'antd';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

// import utils
import { prefectures } from '@app/utils/static';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ContractListFilter = ({ onSearch = () => {} }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterState, setFilterState] = useState({
    contractFacilityName: searchParams.get('contractFacilityName'),
    prefectureId: searchParams.get('prefectureId'),
    accountCreateFrom: searchParams.get('accountCreateFrom')
      ? moment(new Date(searchParams.get('accountCreateFrom')))
      : null,
    accountCreateTo: searchParams.get('accountCreateTo')
      ? moment(new Date(searchParams.get('accountCreateTo')))
      : null,
    lastLoginFrom: searchParams.get('lastLoginFrom')
      ? moment(new Date(searchParams.get('lastLoginFrom')))
      : null,
    lastLoginTo: searchParams.get('lastLoginTo')
      ? moment(new Date(searchParams.get('lastLoginTo')))
      : null,
    tel: searchParams.get('tel'),
  });

  const {
    contractFacilityName,
    prefectureId,
    accountCreateFrom,
    accountCreateTo,
    lastLoginFrom,
    lastLoginTo,
    tel,
  } = filterState;

  return (
    <div className='user-list-filter'>
      <div className='filter-container'>
        <div className='filter-item'>
          <span className='title'>施設名</span>
          <Input
            placeholder='施設名'
            defaultValue={contractFacilityName}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                contractFacilityName: e.target.value,
              });
            }}
          />
        </div>
        <div className='filter-item'>
          <span className='title'>都道府県</span>
          <Select
            placeholder='選択する'
            onChange={(e) => {
              setFilterState({ ...filterState, prefectureId: e });
            }}
            defaultValue={prefectureId || null}
          >
            {(prefectures || [])?.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className='filter-item'>
          <span className='title'>アカウント作成日時</span>
          <RangePicker
            onCalendarChange={(val) => {
              setFilterState({
                ...filterState,
                accountCreateFrom: val?.[0] || null,
                accountCreateTo: val?.[1] || null,
              });
            }}
            defaultValue={[accountCreateFrom, accountCreateTo]}
          />
        </div>
        <div className='filter-item'>
          <span className='title'>最終ログイン</span>
          <RangePicker
            onCalendarChange={(val) => {
              setFilterState({
                ...filterState,
                lastLoginFrom: val?.[0] || null,
                lastLoginTo: val?.[1] || null,
              });
            }}
            defaultValue={[lastLoginFrom, lastLoginTo]}
          />
        </div>
        <div className='filter-item'>
          <span className='title'>電話番号</span>
          <Input
            placeholder='電話番号'
            defaultValue={tel}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                tel: e.target.value,
              });
            }}
          />
        </div>
        <Button
          type='primary'
          className='filter-button'
          onClick={() => {
            onSearch();
            let test = {};
            Object.keys(filterState).forEach((key) => {
              if (filterState[key] !== '' && filterState[key] !== null) {
                test = { ...test, [key]: filterState[key] };
              }
            });
            setSearchParams({
              ...test,
            });
          }}
        >
          検索
        </Button>
      </div>
    </div>
  );
};

export default ContractListFilter;
