const SummaryInfoDetailItem = () => {
  return [
    {
      title: '累計受付回数',
      key: '1',
      dataIndex: 'cumReceptCnt',
    },
    {
      title: '累計リアルタイム計測回数',
      key: '2',
      dataIndex: 'cumRealtimeCnt',
    },
    {
      title: '累計リアルタイム計測時間',
      key: '3',
      dataIndex: 'cumRealtimeMeasure',
    },
    {
      title: 'WE-100計測回数',
      key: '4',
      dataIndex: 'we100Cnt',
    },
    {
      title: 'WE-110計測回数',
      key: '5',
      dataIndex: 'we110Cnt',
    },
    {
      title: 'EM-300計測回数',
      key: '6',
      dataIndex: 'em300Cnt',
    },
    {
      title: 'EM-350計測回数',
      key: '7',
      dataIndex: 'em350Cnt',
    },
    {
      title: 'EM-400計測回数',
      key: '8',
      dataIndex: 'em400Cnt',
    },
  ];
};

export default SummaryInfoDetailItem;
