// import libraries
import React from 'react';
import { Button } from 'antd';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Routes } from '@app/routes/routes';

// create a component
const SummaryInfoItem = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'NO.',
      key: '1',
      dataIndex: 'no',
    },
    {
      title: '施設名',
      key: '2',

      render: (record) => {
        return (
          <Button
            type='text'
            onClick={() =>
              navigate({
                pathname: Routes.Home.ContractorDetail.path,
                search: `?${createSearchParams({
                  contractId: record?.contractId,
                })}`,
              })
            }
          >
            {record?.contractFacilityName}
          </Button>
        );
      },
    },
    {
      title: '累計受付回数',
      key: '3',
      dataIndex: 'cumReceptCnt',
      sorter: (a, b) => a?.cumReceptCnt - b?.cumReceptCnt,
    },
    {
      title: '累計リアルタイム計測回数',
      key: '4',
      dataIndex: 'cumRealtimeCnt',
      sorter: (a, b) => a?.cumRealtimeCnt - b?.cumRealtimeCnt,
    },
    {
      title: '累計リアルタイム計測時間',
      key: '5',
      dataIndex: 'cumRealtimeMeasure',
      sorter: (a, b) =>
        a?.cumRealtimeMeasure?.localeCompare(b?.cumRealtimeMeasure, 'ja'),
    },
    {
      title: 'WE-100計測回数',
      key: '6',
      dataIndex: 'we100Cnt',
      sorter: (a, b) => a?.we100Cnt - b?.we100Cnt,
    },
    {
      title: 'WE-110計測回数',
      key: '7',
      dataIndex: 'we110Cnt',
      sorter: (a, b) => a?.we110Cnt - b?.we110Cnt,
    },
    {
      title: 'EM-300計測回数',
      key: '8',
      dataIndex: 'em300Cnt',
      sorter: (a, b) => a?.em300Cnt - b?.em300Cnt,
    },
    {
      title: 'EM-350計測回数',
      key: '9',
      dataIndex: 'em350Cnt',
      sorter: (a, b) => a?.em350Cnt - b?.em350Cnt,
    },
    {
      title: 'EM-400計測回数',
      key: '10',
      dataIndex: 'em400Cnt',
      sorter: (a, b) => a?.em400Cnt - b?.em400Cnt,
    },
  ];
};

// make this component available to the app
export default SummaryInfoItem;
