// import libraries
import React from 'react';
import {
  CloseCircleOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Rnd } from 'react-rnd';

const ThumbnailListModal = ({
  date,
  mediaList = [],
  pageY = 200,
  setThumbnailModalData,
  setmediaModalData,
  visibleMediaList,
}) => {
  return (
    <Rnd
      default={{
        x: 300,
        y: pageY,
        width: 630,
        height:
          mediaList.length <= 10
            ? 500
            : 500 + 180 * ((mediaList.length - 10) % 5),
      }}
      minWidth={630}
      minHeight={
        mediaList.length <= 10 ? 500 : 500 + 180 * ((mediaList.length - 10) % 5)
      } //10異常の場合は5マイ当たりで180ずつ増やす
      bounds='window'
      className='thumbnail-container'
    >
      <div className='title'>
        <span className='date-txt'>{date}</span>
        <CloseCircleOutlined
          className='close-btn'
          style={{ color: 'red', fontSize: '25px' }}
          onClick={() => {
            setThumbnailModalData(0, [], '');
          }}
        />
      </div>
      <hr
        style={{
          color: 'black',
          backgroundColor: 'black',
          height: 3,
          marginBottom: 0,
          paddingBottom: 0,
        }}
      />
      <div className='img-set-container'>
        {mediaList.map((value, index, array) => {
          return (
            <div className='img-set' key={value?.mediaMemoId}>
              <span
                onClick={(e) => {
                  let isNew = true;
                  visibleMediaList.map((val) => {
                    if (val.mediaMemoId === value?.mediaMemoId) {
                      isNew = false;
                    }
                    return val;
                  });
                  if (isNew) {
                    const mediaData = {
                      url: value?.mediaMemoUrl,
                      mediaMemoId: value?.mediaMemoId,
                      date: date,
                      pageY: e.pageY,
                    };
                    setmediaModalData([...visibleMediaList, mediaData]);
                  }
                }}
              >
                <img
                  src={value?.thumbnailUrl}
                  draggable='false'
                  alt='github avatar'
                  className='humbnail-img'
                />
              </span>
              <div className='icon-set'>
                <DownloadOutlined
                  className='download-btn'
                  style={{ color: 'blue', fontSize: '25px' }}
                />
                <DeleteOutlined
                  className='delete-btn'
                  style={{ color: 'gray', fontSize: '25px' }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Rnd>
  );
};

// make this component available to the app
export default ThumbnailListModal;
