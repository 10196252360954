import { Table as AntTable } from 'antd';

const Table = ({
  visible = true,
  columns = [],
  dataSource = [],
  loading = false,
  rowSelection = {},
  ...props
}) => {
  return (
    <div className='table-container'>
      {visible && (
        <AntTable
          size='small'
          bordered
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowSelection={rowSelection}
          {...props}
          scroll={{ x: 'max-content' }}
        />
      )}
    </div>
  );
};

export default Table;
