import { dataFormatterTime } from '@app/utils/commonFunctions';
import moment from 'moment';

const selectedDefault = {
  脈拍: true,
  酸素: true,
  収縮圧: true,
  拡張圧: true,
  'BE-Well脈拍': true,
  ワット: true,
  メッツ: true,
  ペダル負荷: true,
  '歩数/回転数': true,
  '累積歩数/回転数': true,
  消費カロリー: true,
  距離: true,
};
const selectedDefaultMederugo = {
  脈拍: true,
  酸素: true,
  収縮圧: true,
  拡張圧: true,
  メデルゴ脈拍: true,
  ワット: true,
  ペダル回転数: true,
  ペダルトルク: true,
  消費カロリー: true,
};

export const overlayChartOptions = (
  _realtimeListDataList,
  selectVisible,
  isLance,
  //setGraphTooltipData,
  axis,
  markerArray,
  stackObject,
  rehabilitationId,
  isMeasureTime,
) => {
  const dataVital = dataFormatterTime(_realtimeListDataList, 'isVitalPlot');
  const dataAero = dataFormatterTime(_realtimeListDataList, 'isAerobicPlot');

  const selected = {
    ...stackObject?.reduce((p, item) => {
      return { ...p, [item.seriesName]: item?.isView };
    }, {}),
  };
  return lineGraph(
    dataVital,
    dataAero,
    isLance,
    selectVisible,
    //setGraphTooltipData,
    axis,
    stackObject
      ? selected
      : rehabilitationId === 2 || rehabilitationId === 8
      ? selectedDefault
      : selectedDefaultMederugo,
    markerArray,
    rehabilitationId,
    isMeasureTime,
  );
};

const lineGraph = (
  dataVital,
  dataAero,
  isLance,
  selectVisible,
  //setGraphTooltipData,
  axis,
  selected,
  markerArray,
  rehabilitationId,
  isMeasureTime,
) => {
  if (rehabilitationId === 2 || rehabilitationId === 8) {
    return {
      legend: {
        data: [
          '脈拍',
          '酸素',
          '収縮圧',
          '拡張圧',
          'BE-Well脈拍',
          'ワット',
          'メッツ',
          'ペダル負荷',
          '歩数/回転数',
          '累積歩数/回転数',
          '消費カロリー',
          '距離',
        ],
        icon: 'circle',
        top: '90%',
        left: 25,
        selected: { ...selected },
      },
      xAxis: [
        {
          type: 'category',
          data: dataVital?.measureTime || dataAero?.measureTime || [],
          axisLabel: {
            formatter: function (val, index) {
              return dataVital?.measureTime.map((e) =>
                moment(e, 'HH:mm:ss').format('HH:mm:ss'),
              )[index];
            },
          },
        },
        {
          type: 'category',
          data: dataVital?.measureTime || dataAero?.measureTime,
        },
      ],
      yAxis: [
        {
          type: 'value',
          scale: true,
          position: 'left',
          max: axis?.maxAxisVital,
          min: axis?.lowAxisVital,
        },
        {
          type: 'value',
          scale: true,
          max: axis?.maxAxisAero,
          min: axis?.lowAxisAero,
          position: 'right',
        },
      ],
      grid: [
        {
          top: 5,
          bottom: 60,
          left: 50,
        },
      ],
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          barWidth: 2,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ml';
            },
          },
          data: markerArray,
        },
        {
          name: '脈拍',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.pulse,
        },
        {
          name: '酸素',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.oxygen,
        },
        {
          name: '収縮圧',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.sbp,
        },
        {
          name: '拡張圧',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.dbp,
        },

        {
          name: 'BE-Well脈拍',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.bewellPulse,
        },
        {
          name: 'ワット',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.watt,
        },
        {
          name: 'メッツ',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.mets,
        },
        {
          name: 'ペダル負荷',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.loadLevel,
        },
        {
          name: '歩数/回転数',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.step_rotation,
        },
        {
          name: '累積歩数/回転数',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.cumulative,
        },
        {
          name: '消費カロリー',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.cal,
        },
        {
          name: '距離',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.distance,
        },
      ],
    };
  } else {
    return {
      legend: {
        data: [
          '脈拍',
          '酸素',
          '収縮圧',
          '拡張圧',
          'メデルゴ脈拍',
          'ワット',
          'ペダル負荷',
          'ペダル回転数',
          'ペダルトルク',
        ],
        icon: 'circle',
        top: '90%',
        left: 25,
        selected: { ...selected },
      },
      xAxis: [
        {
          type: 'category',
          data: dataVital?.measureTime || dataAero?.measureTime || [],
          axisLabel: {
            formatter: function (val, index) {
              return dataVital?.measureTime.map((e) =>
                moment(e, 'HH:mm:ss').format('HH:mm:ss'),
              )[index];
            },
          },
        },
        {
          type: 'category',
          data: dataVital?.measureTime || dataAero?.measureTime,
          // inverse: true,
        },
      ],
      yAxis: [
        {
          type: 'value',
          scale: true,
          position: 'left',
          max: axis?.maxAxisVital,
          min: axis?.lowAxisVital,
        },
        {
          type: 'value',
          scale: true,
          max: axis?.maxAxisAero,
          min: axis?.lowAxisAero,
          position: 'right',
        },
      ],
      grid: [
        {
          top: 5,
          bottom: 60,
          left: 50,
        },
      ],
      dataZoom: {
        show: false,
        type: 'slider',
      },
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          barWidth: 2,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ml';
            },
          },
          data: markerArray,
        },
        {
          name: '脈拍',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.pulse,
        },
        {
          name: '酸素',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.oxygen,
        },
        {
          name: '収縮圧',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.sbp,
        },
        {
          name: '拡張圧',
          type: 'line',
          showAllSymbol: true,
          data: dataVital?.dbp,
        },

        {
          name: 'メデルゴ脈拍',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.bewellPulse,
          color: '#F73131',
        },
        {
          name: 'ワット',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.watt,
          color: '#FDB01D',
        },
        {
          name: 'ペダル回転数',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.step_rotation,
          color: '#5EB167',
        },
        {
          name: 'ペダルトルク',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.pedalTorque,
          color: '#1399CB',
        },
        {
          name: '消費カロリー',
          type: 'line',
          showAllSymbol: true,
          yAxisIndex: 1,
          xAxisIndex: 1,
          data: dataAero?.cal,
          color: '#D36BB3',
        },
      ],
    };
  }
};
