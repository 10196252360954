/* eslint-disable no-unused-vars */
// import libraries
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Form } from 'antd';
import crypto from 'crypto-js';
import { RightCircleFilled } from '@ant-design/icons/lib/icons';

// import utils
import { showErrorDialogs } from '@app/utils/errorHandler';
import { loginAsync } from '@app/services/auth/auth.service';

const { useForm } = Form;
// create a component
const LoginScene = () => {
  const [form] = useForm();
  const [state, setState] = useState({ isLoading: false, errorText: false });
  const { isLoading, errorText } = state;
  useEffect(() => {
    const initialValues = JSON.parse(
      localStorage.getItem('credentials')
        ? crypto.AES.decrypt(
            localStorage.getItem('credentials'),
            'datalink_credentials_secret',
          ).toString(crypto.enc.Utf8) || '{}'
        : '{}',
    );
    form.setFieldsValue(initialValues);
  }, []);

  const onSubmit = async ({ remember, ...values }) => {
    setState({ ...state, isLoading: true });
    try {
      if (remember) {
        localStorage.setItem(
          'credentials',
          crypto.AES.encrypt(
            JSON.stringify({ ...values, remember }),
            'datalink_credentials_secret',
          ).toString(),
        );
      } else localStorage.removeItem('credentials');
      // login id, staff id-d ni tohirson datag api aas call hiij avch irne
      const loginResult = await loginAsync(values);
      if (loginResult?.success === 'failed') {
        setState({ ...state, isLoading: false });
        if (loginResult?.code === 'A001-001') {
          setState({ ...state, errorText: true });
        } else {
          showErrorDialogs({
            message: `${loginResult?.code}: ${loginResult?.messege}`,
          });
        }
      }
      if (loginResult?.success === 'success') {
        setState({ ...state, isLoading: false });
        window.location.reload();
      }
    } catch (error) {
      setState({ ...state, isLoading: false });
      console.log(error);
      if (error?.response) {
        showErrorDialogs({ isNeworkError: true });
      } else {
        showErrorDialogs({ isNeworkError: true });
      }
    }
  };

  return (
    <div className='login'>
      <div className='login-container'>
        <img
          className='logo'
          src='/assets/images/logos/OG-data-link-logo.png'
          alt='Logo'
        />
        <Form
          name='loginForm'
          form={form}
          onFinish={onSubmit}
          labelCol={{ flex: '100px' }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            remember: false,
          }}
        >
          <Form.Item
            name='loginContractId'
            label='契約者ID'
            rules={[{ required: true, message: '契約者IDを入力してください' }]}
          >
            <Input
              size='large'
              onChange={() => setState({ ...state, errorText: false })}
            />
          </Form.Item>
          <Form.Item
            name='loginUserId'
            label='スタッフID'
            rules={[
              { required: true, message: 'スタッフIDを入力してください' },
            ]}
          >
            <Input
              size='large'
              onChange={() => setState({ ...state, errorText: false })}
            />
          </Form.Item>
          <Form.Item
            name='password'
            label='パスワード'
            rules={[
              { required: true, message: 'パスワードを入力してください' },
            ]}
          >
            <Input.Password
              size='large'
              onChange={() => setState({ ...state, errorText: false })}
            />
          </Form.Item>
          <Form.Item
            name='remember'
            valuePropName='checked'
            className='remember'
          >
            <Checkbox>ログイン情報を端末に保存する</Checkbox>
          </Form.Item>
          {errorText && (
            <div className='errorText'>
              <span>
                契約者ID/スタッフID/パスワードの組み合わせが不正です。
              </span>
            </div>
          )}
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              className='button'
              loading={isLoading}
            >
              ログイン <RightCircleFilled />
            </Button>
          </Form.Item>
          <div className='under-buttons'>
            <Button
              type='link'
              href='https://www.og-wellness.jp/app'
              target='_blank'
            >
              初めてご利用の方
            </Button>
            <Button
              type='link'
              href='https://www.og-wellness.jp/contents/app/resetpass/'
              target='_blank'
            >
              パスワードが分からない
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

// make this component available to the app
export default LoginScene;
