// import libraries
import React, { useEffect, useState } from 'react';
import { Input, DatePicker, Button, Select } from 'antd';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

// import utils
import { getClassList } from '@app/services/enum.service';
import { genderJapName } from '@app/utils/static';

const { RangePicker } = DatePicker;
const { Option } = Select;

// create a component
export const PatientListFilter = ({ onSearch = () => {} }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [classDataOptions, setClassDataOptions] = useState([]);

  const [filterState, setFilterState] = useState({
    dataNo: searchParams.get('dataNo'),
    carteNo: searchParams.get('carteNo'),
    patientName: searchParams.get('patientName'),
    birthdayFrom: searchParams.get('birthdayFrom')
      ? moment(new Date(searchParams.get('birthdayFrom')))
      : null,
    birthdayTo: searchParams.get('birthdayTo')
      ? moment(new Date(searchParams.get('birthdayTo')))
      : null,
    receptDateFrom: searchParams.get('receptDateFrom')
      ? moment(new Date(searchParams.get('receptDateFrom')))
      : null,
    receptDateTo: searchParams.get('receptDateTo')
      ? moment(new Date(searchParams.get('receptDateTo')))
      : null,
    gender: searchParams.get('gender'),
    staffName: searchParams.get('staffName'),
    classId: searchParams.get('classId'),
  });

  const {
    carteNo,
    patientName,
    birthdayFrom,
    birthdayTo,
    receptDateFrom,
    receptDateTo,
    staffName,
    gender,
    classId,
  } = filterState;

  useEffect(() => {
    if (!classDataOptions.length) {
      const _getClassData = async () => {
        const classList = await getClassList();
        // APIからのClassに「指定なし」の追加
        classList.data.classList.unshift({
          classId: 0,
          className: '指定なし',
        });
        setClassDataOptions(classList.data.classList);
      };
      _getClassData();
    }
  }, []);

  return (
    <div className='user-list-filter'>
      <div className='filter-container'>
        <div className='filter-item'>
          <span className='title'>カルテID</span>
          <Input
            placeholder='カルテID'
            defaultValue={carteNo}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                carteNo: e.target.value,
              });
            }}
          />
        </div>
        <div className='filter-item'>
          <span className='title'>氏名</span>
          <Input
            placeholder='氏名もしくは氏名カナ'
            defaultValue={patientName}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                patientName: e.target.value,
              });
            }}
          />
        </div>
        <div className='filter-item'>
          <span className='title'>生年月日</span>
          <RangePicker
            onCalendarChange={(val) => {
              setFilterState({
                ...filterState,
                birthdayFrom: val?.[0] || null,
                birthdayTo: val?.[1] || null,
              });
            }}
            defaultValue={[birthdayFrom, birthdayTo]}
          />
        </div>
        <div className='filter-item'>
          <span className='title'>性別</span>
          <Select
            placeholder='選択してください。'
            onChange={(e) => {
              setFilterState({ ...filterState, gender: e });
            }}
            defaultValue={gender ? +gender : null}
          >
            {genderJapName.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className='filter-item'>
          <span className='title'>類別</span>
          <Select
            placeholder='選択してください。'
            onChange={(e) => {
              return setFilterState({
                ...filterState,
                classId: e,
              });
            }}
            defaultValue={classId ? +classId : null}
          >
            {classDataOptions.map((e) => (
              <Option key={e.classId} value={e.classId}>
                {e.className}
              </Option>
            ))}
          </Select>
        </div>
        <div className='filter-item'>
          <span className='title'>受付日</span>
          <RangePicker
            onCalendarChange={(val) => {
              setFilterState({
                ...filterState,
                receptDateFrom: val?.[0] || null,
                receptDateTo: val?.[1] || null,
              });
            }}
            defaultValue={[receptDateFrom, receptDateTo]}
          />
        </div>
        <div className='filter-item '>
          <span className='title'>担当スタッフ</span>
          <Input
            placeholder='担当スタッフ'
            defaultValue={staffName}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                staffName: e.target.value,
              });
            }}
          />
        </div>
        <Button
          type='primary'
          className='filter-button'
          onClick={() => {
            onSearch();
            let test = {};
            Object.keys(filterState).forEach((key) => {
              if (filterState[key] !== '' && filterState[key] !== null) {
                test = { ...test, [key]: filterState[key] };
              }
            });
            let facility = {};
            if (searchParams.get('facilityId')) {
              facility = { facilityId: searchParams.get('facilityId') };
            }
            setSearchParams({
              ...facility,
              ...test,
            });
          }}
        >
          検索
        </Button>
      </div>
    </div>
  );
};
