/* eslint-disable no-unused-vars */
import axios from '@app/config/axios';

export const getUser = () => {
  return new Promise((resolve, reject) => {
    axios
      .post('sessioninfo', '', {
        withCredentials: true,
      })
      .then(function ({ data }) {
        if (data?.success === 'success') {
          resolve(data?.data);
        } else {
          resolve({});
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const loginAsync = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('login', JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const logoutAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .post('logout', '', {
        withCredentials: true,
      })
      .then(function ({ data }) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
