/* eslint-disable no-unused-vars */
import axios from '@app/config/axios';
export const getCsvFromAPI = (API_URL, values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL, JSON.stringify(values), {
        withCredentials: true,
      })
      .then(function ({ data }) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
