// import { getPatientListCsv } from '@app/services/user/csvPatientList';
import { getCsvFromAPI } from '@app/services/user/csvApi.service';
import moment from 'moment';
import { showErrorDialogs } from '@app/utils/errorHandler';

export const downloadCsvFromAPI = (apiPath, userFacility, fileName, _data) => {
  return new Promise(async (resolve, reject) => {
    try {
      var a = document.createElement('a');
      const data = await getCsvFromAPI(apiPath, {
        facilityId: userFacility.facilityId,
        ..._data,
        plot: 1,
      });
      if (data?.code === 'A001-001') {
        reject('A001-001');
        return;
      }
      let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `OG-DATALINK-${fileName}-${moment().format(
        'YYYY-MM-DD-hh-mm-ss',
      )}.csv`;
      a.click();
      resolve(true);
    } catch (error) {
      showErrorDialogs({
        message: 'CSVファイルのダウンロードに失敗しました。',
      });
      reject(error);
    }
  });
};
export const convertLabelBarChartData = (_data, itemName) => {
  const item = {};
  if (_data?.measureDate?.length >= 0) {
    const dates = _data.measureDate;
    for (let i = 0; i < dates?.length; i += 1) {
      const e = dates[i];
      if (itemName === 'exerciseTime' && _data?.exerciseTime[i]) {
        const hours = _data?.exerciseTime[i]?.split(':');
        if (hours?.length === 2) {
          const stringTime = hours[0] + '.' + hours[1];
          item[e] = parseFloat(stringTime);
        }
      } else if (itemName === 'cumulative' && _data?.cumulative[i]) {
        const cumulatives = _data?.cumulative;
        item[e] = cumulatives[i];
      } else if (itemName === 'cal' && _data?.cal[i]) {
        const cals = _data?.cal;
        item[e] = cals[i];
      } else if (itemName === 'distance' && _data?.distance[i]) {
        const distances = _data?.distance;
        item[e] = distances[i];
      } else if (itemName === 'pulse' && _data?.pulse[i]) {
        const pulses = _data?.pulse;
        item[e] = pulses[i];
      } else if (itemName === 'oxygen' && _data?.oxygen[i]) {
        const oxygens = _data?.oxygen;
        item[e] = oxygens[i];
      } else if (itemName === 'sbp' && _data?.sbp[i]) {
        const sbps = _data?.sbp;
        item[e] = sbps[i];
      } else if (itemName === 'dbp' && _data?.dbp[i]) {
        const dbps = _data?.dbp;
        item[e] = dbps[i];
      } else if (itemName === 'pulseF' && _data?.pulseF[i]) {
        const pulseFs = _data?.pulseF;
        item[e] = pulseFs[i];
      } else if (itemName === 'oxygenF' && _data?.oxygenF[i]) {
        const oxygenFs = _data?.oxygenF;
        item[e] = oxygenFs[i];
      } else if (itemName === 'sbpF' && _data?.sbpF[i]) {
        const sbpFs = _data?.sbpF;
        item[e] = sbpFs[i];
      } else if (itemName === 'dbpF' && _data?.dbpF[i]) {
        const dbpFs = _data?.dbpF;
        item[e] = dbpFs[i];
      } else if (itemName === 'pulseB' && _data?.pulseB[i]) {
        const pulseBs = _data?.pulseB;
        item[e] = pulseBs[i];
      } else if (itemName === 'oxygenB' && _data?.oxygenB[i]) {
        const oxygenBs = _data?.oxygenB;
        item[e] = oxygenBs[i];
      } else if (itemName === 'sbpB' && _data?.sbpB[i]) {
        const sbpBs = _data?.sbpB;
        item[e] = sbpBs[i];
      } else if (itemName === 'dbpB' && _data?.dbpB[i]) {
        const dbpBs = _data?.dbpB;
        item[e] = dbpBs[i];
      }
    }
  }
  return item;
};

export const convertExerciseTime = (_data) => {
  const exerciseTimes = [];
  for (let i = 0; i < _data?.exerciseTime?.length; i += 1) {
    const hours = _data?.exerciseTime[i][1]?.split(':');
    if (hours?.length === 2) {
      const stringTime = hours[0] + '.' + hours[1];
      exerciseTimes[i] = [_data?.exerciseTime[i][0], parseFloat(stringTime)];
    }
  }
  return exerciseTimes;
};

export const dataFormatter = (daily_list, plotType) => {
  let summaryDataList = {};
  (daily_list || []).map((n) =>
    Object.keys(n).map((i) => {
      if (i === 'measureDate') {
        summaryDataList[i] = [...(summaryDataList?.[i] || []), n?.[i]];
      }
      if (n[plotType]) {
        if (i !== 'measureDate') {
          summaryDataList[i] = [
            ...(summaryDataList?.[i] || []),
            [n.measureDate, n?.[i]],
          ];
        }
      }
      return {};
    }),
  );
  return summaryDataList;
};

export const dataFormatterTime = (daily_list, plotType) => {
  let summaryDataList = {};
  (daily_list || []).map((n) =>
    Object.keys(n).map((i) => {
      if (i === 'measureTime') {
        summaryDataList[i] = [...(summaryDataList?.[i] || []), n?.[i]];
      }
      if (n[plotType]) {
        if (i !== 'measureTime') {
          summaryDataList[i] = [
            ...(summaryDataList?.[i] || []),
            [n.measureTime, n?.[i]],
          ];
        }
      }
      return {};
    }),
  );
  return summaryDataList;
};

export const dataFormatterBabel = (daily_list, plotType) => {
  let summaryDataList = {};
  (daily_list || []).map((n) =>
    Object.keys(n).map((i) => {
      if (n[plotType]) {
        summaryDataList[i] = [...(summaryDataList?.[i] || []), n?.[i]];
      }
      return summaryDataList;
    }),
  );
  return summaryDataList;
};
