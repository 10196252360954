// import libraries
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { useSearchParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

//import utils
import { Table, Title } from '@app/components';
import { useAuthContext } from '@app/providers/authProvider';
import { getAdminPatientList } from '@app/services/patient/patient.service';
import { useStableNavigate } from '@app/providers/stableNavigate';

import { PatientListFilter, PatientListItem } from './components';

// create a component
const PatientListScene = () => {
  const { userFacility } = useAuthContext();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const params = queryString.parse(search);
  const navigate = useStableNavigate();
  const [state, setState] = useState({
    patientDataList: [],
    pageNo: 1,
    loading: false,
    finished: false,
    isRefresh: true,
    patDelete: 0,
    spin: false,
  });

  const {
    patientDataList,
    pageNo,
    loading,
    isRefresh,
    finished,
    patDelete,
    spin,
  } = state;

  // Call class api after component did mount
  useEffect(() => {
    onSearch();
  }, []);

  // Infinity scroll when loading is true
  useEffect(() => {
    if (loading) {
      onSearch();
    }
  }, [loading]);

  //削除後のリロード
  useEffect(() => {
    onSearch();
  }, [patDelete]);

  // Search function that request API with filtered values
  const loadMoreData = async () => {
    if (pageNo > 0) {
      setState({ ...state, loading: true, pageNo: pageNo + 1 });
    }
  };

  const onSearch = async () => {
    try {
      // Result of Searched values to get API data
      const _res = await getAdminPatientList({
        page: pageNo,
        facilityId: +params?.facilityId || userFacility?.facilityId || null,
        carteNo: searchParams.get('carteNo'),
        birthdayFrom: searchParams.get('birthdayFrom')
          ? moment(new Date(searchParams.get('birthdayFrom'))).format(
              'YYYY-MM-DD',
            )
          : null,
        birthdayTo: searchParams.get('birthdayTo')
          ? moment(new Date(searchParams.get('birthdayTo'))).format(
              'YYYY-MM-DD',
            )
          : null,
        dataNo: searchParams.get('dataNo'),
        receptDateFrom: searchParams.get('receptDateFrom')
          ? moment(new Date(searchParams.get('receptDateFrom'))).format(
              'YYYY-MM-DD',
            )
          : null,
        receptDateTo: searchParams.get('receptDateTo')
          ? moment(new Date(searchParams.get('receptDateTo'))).format(
              'YYYY-MM-DD',
            )
          : null,
        patientName: searchParams.get('patientName'),
        gender: searchParams.get('gender'),
        staffName: searchParams.get('staffName'),
        classId: searchParams.get('classId'),
      });

      const patientListData = _res.data.patientResponseList.map(
        (data, index) => {
          const indexNew = (pageNo - 1) * 30 + index;
          return { ...data, no: indexNew + 1, key: indexNew + 10 };
        },
      );
      setState({
        ...state,
        loading: false,
        isRefresh: false,
        finished: patientListData?.length < 30,
        patientDataList: [...patientDataList, ...patientListData],
      });
    } catch (error) {
      setState({ ...state, loading: false, isRefresh: false });
    }
  };

  return (
    <Spin spinning={spin}>
      <div className='user-list'>
        <Title title='使用者一覧画面' />
        <PatientListFilter
          onSearch={() => {
            setState({
              ...state,
              pageNo: 1,
              patientDataList: [],
            });
          }}
        />
        <InfiniteScroll
          dataLength={patientDataList.length}
          next={loadMoreData}
          hasMore={!finished}
        >
          <Table
            rowSelection=''
            className='user-list-table'
            loading={isRefresh || loading}
            dataSource={patientDataList}
            columns={PatientListItem()}
          />
        </InfiniteScroll>
      </div>
    </Spin>
  );
};

// make this component available to the app
export default PatientListScene;
