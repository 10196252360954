import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  DatePicker,
  Spin,
} from 'antd';
import moment from 'moment';
import {
  pdfOdlReport,
  pdfProduct,
} from '@app/services/user/userDetail.service';
import { useStableNavigate } from '@app/providers/stableNavigate';
import { Routes } from '@app/routes/routes';
import { useAuthContext } from '@app/providers/authProvider';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { useForm } = Form;

const ReportDownloadModal = ({
  open = false,
  onCancel = () => {},
  patientInfo = {},
}) => {
  const [form] = useForm();
  const navigate = useStableNavigate();

  const { userFacility } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    rehabilitationList: [],
    rehabilitationLoading: false,
  });

  const { rehabilitationList, rehabilitationLoading } = state;

  useEffect(() => {
    onInit();
  }, []);

  const onInit = async () => {
    try {
      setState({ ...state, rehabilitationLoading: true });
      const response = await pdfProduct({
        facilityId: userFacility?.facilityId || null,
        patientId: patientInfo?.patientId,
      });
      setState({
        rehabilitationList: response?.data?.rehabiliList || [],
        rehabilitationLoading: false,
      });
    } catch (error) {
      setState({ rehabilitationList: [], rehabilitationLoading: false });
    }
  };

  const onSubmit = async (e) => {
    try {
      setLoading(true);
      await pdfOdlReport({
        facilityId: userFacility?.facilityId || null,
        patientId: patientInfo?.patientId,
        receptId: patientInfo?.receptId,
        targetDateFrom: e?.targetDate?.[0]
          ? moment(new Date(e?.targetDate?.[0])).format('YYYY/MM/DD')
          : null,
        targetDateTo: e?.targetDate?.[1]
          ? moment(new Date(e?.targetDate?.[1])).format('YYYY/MM/DD')
          : null,
        showShape: e?.showShape ? true : false,
        showWe100Graph: e?.showMeasureTypes?.find((e) => e === 8)
          ? true
          : false,
        showWe110Graph: e?.showMeasureTypes?.find((e) => e === 2)
          ? true
          : false,
        showEm300Graph: e?.showMeasureTypes?.find((e) => e === 11)
          ? true
          : false,
        showEm350Graph: e?.showMeasureTypes?.find((e) => e === 12)
          ? true
          : false,
        showEm400Graph: e?.showMeasureTypes?.find((e) => e === 13)
          ? true
          : false,
        showWatchGraph: e?.showMeasureTypes?.find((e) => e === 1)
          ? true
          : false,
        showBodyGraph: e?.showMeasureTypes?.find((e) => e === 'showBodyGraph')
          ? true
          : false,
        comment: e?.comment,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('reportDownloadModal', error);
      if (error === 'A001-001') {
        navigate(Routes.Landing.Login.path);
        window.location.reload();
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const lines = value.split('\n');
    if (lines?.length <= 6) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        comment: value,
      });
    } else {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        comment: lines.slice(0, 5).reduce(function (prev, current, idx) {
          return idx === 0 ? `${current}` : prev + '\n' + current;
        }, ''),
      });
    }
  };

  return (
    <>
      {open ? (
        <Modal
          title='レポート出力'
          footer={null}
          visible={open}
          onCancel={onCancel}
        >
          <Spin spinning={rehabilitationLoading}>
            <Space
              split={<Divider type='vertical' />}
              className='mb-20 row-content center-center'
              size='middle'
            >
              <span>初回受付日: {patientInfo?.firstDate}</span>
              <span>最終受付日: {patientInfo?.visitDate}</span>
            </Space>
            <Form
              name='reportDownloadModal'
              form={form}
              onFinish={onSubmit}
              layout='vertical'
              initialValues={{
                targetDate: [
                  moment(patientInfo?.firstDate),
                  moment(patientInfo?.visitDate || patientInfo?.firstDate),
                ],
                comment: undefined,
                showShape: true,
              }}
            >
              <Form.Item
                name='targetDate'
                label='レポート対象期間'
                rules={[
                  {
                    required: true,
                    message: 'レポート対象期間を入力してください',
                  },
                ]}
              >
                <RangePicker
                  disabledDate={(d) =>
                    !d ||
                    d.isAfter(patientInfo?.visitDate) ||
                    d.isBefore(patientInfo?.firstDate)
                  }
                />
              </Form.Item>
              <Form.Item
                name='showMeasureTypes'
                label='レポート出力対象機器'
                rules={[
                  {
                    required: true,
                    message: 'レポート出力対象機器を入力してください',
                  },
                ]}
              >
                <Checkbox.Group
                  options={[
                    ...rehabilitationList?.map((item) => ({
                      label: item?.rehabilitationName,
                      value: item?.rehabilitationId,
                    })),
                    { label: '身体情報', value: 'showBodyGraph' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name='comment'
                label='コメント'
                rules={[
                  {
                    required: false,
                    max: 220,
                    message: 'コメントは220文字以内で入力してください。',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        const lines = value.split('\n');
                        if (lines?.length <= 5) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(
                              'コメントの改行は5回以内で入力してください。',
                            ),
                          );
                        }
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <TextArea
                  placeholder='レポート出力'
                  defaultValue={'お疲れ様でした。'}
                  size='large'
                  rows={5}
                  maxLength={221}
                  autoSize={false}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item name='showShape' valuePropName='checked'>
                <Checkbox>グラフのプロットを表示</Checkbox>
              </Form.Item>
              <div className='row-content center-center'>
                <Button
                  type='primary'
                  htmlType='submit'
                  size='large'
                  className='button'
                  disabled={loading}
                  loading={loading}
                >
                  レポート出力
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      ) : null}
    </>
  );
};

export default ReportDownloadModal;
