import moment from 'moment';

export const chartZoomOptions = (realtimeListDataList, selectGraph) => {
  // console.log('selectGraph', selectGraph);
  // console.log(realtimeListDataList);
  return {
    xAxis: [
      {
        show: false,
        type: 'category',
        data:
          selectGraph !== 3
            ? realtimeListDataList?.map((item) => item.measureTime)
            : null,
      },
    ],
    yAxis: [
      {
        show: false,
        type: 'value',
        scale: true,
        position: 'left',
      },
    ],
    dataZoom: [
      {
        show: true,
        type: 'slider',
        top: 10,
        height: 40,
        left: 0,
        filterMode: 'none',
      },
    ],
  };
};
